import React, { useState, useEffect } from "react";
import {Link, NavLink} from 'react-router-dom';
import ReactDOM from "react-dom";
import HeaderDoc from "../elements/HeaderDoc.jsx";
import { motion } from "framer-motion";


let transition = { duration: 0.6, ease: [0.215, 0.61, 0.355, 1] };
const Documents_3189 = ({ placeholder, handleChange }) => (
    <>
                <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                
                <div className="container_stroke_steps">
                <div className="container-guides-docu">
                <div className="container-guides-heading vbbb">

                        <h1>Документы и служебные письма</h1>

                </div>
                
                <div className="guides-doc-heading">
                    <h1>Выберите сеть</h1>
                </div>

                    <HeaderDoc />


            </div>
            <motion.div
                      exit={{ scale: 0.8 }}
                      initial={{ scale: 0.8 }}
                      animate={{ scale: 1 }}
                      transition={transition}
                                    >
                                                                <div className="guides-doc-heading">
                    <h1>ЗСПД 3189</h1>
                </div>
                <div className="container_post_connection">
       <div className="container_steps_news">
           <h2>О смене мастер-ключей ФИС ГНА</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных 3189! В период с 18 по 20 октября 2021 г. будет производиться
       смена мастер-ключей ЗСПД 3189. Ознакомьтесь с письмом министерства образования и науки
           <a className="button-download-let" href="/cm3189.pdf" download>Скачать</a>
        </div>
    </div>
    <div className="container_post_connection">
       <div className="container_steps_news">
           <h2>Регламент</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных 3189! Представляем Вам Регламент защищенной сети передачи данных 3189
           <a className="button-download-let" href="/rg3189.pdf" download>Скачать</a>
        </div>
    </div>

    </motion.div>
    </div>
    </motion.div>
    </>
);

export default Documents_3189;