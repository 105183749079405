import React from 'react';
import {Link} from 'react-scroll';
import { motion } from "framer-motion";
import scheme from '../img/Asset-scheme.svg';
import { Helmet } from 'react-helmet';
import data from "../elements/data.jsx";
import dataSert from "../elements/data-sert.jsx";
import dataOtherQ from "../elements/data-otherq";
import dataSig from "../elements/data-sig.jsx";
import dataDist from "../elements/data-dist.jsx";
import dataNet from "../elements/data-network";
import Accordion from "../elements/Accordion";

const title = 'ЦИТиС ЗСПД | FAQ'

const container = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 10
      }
    }
  };


  const item = {
     hidden: { opacity: 0 },
     visible: {opacity: 1}
  };

const GuidesFaq = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
                  </Helmet> 
                  <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                                
            <div id="3189" className="container-guides-section-faq">
                
                <div className="container-guides-heading-faq">
                    <h1>Ответы на часто задаваемые вопросы</h1>
                </div>

                <div className="container-guides-faq-main-block">
                <div className="container-guides-sidebar">
                    <Link className="faq-more mtb" to="sert" spy={true} smooth={true}>Работа с сертификатом </Link>
                    <Link className="faq-more mtb" to="sig" spy={true} smooth={true}>Подписание документов</Link>
                    <Link className="faq-more mtb" to="vipnetc" spy={true} smooth={true}>Взаимодействие с ViPNet Client</Link>
                    <Link className="faq-more mtb" to="networks" spy={true} smooth={true}>Межсетевое взаимодействие</Link>
                    <Link className="faq-more mtb" to="workdist" spy={true} smooth={true}>Работа с дистрибутивом</Link>
                    <Link className="faq-more mtb" to="otherq" spy={true} smooth={true}>Общие вопросы</Link>
                    </div>
                    <div className="container-guides-sqd">
                <div className="container-guides-faq">
                        <h1 id="sert">Работа с сертификатом </h1>
                    <Accordion items={dataSert.items}/>
                    </div> </div>
                <div className="container-guides-sqd">
                    <div className="container-guides-faq">
                        <h1 id="sig">Подписание документов</h1>
                    <Accordion items={dataSig.items}/>
                    </div>   
            </div>
            <div className="container-guides-sqd">
                    <div className="container-guides-faq">
                        <h1 id="vipnetc">Взаимодействие с ViPNet Client</h1>
                    <Accordion items={data.items}/>
                    </div>
            </div>
            <div className="container-guides-sqd">
                    <div className="container-guides-faq">
                        <h1 id="networks">Межсетевое взаимодействие</h1>
                    <Accordion items={dataNet.items}/>
                    </div>
            </div>

            <div className="container-guides-sqd">
                    <div className="container-guides-faq">
                        <h1 id="workdist">Работа с дистрибутивом</h1>
                    <Accordion items={dataDist.items}/>
                    </div>
                    
            </div>

            
            <div className="container-guides-sqd">
                    <div className="container-guides-faq">
                        <h1 id="otherq">Общие вопросы</h1>
                    <Accordion items={dataOtherQ.items}/>
                    </div>
                    
            </div>
            </div>
            </div>
            </motion.div>
        </>
        
        
    )
}

export default GuidesFaq;