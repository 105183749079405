import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import {Link, NavLink} from 'react-router-dom';

export const Connection_13833_client = ({ placeholder, handleChange }) => (
    <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
    <>
    <div className="container_stroke_steps">
        </div>
        <div className="container_choosen_type">
                        <NavLink to='/13833' className="nav-link-connect" activeClassName="nav-link-connect-active" >
                            Подключение с использованием СКЗИ ViPNet Client
                        </NavLink>
                        <NavLink to='/13833/networks' className="nav-link-connect" activeClassName="nav-link-connect-active">
                             Подключение с помощью межсетевого взаимодействия
                        </NavLink>
        </div>
        <div className="connection-title">
            <h1>Условия подключения к защищенной сети передачи данных 13833 образовательной организации</h1>
            <p className="connection-title-descript">Ознакомьтесь с правилами и процедурой подключения к ЗСПД 13833</p>
          </div>
          <div className="container_info_steps_connection">
        <div className="ww">
            
        <div className="container_steps_main gs fd">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-certificate" width="64" height="64" viewBox="0 0 24 24" stroke-width="1.0" stroke="#4e4e4e" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <circle cx="15" cy="15" r="3" />
  <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
  <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
  <line x1="6" y1="9" x2="18" y2="9" />
  <line x1="6" y1="12" x2="9" y2="12" />
  <line x1="6" y1="15" x2="8" y2="15" />
</svg>
          <br></br>
         Обеспечить наличие квалифицированного сертификата ключа проверки электронной подписи (далее – КСКПЭП) на лицо, получающее парольную и справочно-ключевую информацию для подключения к ЗСПД (необходим для шифрования и расшифровки парольной и справочно-ключевую информации);
        </div>
        <div className="container_steps_main gs fd">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-certificate" width="64" height="64" viewBox="0 0 24 24" stroke-width="1" stroke="#4e4e4e" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
  <path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5" />
  <circle cx="6" cy="14" r="3" />
  <path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5" />
</svg>
          <br></br>
         Обеспечить наличие КСКПЭП на информационную систему (необходим для обеспечения подлинности и достоверности электронных документов и сведений, предоставляемых информационной системой образовательной организацией в Сервис приема);
        </div>
                <div className="container_steps_main gs fd">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-box" width="64" height="64" viewBox="0 0 24 24" stroke-width="1" stroke="#4e4e4e" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" />
  <line x1="12" y1="12" x2="20" y2="7.5" />
  <line x1="12" y1="12" x2="12" y2="21" />
  <line x1="12" y1="12" x2="4" y2="7.5" />
</svg>
          <br></br>
         Приобрести у любой организации-партнёра ОАО «ИнфоТеКС» необходимое количество СКЗИ ViPNet Client для сети 13833, имеющих действующий сертификат соответствия ФСБ России. Если образовательная организация владеет собственной ViPNet-сетью, то возможна организация межсетевого взаимодействия между ViPNet-сетью образовательной организации и ЗСПД 13833;
        </div>
        </div>
    </div>
                        {/* <div className="navbar-13833">
                        <NavLink exact to='/13833/' className="nav-link-connect" activeClassName="nav-link-connect-active" >
                            Подключение к ЗСПД 13833 с использованием СКЗИ ViPNet Client
                        </NavLink>
                        <NavLink exact to='/13833/networks' className="nav-link-connect" activeClassName="nav-link-connect-active">
                             Подключение к ЗСПД 13833 с помощью межсетевого взаимодействия
                        </NavLink>
                    </div> */}
    <div className="info-container">
       <div className="header">
           <h1>Подключение с использованием СКЗИ ViPNet Client</h1>
       </div>
    </div>
    <div className="container_stroke_steps">
    <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">01</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
          <h2>Сбор документов</h2>
       </div>
       <div className="container_steps_main">
         Для подключения к защищенной сети передачи данных № 13833 (далее – сеть 13833) необходимо собрать следующие документы:
        </div>
        <div className="container_steps_main num">
          <div className="container_steps_ico">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-invoice" width="42" height="42" viewBox="0 0 24 24" stroke-width="1" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
  <line x1="9" y1="7" x2="10" y2="7" />
  <line x1="9" y1="13" x2="15" y2="13" />
  <line x1="13" y1="17" x2="15" y2="17" />
</svg> </div><p>Заявление на подключение к сети 13833 юридического лица;<a className="button-download-let sml" href="/03 Форма заявления 13833.docx" download>Скачать</a></p>

        </div>

        <div className="container_steps_main num">
        <div className="container_steps_ico">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-license" width="42" height="42" viewBox="0 0 24 24" stroke-width="1" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M15 21h-9a3 3 0 0 1 -3 -3v-1h10v2a2 2 0 0 0 4 0v-14a2 2 0 1 1 2 2h-2m2 -4h-11a3 3 0 0 0 -3 3v11" />
  <line x1="9" y1="7" x2="13" y2="7" />
  <line x1="9" y1="11" x2="13" y2="11" />
</svg> </div>Заверенная копия лицензии на право пользования сети ViPNet № 13833 (ПО ViPNet Client) или Акт приема-передачи программного
обеспечения.
При подключении филиалов необходимо предоставить лицензию на право пользования сети ViPNet<br></br> № 13833 или Акт приема-передачи программного
обеспечения на каждый филиал.
        </div>
        <div className="container_steps_main_p bc">
        Внимание! В соответствии с пунктом 2.5 Регламента подключения к ЗСПД 13833 переписка заявителя с органом КЗИ ЦБИ 
        должна осуществляться посредством электронной почты с адреса, указанного в разделе 2 заявления на подключение к ЗСПД 13833 
        с сохранением истории переписки. Информация, направленная с другой электронной почты, рассматриваться не будет.
           </div>
    </div>
    
        <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">02</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
          <h2>Отправка документов на предварительное согласование</h2>
       </div>
       <div className="container_steps_main fd">
       Скан-копии документов (в формате .pdf), указанных в шаге 1, совместно с действующим квалифицированным сертификатом
             ключа проверки электронной подписи (в формате .cer), выданным на лицо, получающее парольную и справочно-ключевую 
             информацию, необходимо направить на электронную почту 13833vipnet@citis.ru. В теме письма необходимо указать:
              «Заявление на подключение к ЗСПД 13833», а также краткое наименование органа или организации (дальнейшую переписку нужно вести в 
              рамках первого письма с сохранением истории).
         <br></br>
        </div>
    </div>
        <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">03</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Получение ответа</h2>
       </div>
       <div className="container_steps_main">
         После проверки (до 5 рабочих дней) на ваш адрес электронной почты придёт уведомление о положительном результате проверки или перечень обнаруженных замечаний.
        </div>
    </div>
            <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">04</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Отправка пакета документов подписанных электронной подписью</h2>
       </div>
       <div className="container_steps_main">
         После получения уведомления о положительном результате просим направить пакет документов на электронную почту 13833vipnet@citis.ru единым 
         файлом в формате .pdf, подписанного квалифицированной электронной подписью руководителя организации или лица, действующего на основании доверенности (с обязательным приложением файла в формате .sig).
        </div>
        <div className="container_steps_main_p bc">
Примечание - необходимо направить, как .pdf, так и .sig файл.
           </div>
    </div>
            <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">05</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Формирование и отправка парольной и справочно-ключевой информации</h2>
       </div>
       <div className="container_steps_main">
       В случае положительной проверки документов в течение 10 рабочих дней с момента 
         получения и регистрации документов осуществляется формирование и отправка парольной 
         и справочно-ключевой информации, зашифрованной на ключе проверки электронной подписи получателя (входит в состав сертификата получателя). 
         После чего заявителю направляется письмо-уведомление о направлении парольной и справочно-ключевой информации.
Если в результате проверки документов были обнаружены замечания, на адрес электронной почты (с которого вы направляли документы на предварительное согласование), будет направлен перечень обнаруженных замечаний.
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">06</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Получение и установка парольной и справочно-ключевой информации</h2>
       </div>
       <div className="container_steps_main">
         После получения парольной и справочно-ключевой информации в зашифрованном виде (файл с расширением .enc) заявитель:
        </div>
        <div className="container_steps_main num">
         	&#8226; Осуществляет расшифрование парольной и справочно-ключевой информации с использованием средства электронной подписи и ключа электронной подписи сертификата получателя;
        </div>
        <div className="container_steps_main num">
         	&#8226; Осуществляет проверку содержимого расшифрованного файла на наличие парольной (файл с расширением .xps) и справочно-ключевой информации (файл с расширением .dst);
        </div>
        <div className="container_steps_main num">
         	&#8226; Организует настройку собственного узла сети 13833, включая установку справочно-ключевой информации.
        </div>
               <div className="container_steps_main">
         После получения письма-уведомления о передаче парольной и справочно-ключевой информации заявитель:
        </div>
                <div className="container_steps_main num">
         	&#8226; Проверяет соответствие информации, указанной в письме;
        </div>
                <div className="container_steps_main num">
         	&#8226; Направляет на электронную почту 13833vipnet@citis.ru письмо-уведомление о получении парольной и справочно-ключевой информации в формате .pdf, подписанное квалифицированной электронной подписью руководителя организации или лица, действующего на основании доверенности (с обязательным приложением файла в формате .sig).
        </div>
        <a className="button-download-let sml pp" href="/07_Письмо_уведомление_о_получении_дст_13833.docx" download>Скачать</a>
    </div>
    </div>
    </>
    </motion.div>
);