import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import scheme from '../img/Asset-scheme.svg';
import { News_13833 } from '../containers/News_13833.jsx';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Новости ЗСПД 13833'

const Page_13833 = () => {
    return (
        <>
            <Helmet>
          <title>{ title }</title>
        </Helmet>
         <div className='container-network Flex-cell Flex--1of1'>
            <div className="info-container">
                       <div className="header">
            <h1>Новости ЗСПД 13833</h1>
            <p>Ознакомьтесь с последними новостями ЗСПД </p>
        </div>
                <News_13833></News_13833>
            </div>
        </div>
        </>
    )
}

export default Page_13833;