import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import { motion } from "framer-motion";
import scheme from '../img/Asset-scheme.svg';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Перечень организаций-лицензиатов по технической защите информации'

const container = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 10
      }
    }
  };


  const item = {
     hidden: { opacity: 0 },
     visible: {opacity: 1}
  };

const Licensees = () => {
  return (
    <>
    <Helmet>
      <title>{ title }</title>
              </Helmet> 
              <motion.div
        visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
        initial={{ opacity: 0, x: '2vw', y: 0 }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
        >
        <div className="container-guides-section-faq-isea">
            <div className="container-guides-heading-esia cghn">
            <h1-1>Перечень организаций-лицензиатов по технической защите информации</h1-1>
            </div>
            <div className="container-ipnet-man">
            <motion.div
        className="overlay-form-isea_2">
            <div className="overlay-upper-lic"></div>
            <div className="overlay-form-inner-esia">
            <p_2>На основании пункта 6 части 1 приказа ФСТЭК России от 18 февраля 2013 г. № 21 «Об утверждении состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при 
              их обработке в информационных системах персональных данных» ИСПДн должны пройти оценку эффективности реализованных в рамках системы защиты персональных данных мер по обеспечению безопасности персональных 
              данных (в том числе, в форме аттестационных испытаний ИСПДн на соответствие требованиям защиты информации).</p_2> 
            <p_2>Такая оценка эффективности проводится оператором самостоятельно 
              или с привлечением на договорной основе юридических лиц и индивидуальных предпринимателей, 
              имеющих лицензию на осуществление деятельности по технической защите конфиденциальной информации. 
              Перечень организаций-лицензиатов 
              по технической защите информации приведен в Реестре лицензий на деятельность
               по технической защите конфиденциальной информации, 
              размещенном на официальном сайте ФСТЭК России по адресу: <a href ="https://reestr.fstec.ru/reg1"> https://reestr.fstec.ru/reg1</a>.</p_2>
                </div>
            </motion.div>
            </div>
            </div>
            </motion.div>
        </>
        
        
    )
}

export default Licensees;