import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import { AnimatePresense, motion } from "framer-motion";
import scheme from '../img/Asset-scheme.svg';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Инструкции'
const transition = { duration: 0.5, ease: "easeInOut" };
const container = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 10
      }
    }
  };


  const item = {
     hidden: { opacity: 0 },
     visible: {opacity: 1}
  };


const Guides = () => {
    
  const postPreviewVariants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 }
  };

  const blogVariants = {
    enter: { transition: { staggerChildren: 0.1 } },
    exit: { transition: { staggerChildren: 0.1 } }
  };
    return (
        <>
        <Helmet>
          <title>{ title }</title>
                  </Helmet> 
                  <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
            <div className="container-guides">
                <div className="container-guides-heading">
                    <h1>Раздел, который готов <span className="motn">Вам помочь</span></h1>
                    <p>Добро пожаловать в раздел инструкции! Здесь Вы сможете
                        найти ответы на часто задаваемые вопросы, ознакомиться
                        с различными руководствами и получить актуальную документацию
                    </p>
                </div>
                <motion.div 
                className="container-guides-categ mkol ">
                    
                    <motion.div
                    initial={{ x: -20, y: 0 }}
                    animate={{ x: 0}}
                    transition={{ duration: 1 }}
                    className="container-guides-categ-block">
                    
                        <h1>FAQ</h1>
                        <p>Ответы на часто задаваемые вопросы</p>
                        <NavLink className="guides-more" exact to="/guides/faq">Подробнее</NavLink>
                    </motion.div>
                    <motion.div 
                    initial={{ y: -20, }}
                    animate={{ y: 0}}
                    transition={{ duration: 1 }}
                    className="container-guides-categ-block">
                    
                        <h1>Руководства</h1>
                        <p>Раздел с пошаговыми инструкциями</p>
                        <NavLink className="guides-more" exact to="/guides/man">Подробнее</NavLink>
                    </motion.div>
                    <motion.div 
                    initial={{ x: 20, }}
                    animate={{ x: 0}}
                    transition={{ duration: 1 }}
                    className="container-guides-categ-block">
                        <h1>Документация</h1>
                        <p>Официальная документация и служебные письма</p>
                        <NavLink className="guides-more" exact to="/guides/doc">Подробнее</NavLink>
                    </motion.div>
                    <motion.div 
                    initial={{ y: 20, }}
                    animate={{ y: 0}}
                    transition={{ duration: 1 }}
                    className="container-guides-categ-block cgc">
                    
                    <h1>Адреса для входа на ресурсы ЗСПД</h1>
                    <p>Перечень IP-адресов включащий в себя ресурсы всех ЗСПД</p>
                    <NavLink className="guides-more" exact to="/guides/ipres">Подробнее</NavLink>
                </motion.div>
                <motion.div 
                    initial={{ y: 20, }}
                    animate={{ y: 0}}
                    transition={{ duration: 1 }}
                    className="container-guides-categ-block">
                    <h1>Разное</h1>
                    <p>Полезные ссылки на сторонние ресурсы</p>
                    <NavLink className="guides-more" exact to="/guides/other">Подробнее</NavLink>
                </motion.div>
                </motion.div>
            </div>
            </motion.div>
        </>
        
        
    )
}

export default Guides;