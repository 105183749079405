import React, { useState } from 'react';
import {Link} from 'react-scroll';
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import scheme from '../img/Asset-scheme.svg';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Адреса для входа на ресурсы ЗСПД'

const container = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 10
      }
    }
  };


  const item = {
     hidden: { opacity: 0 },
     visible: {opacity: 1}
  };

const Hover = ({ stata }) => {
    const [active, setActive] = useState(false);
}

const GuidesIpres = (isActive) => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
                  </Helmet> 
                  <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
            <div className="container-guides-section-faq">
                <div className="container-guides-heading cghn">
                    <h1>Адреса для входа на ресурсы ЗСПД</h1>
                </div>
                <div className="guides-doc-heading">
                    <h1>Выберите сеть</h1>
                </div>
                <div className="container-guides-categ vbbb ssss nbnbn">
                    
                <div className="container-guides-categ-block ppb">
                    
                    <h1>ЗСПД 3608</h1>
                    
                    <Link className="guides-more mtb" to="3608" spy={true} smooth={true}>Подробнее</Link>
                </div>
                <div className="container-guides-categ-block ppb">
                
                    <h1>ЗСПД 3189</h1>
                    
                    <Link className="guides-more mtb" to="3189" spy={true} smooth={true}>Подробнее</Link>
                </div>
                <div className="container-guides-categ-block ppb">
                
                    <h1>ЗСПД 13833</h1>
                    
                    <Link className="guides-more mtb" to="13833" spy={true} smooth={true}>Подробнее</Link>
                </div>
            </div>
                <div className="container-ipnet-sqd">
                    <div id="3608" className="ipnet">
                        <h1>ЗСПД 3608</h1>
                    </div>
                    <div className="ipnet-flow">
                    <svg width="575" height="968" viewBox="0 0 575 968" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M573 958L56 958C33.3725 958 22.0588 958 15.0294 950.971C7.99996 943.941 7.99996 932.627 7.99996 910L8 2" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M565 966L573 958L565 950" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                    </div>
                    <div className="ipnet-flow fip1">
                    <svg width="576" height="240" viewBox="0 0 576 240" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M574 120H16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M566 128L574 120L566 112" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(-1 0 0 1 9 120)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                    </div>
                    <div className="ipnet-flow fip2">
                    <svg width="576" height="240" viewBox="0 0 576 240" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M574 120H16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M566 128L574 120L566 112" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(-1 0 0 1 9 120)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                    </div>
                    <div className="ipnet-flow fip3">
                    <svg width="576" height="240" viewBox="0 0 576 240" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M574 120H16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M566 128L574 120L566 112" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(-1 0 0 1 9 120)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                    </div>
                    <div className="ipnet-flow fip4">
                    <svg width="576" height="240" viewBox="0 0 576 240" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M574 120H16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M566 128L574 120L566 112" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(-1 0 0 1 9 120)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                    </div>
                    <div className="ipnet-flow fip5">
                    <svg width="576" height="240" viewBox="0 0 576 240" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M574 120H16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M566 128L574 120L566 112" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(-1 0 0 1 9 120)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                    </div>
                    <div className="ipnet ipl">
                        <h1>10.3.46.15</h1>
                        <p>Модуль сбора данных о выданных сертификатах о 
                            владении русским языком, знании истории России и основ законодательства российской федерации</p>
                    </div>
                    <div className="ipnet ipl">
                        <h1>10.3.47.15</h1>
                        <p>Подсистема сбора данных о среднем общем образовании</p>
                    </div>
                    <div className="ipnet ipl">
                        <h1>10.3.49.15</h1>
                        <p>подсистема сбора данных о среднем профессиональном образовании</p>
                    </div>
                    <div className="ipnet ipl">
                        <h1>10.3.54.15</h1>
                        <p>Подсистема сбора данных о высшем образовании</p>
                    </div>
                    <div className="ipnet ipl dpo">
                        <h1>10.3.48.20</h1>
                        <p>Подсистема сбора данных о дополнительном профессиональном образовании</p>
                    </div>
                    <div className="ipnet ipl dpo">
                        <h1>10.3.48.30</h1>
                        <p>Подсистема сбора данных о профессиональном обучении</p>
                    </div>
            </div>
            <div className="container-ipnet-sqd">
                    <div id="3189" className="ipnet bmms">
                        <h1>ЗСПД 3189</h1>
                    </div>
                    <div className="ipnet-flow fip6">
                    <svg width="136" height="151" viewBox="0 0 136 151" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M134 141L57 141C34.3726 141 23.0589 141 16.0294 133.971C9 126.941 9 115.627 9 93L9 16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="9" cy="9" r="7" transform="rotate(-90 9 9)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M126 149L134 141L126 133" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>




                    </div>
                    <div className="ipnet ipl">
                        <h1>85.142.162.98</h1>
                        <h1>http://vak3.ed.gov.ru/</h1>
                        <p>Высшая аттестационная комиссия</p>
                    </div>
                    </div>
                    <div className="container-ipnet-sqd">
                    <div id="13833" className="ipnet bsss">
                        <h1>ЗСПД 13833</h1>
                    </div>
                    <div className="ipnet-flow fip7">
                    <svg width="242" height="257" viewBox="0 0 242 257" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M240 247L57 247C34.3726 247 23.0589 247 16.0294 239.971C8.99999 232.941 8.99999 221.627 8.99999 199L9 16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="9" cy="9" r="7" transform="rotate(-90 9 9)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M232 255L240 247L232 239" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                    </div>
                    <div className="ipnet-flow fip8">
                    <svg width="241" height="240" viewBox="0 0 241 240" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M239 120H16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M231 128L239 120L231 112" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(-1 0 0 1 9 120)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
<div className="ipnet-flow fip9">
                    <svg width="242" height="257" viewBox="0 0 242 257" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M240 247L57 247C34.3726 247 23.0589 247 16.0294 239.971C8.99999 232.941 8.99999 221.627 8.99999 199L9 16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="9" cy="9" r="7" transform="rotate(-90 9 9)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M232 255L240 247L232 239" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                    </div>
           
                    <div className="ipnet ipl">
                        <h1>10.3.60.2:8032</h1>
                        <p>Сервис приема поступи в ВУЗ онлайн: Веб-сервис</p>
                    </div>
                    <div className="ipnet ipl">
                        <h1>10.3.60.3:8100</h1>
                        <p>Сервис приема поступи в ВУЗ онлайн: API</p>
                    </div>
                    <div className="ipnet ipl">
                        <h1>10.3.62.2:8080</h1>
                        <p>Подсистема сбора сведений о гражданах, обучающихся в организациях, осуществляющих образовательную деятельность по программам высшего образования (студентов)</p>
                    </div>
                    </div>
            </div>
            </motion.div>
        </>
        
        
    )
}

export default GuidesIpres;