import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import { motion } from "framer-motion";
import scheme from '../img/Asset-scheme.svg';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Главная'

const container = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 10
      }
    }
  };


  const item = {
     hidden: { opacity: 0 },
     visible: {opacity: 1}
  };

const Home = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
                  </Helmet>
        <div className='container-network Flex-cell Flex--1of1 dsmg'>
         <div className='database-container'>
                <div className='database-header'>
                   <motion.div 
                   className="container-description"
                   variants={container}
                   initial="hidden"
                   animate="visible"
                   >
                        <h1>Федеральное государственное автономное научное учреждение<br></br>
                        «Центр информационных технологий и систем органов исполнительной власти<br></br> имени А.В. Старовойтова»</h1>
                        <p>Техническая поддержка <br></br>защищенных сетей передачи данных</p>
                        <p2>Уважаемые гости сайта! На данном ресурсе Вы можете ознакомиться с правилами подключения к защищенным сетям передачи данных, получить техническую поддержку и быть в курсе последних новостей</p2>
                    </motion.div>
                    <img className="main-img" src={scheme} />
                </div>
                <div className="container_info_steps_connection-sn">
                <motion.div 
                className='home-bot'
                variants={container}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.5 }}>
                    <div className='containers_main_page_grid'>
                        <div className='containers_main_page_grid_1'>
                            {/* <h1 className='container_steps_main_red'>Внимание! С 1 апреля 2023 года изменяются<br></br>
                            контактные данные службы техподдержки ЗСПД 13833</h1>
                            <a className="button-download-let_main" href="/13833/news">Подробнее</a> */}
                        </div>
                        <div className='containers_main_page_grid_2'>
                    <motion.div className='container_steps_main-ws fd'
                                    variants={item}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}>
                        <h1>Подключение к <span className="color-selected">ЗСПД 13833</span></h1>
                        <p>Порядок и способы подключения к защищенной сети передачи данных 13833 для организации защищенного информационного взаимодействия с <br></br><span className="color-selected">Сервисом приема</span></p>
                                    <NavLink className="button-download-let" exact to="/13833/">
                    Подробнее
                </NavLink>
                    </motion.div>
                    <motion.div className='container_steps_main-ws fd'
                    variants={item}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}>
                        <h1>Подключение к <span className="color-selected">ЗСПД 3608</span></h1>
                        <p>Порядок и способы подключения к защищенной сети передачи данных 3608 для организации защищенного информационного взаимодействия с <br></br><span className="color-selected">ФИС ФРДО, ФИС ФБДА, АКНД ПП</span></p>
                        <NavLink className="button-download-let" exact to="/3608/">
                    Подробнее
                </NavLink>
                    </motion.div>
                    <motion.div className='container_steps_main-ws fd'
                    variants={item}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.2 }}>
                        <h1>Подключение к <span className="color-selected">ЗСПД 3189</span></h1>
                        <p>Порядок и способы подключения к защищенной сети передачи данных 3189 для организации защищенного информационного взаимодействия с <br></br><span className="color-selected">ФИС ГНА</span></p>
                    <NavLink className="button-download-let" exact to="/3189/">
                    Подробнее
                </NavLink>
                    </motion.div>
                        </div>
                    </div>
                </motion.div>
                </div>
        </div>
        </div>
        </>
        
        
    )
}

export default Home;