import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {Link, NavLink} from 'react-router-dom';
import { motion } from "framer-motion";

export const News_13833 = ({ placeholder, handleChange }) => (
    <>
        <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                <div className="container_stroke_steps">
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>01 ноября 2023</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       <p>Уважаемые пользователи защищенной сети передачи данных № 13833.</p>
<p>Уведомляем о том, что в период с 27 ноября по 8 декабря текущего года будет осуществляться смена мастер-ключей для ЗСПД 13833.<br></br>
Руководство о порядке действий пользователей при смене мастер-ключей размещено в разделе Руководство.
</p>
<a class="button-download-let" href="/guides/man/change-master-keys">Руководство</a>
        </div>
    </div>
                <div className="container_post_connection">
                    <div className="container_post_header">
                        <p>01 августа 2023</p>
                    </div>
                    <div className="container_steps_news">
                        <h2>О передаче сведений о студентах</h2>
                    </div>
                    <div className="container_post_main">
                    <p>Уважаемые пользователи!<br></br></p>
                <p>Сообщаем о том, что передача сведений о гражданах, обучающихся в образовательных организациях, реализующих программы высшего образования 
                    (студентах), осуществляется через модуль Сервиса приема, являющегося выделенной подсистемой ФИС ГИА и приема, с использованием действующего подключения к ЗСПД 13833.<br></br>
                </p>
                    </div>
                </div>
                <div className="container_post_connection">
                    <div className="container_post_header">
                        <p>11 мая 2023</p>
                    </div>
                    <div className="container_steps_news">
                        <h2>Смена адресов доступа Суперсевиса «Поступление в вуз онлайн».</h2>
                    </div>
                    <div className="container_post_main">
                    <p>Уважаемые пользователи защищенной сети передачи данных № 13833.<br></br></p>
                <p>Сообщаем Вам о том, что были изменены адреса входа в Суперсервис «Поступление в вуз онлайн»:</p>
                <b>
                    10.3.60.2:8032 - Веб-сервис<br></br>
                    10.3.60.3:8100 - API
                </b>
                    </div>
                </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>26 октября 2022</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       <p>Уважаемые пользователи защищенной сети передачи данных № 13833.</p>
<p>Напоминаем о том, что в период с 28 ноября по 9 декабря текущего года будет осуществляться смена мастер-ключей для ЗСПД 13833.<br></br>
Информация о согласовании Рособрнадзором указанной процедуры, а также об уведомлении Минобрнауки России о сроках ее проведения прилагается.<br></br>
Руководство о порядке действий пользователей при смене мастер-ключей размещено в предыдущем блоке новостей от 6 октября 2022 года.
</p>
<a class="button-download-let" href="/Согласование_РОНом_смены_мастер_ключей.pdf" download="">Скачать</a>
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>06 октября 2022</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных 13833 (ЗСПД 13833).<br></br> Сообщаем Вам о том, что с 28 ноября по 09 декабря 2022 года будет осуществляться смена мастер-ключей в ЗСПД 13833.
       <a className="button-download-let" href="/guides/man">Подробнее</a>
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>16 августа 2022</p>
       </div>
       <div className="container_steps_news">
           <h2>График работы</h2>
       </div>
       <div className="container_post_main">
       <p>Уважаемые пользователи защищенной сети передачи данных № 13833.</p>
<p>Уведомляем Вас о том, что в связи с окончанием проведения приемной кампании 2022/2023 учебного года техническая поддержка пользователей ЗСПД 13833 
с 16 августа 2022 г. будет осуществляться по следующему графику:
</p>
<div className="container_steps_main_h3">C понедельника по четверг с 8:00 до 18:00 по МСК.<br></br>
В пятницу с 8:00 до 17:00 по МСК.
</div>
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>21 июня 2022</p>
       </div>
       <div className="container_steps_news">
           <h2>График работы</h2>
       </div>
       <div className="container_post_main">
       <p>Уважаемые пользователи защищенной сети передачи данных № 13833.</p>
Уведомляем Вас о том, что в рамках приемной кампании 2022/2023 учебного года изменено время работы службы технической поддержки ЗСПД 13833.
<p>В настоящее время и до конца проведения приемной компании техническая поддержка пользователей ЗСПД 13833 будет осуществляться по графику:</p>

<div className="container_steps_main_h3">C понедельника по пятницу с 8:00 до 20:00 по Московскому времени</div>

<p>Возникшие в не рабочее время проблемы, связанные с работоспособностью сети 13833, будут решаться службой технической поддержки в рабочее время.</p>
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>01 февраля 2022</p>
       </div>
       <div className="container_steps_news">
           <h2>Технические работы</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных № 13833.
        <p>В связи с проводимыми техническими работами на сети возможны пропадания связи в период </p>с 1 февраля по 2 февраля 2022 года.
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>24 января 2022</p>
       </div>
       <div className="container_steps_news">
           <h2>Подключение к Суперсервису «Поступи в ВУЗ онлайн»</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных 13833!!!
<p>Учитывая сложность, важность и оперативность задачи по подключению пользователей к сети 13833, уведомляем о том, что ФГАНУ ЦИТиС в рамках приемной кампании 
2022/2023 учебного года осуществляет подключение образовательных организаций высшего образования и их филиалов для участия в суперсервисе «Поступление в вуз онлайн».
В связи с эти просим активизировать работу по подключению к сети 13833.</p>
        </div>
    </div>
    <div className="container_post_connection">
       <div className="container_post_header">
           <p>21 июня 2021</p>
       </div>
       <div className="container_steps_news">
           <h2>График работы</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи сети 13833! С 21.06.2021 г. ФГАНУ ЦИТиС возобновляет работу по подключению пользователей, к сети 13833 в штатном режиме.
        Информация о времени работы службы технической поддержки размещена в разделе  <NavLink exact to='/13833/contacts' className="nav-link-news" activeClassName="nav-link-active"><u>«Контакты»</u></NavLink>
        </div>
    </div>
    <div className="container_post_connection ll">
       <div className="container_post_header">
           <p>11 июня 2021</p>
       </div>
       <div className="container_steps_news">
           <h2>График работы в выходные дни</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи сети 13833! 
            График работы в выходные дни:<br></br>
            12 июня с 12:00 до 18:00<br></br>
            13 и 14 июня с 12:00 до 18:00<br></br>
            19 и 20 июня с 12:00 до 18:00<br></br>
        </div>
    </div>
    {/* <div className="container_post news">
       <div className="container_post_header">
           <p>03.06.2021</p>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи сети 13833! 
С 03.06.2021 г. ФГАНУ ЦИТиС проводится подключение пользователей филиалов ВУЗов, к сети 13833. 
Подключение к сети 13833 будет осуществляться в упрощённом порядке. Для этого, головному ВУЗу необходимо отправить на электронную почту 13833vipnet@citis.ru 
следующий пакет документов: заявление на подключение к сети 13833 <b>(обращаем внимание, что в одной заявке должны быть отображены все филиалы головного ВУЗа)</b>, квалифицированный сертификат ключа проверки электронной подписи.
Представление полного пакета документов, указанных в регламенте подключения к сети 13833, необходимо осуществить до 01.08.2021 г. 
В случае непредставления полного комплекта документов, необходимых для подключения, доступ к сети 13833 будет приостановлен.
         <a className="button-download-let" href="/Заявление для филиалов.docx" download>Скачать</a>
        </div>
    </div> */}
    <div className="container_post_connection">
       <div className="container_post_header">
           <p>17 мая 2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Подключение в упрощенном виде</h2>
       </div>
       <div className="container_post_main">
            Добрый день! Учитывая сложность, важность и оперативность задачи по подключению пользователей к сети 13833, сообщаем Вам, что в период с 17.05.2021 по 01.07.2021 будет производится подключение к ЗСПД 13833 в упрощенном виде. Для этого, Вам необходимо отправить на электронную почту 13833vipnet@citis.ru следующий пакет документов: заявление на подключение к сети 13833, квалифицированный сертификат ключа проверки электронной подписи. В случае непредоставления полного комплекта документов до 01.08.2021 доступ к ЗСПД 13833 будет приостановлен.
        </div>
    </div>
        <div className="container_post_connection">
       <div className="container_post_header">
           <p>18 марта 2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Технические работы</h2>
       </div>
       <div className="container_post_main">
         Уважаемые пользователи защищенной сети передачи данных №13833 сообщаем Вам о том, что 19 марта 2021 года с 00:00 до 06:00 по московскому времени будут проводиться технические работы на сети связи. В этот период возможно кратковременное прерывание доступа к Сервису приема.
        </div>
    </div>
    <div className="container_post_connection">
       <div className="container_post_header">
           <p>10 августа 2020</p>
       </div>
       <div className="container_steps_news">
           <h2>Регламент подключения</h2>
       </div>
       <div className="container_post_main">
         Уважаемые пользователи защищенной сети передачи данных 13833 сообщаем Вам о том, что 11.12.2020 г. утвержден новый регламент подключения к защищенной сети передачи данных 13833
         <a className="button-download-let" href="/rg13833.pdf" download>Скачать</a>
        </div>
    </div>
        <div className="container_post_connection">
       <div className="container_post_header">
           <p>10 августа 2020</p>
       </div>
       <div className="container_steps_news">
           <h2>Суперсервис «Поступи в ВУЗ онлайн»</h2>
       </div>
       <div className="container_post_main">
         Уважаемые пользователи защищенной сети передачи данных 13833 сообщаем Вам о том, что в соответствии с 
         п.10 Поручения заместителя руководителя Федеральной службы по надзору в сфере образования и науки от 04.02.2021 г. 
         № 03-П-1 ФГАНУ ЦИТиС поручено: «10. Для доступа к сервису приема в рамках достижения целевого состояния суперсервиса «Поступление в 
         ВУЗ онлайн» использовать защищенную сеть передачи данных №13833
        </div>
    </div>
    </div>
        </motion.div>
    </>

);