import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import scheme from '../img/Asset-scheme.svg';
import { AnimatePresence, motion } from "framer-motion";
import { Connection_13833_client} from '../containers/Connection_13833_client.jsx';
import { Presentation_13833} from '../containers/Pressentation_13833.jsx';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Прямое подключение к ЗСПД 13833'

const Page_13833_connect_client = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
        </Helmet>
         <div className='container-network Flex-cell Flex--1of1'>
            <div className="info-container">
                       <div className="header">
            <h1>Подключение к ЗСПД 13833</h1>
            <p>Ознакомьтесь с правилами и процедурой подключения к ЗСПД 13833</p>
                <Presentation_13833></Presentation_13833>
        </div>
                <Connection_13833_client></Connection_13833_client>
            </div>
        </div>
        </>
    )
}

export default Page_13833_connect_client;