import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import scheme from '../img/404.svg';
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Страница не найдена (404)'

const NotFoundPage = () => {
    return (
                <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: 0, y: '2vw' }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
        <>
        <Helmet>
          <title>{ title }</title>
        </Helmet>
        <div className="main-container">
           <div className='notfound-container'>
           <img src={scheme} />
        </div>
        </div>
        </>
            </motion.div>
        
    )
}

export default NotFoundPage;