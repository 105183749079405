import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import scheme from '../img/Asset-scheme.svg';
import { News_3189 } from '../containers/News_3189.jsx';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Новости ЗСПД 3189'

const Page_3189 = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
        </Helmet>
         <div className='container-network Flex-cell Flex--1of1'>
                         <div className="info-container">
                       <div className="header">
            <h1>Новости ЗСПД 3189</h1>
            <p>Ознакомьтесь с последними новостями ЗСПД </p>
        </div>
                <News_3189></News_3189>
            </div>
        </div>
        </>
    )
}

export default Page_3189;