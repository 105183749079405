export default {
    items: [
      {
        id: "id1",
        title: "Как расшифровать и установить дистрибутивы (он же dst-файл, ПКИ)?",
        text1:
          "Если Вы используете VipNet PKI - Воспользуйтесь следующей инструкцией",
        button1: "/Установка_ключей.pdf"

      }
    ]
  };
  