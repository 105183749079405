import React, { useState, useEffect, Component } from "react";
import ReactDOM from "react-dom";
import { Host } from '../host/host.component';
import { motion } from "framer-motion";


export const HostList = props => (
    <div className='host-list'>
        {props.hosts.map(host => (
            <Host key={host.id} host={host} />
        ))}
    </div>
);
