import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import  Status_3189 from '../containers/Status_3189.jsx';
import { Helmet } from 'react-helmet';
import shield from '../img/shield.svg';

const title = 'ЦИТиС ЗСПД | Статус связи с ЗСПД 3189'

export default class Page_3189_status extends React.Component {
  render() {
      return (
          <>
            <Helmet>
            <title>{ title }</title>
          </Helmet>
          <div className='container-network'>
              <div className="sidebar">
                        <div className="side-menu">
                        <span className="nav-title">МЕНЮ</span>
                        <NavLink exact to='/' className="nav-link" activeClassName="nav-link-active">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" activeClassName="icon-active" width="21" height="21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <polyline points="5 12 3 12 12 3 21 12 19 12" />
    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
  </svg>
                              Главная
                          </NavLink>
                          <NavLink exact to='/3189/news' className="nav-link" activeClassName="nav-link-active">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-news" width="21" height="21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
    <line x1="8" y1="8" x2="12" y2="8" />
    <line x1="8" y1="12" x2="12" y2="12" />
    <line x1="8" y1="16" x2="12" y2="16" />
  </svg>
                              Новости
                          </NavLink>
                          <NavLink exact to='/3189/' className="nav-link" activeClassName="nav-link-active">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="21" height="21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <path d="M7 7h10v6a3 3 0 0 1 -3 3h-4a3 3 0 0 1 -3 -3v-6" />
    <line x1="9" y1="3" x2="9" y2="7" />
    <line x1="15" y1="3" x2="15" y2="7" />
    <path d="M12 16v2a2 2 0 0 0 2 2h3" />
  </svg>
                              Подключение
                          </NavLink>
                          <NavLink exact to="/3189/faq" className="nav-link" activeClassName="nav-link-active">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-info-square" width="21" height="21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <line x1="12" y1="8" x2="12.01" y2="8" />
    <rect x="4" y="4" width="16" height="16" rx="2" />
    <polyline points="11 12 12 12 12 16 13 16" />
  </svg>
                              Вопросы и ответы
                          </NavLink>
                          <NavLink exact to="/3189/contacts" className="nav-link" activeClassName="nav-link-active">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="21" height="21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54"  fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
  </svg>
                              Контакты
                          </NavLink>
                          <span className="nav-title">ПРОЧЕЕ</span>
                          <NavLink exact to="/3189/status" className="nav-link" activeClassName="nav-link-active">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-line" width="21" height="21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <circle cx="6" cy="18" r="2" />
    <circle cx="18" cy="6" r="2" />
    <line x1="7.5" y1="16.5" x2="16.5" y2="7.5" />
  </svg>
  Проверка доступа
                          </NavLink>
                      </div>
              </div>
              <div className="info-container overflow">
                        <div className="header">
              <h1>Доступ к ЗСПД 3189</h1>
              <p>Узнайте о наличии доступа к ЗСПД 3189 после смены мастер-ключей</p>
          </div>
                  <Status_3189></Status_3189>
              </div>
          </div>
          </>
      )
  }
}
