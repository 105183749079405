import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import Arrow from "./Arrow";
import { motion } from "framer-motion";

function Quest_3189_2(props) {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon");
    
    const content = useRef(null);

      function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
          setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
          setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
        );
      }
            return (
            <>
            <div className="container_stroke_steps wds">
                            <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
            <div className="container_post_connection ll">
                <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
                    <p className="accordion-title">{props.title}</p>
                    <Arrow className={`${setRotate}`} width={12} fill={"#343B54"} />
                </button>
                      <div
                        ref={content}
                        style={{ maxHeight: `${setHeight}` }}
                        className="accordion-content"
                      >
                      <div
                          className="accordion-text"
                          dangerouslySetInnerHTML={{ __html: props.content }}
                        />
                        <a className="button-download-let" href="/Установка ViPNet Client.pdf" target="_blank">Подробнее</a>
                    </div>
                    
            </div>
                            </motion.div>
                            </div>
            </>
        );
};

export default Quest_3189_2;
