import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {Link, NavLink} from 'react-router-dom';
import { motion } from "framer-motion";

export const Connection_13833_networks = ({ placeholder, handleChange }) => (
    <>
                    <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                        <div className="container_choosen_type">
                        <NavLink exact to='/13833' className="nav-link-connect" activeClassName="nav-link-connect-active" >
                            Подключение с использованием СКЗИ ViPNet Client
                        </NavLink>
                        <NavLink exact to='/13833/networks' className="nav-link-connect" activeClassName="nav-link-connect-active">
                             Подключение с помощью межсетевого взаимодействия
                        </NavLink>
                    </div>
                    
        <div className="info-container">
       <div className="header">
           <h1>Подключение с помощью межсетевого взаимодействия</h1>
       </div>
    </div>
    <div className="container_stroke_steps">
    <div className="container_post_connection">
    <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">01</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
          <h2>Сбор документов</h2>
       </div>
       <div className="container_steps_main">
         Для организации межсетевого взаимодействия с ЗСПД 13833 необходимо собрать следующие документы:
        </div>
        <div className="container_steps_main num">
        <div className="container_steps_ico">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-invoice" width="42" height="42" viewBox="0 0 24 24" stroke-width="1" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
  <line x1="9" y1="7" x2="10" y2="7" />
  <line x1="9" y1="13" x2="15" y2="13" />
  <line x1="13" y1="17" x2="15" y2="17" />
</svg> </div> <p>Заявление на подключение к сети 13833 юридического лица;<a className="button-download-let sml" href="/04 Форма заявления 13833 межсеть.docx" download>Скачать</a></p>
        </div>
        <div className="container_steps_main num">
        <div className="container_steps_ico">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-license" width="42" height="42" viewBox="0 0 24 24" stroke-width="1" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M15 21h-9a3 3 0 0 1 -3 -3v-1h10v2a2 2 0 0 0 4 0v-14a2 2 0 1 1 2 2h-2m2 -4h-11a3 3 0 0 0 -3 3v11" />
  <line x1="9" y1="7" x2="13" y2="7" />
  <line x1="9" y1="11" x2="13" y2="11" />
</svg> </div> Заверенная копия лицензии на право пользования ПО ViPNet Administrator<br></br> или Акт приема-передачи программного
обеспечения.
        </div>
        <div className="container_steps_main_p bc">
        Внимание! В соответствии с пунктом 2.5 Регламента подключения к ЗСПД 13833 переписка заявителя с органом КЗИ ЦБИ 
        должна осуществляться посредством электронной почты с адреса, указанного в разделе 2 заявления на подключение к ЗСПД 13833 
        с сохранением истории переписки. Информация, направленная с другой электронной почты, рассматриваться не будет.
           </div>
    </div>
    
        <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">02</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Отправка документов на предварительное согласование</h2>
       </div>
       <div className="container_steps_main fd">
       Скан-копии документов (в формате .pdf), указанных в шаге 1, совместно с действующим квалифицированным сертификатом
             ключа проверки электронной подписи (в формате .cer), выданным на лицо, получающее парольную и справочно-ключевую 
             информацию, необходимо направить на электронную почту 13833vipnet@citis.ru. В теме письма необходимо указать:
              «Заявление на подключение к ЗСПД 13833», а также краткое наименование органа или организации (дальнейшую переписку нужно вести в 
              рамках первого письма с сохранением истории).
         <br></br>
        </div>
    </div>
        <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">03</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
          <h2>Получение ответа</h2>
       </div>
       <div className="container_steps_main">
         После проверки (до 5 рабочих дней) на ваш адрес электронной почты придёт уведомление о положительном результате проверки или перечень обнаруженных замечаний.
        </div>
    </div>
            <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">04</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Отправка пакета документов подписанных электронной подписью</h2>
       </div>
       <div className="container_steps_main">
       После получения уведомления о положительном результате просим направить пакет документов на электронную почту 13833vipnet@citis.ru единым 
         файлом в формате .pdf, подписанного квалифицированной электронной подписью руководителя организации или лица, действующего на основании доверенности (с обязательным приложением файла в формате .sig).
        </div>
        <div className="container_steps_main_p bc">
Примечание - необходимо направить, как .pdf, так и .sig файл.
           </div>
    </div>
            <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">05</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Формирование и отправка информации первичного межсетевого экспорта</h2>
       </div>
       <div className="container_steps_main">
       В случае положительной проверки документов в течение 10 рабочих дней с момента получения и регистрации документов
        осуществляется формирование информации первичного межсетевого экспорта, зашифрованной на ключе проверки электронной
         подписи получателя (входит в состав сертификата получателя). После чего в адрес заявителя (указанный в пункте
         1 заявления на подключение к сети 13833 юридического лица) направляется письмо-уведомление о направлении информации
         первичного межсетевого экспорта. Если в результате проверки документов были обнаружены замечания, на адрес электронной
          почты (с которого вы направляли документы на предварительное согласование), будет направлен перечень обнаруженных
          замечаний.
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">06</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Получение и установка информации первичного межсетевого экспорта, формирование и отправка информации ответного межсетевого экспорта</h2>
       </div>
       <div className="container_steps_main">
         Заявитель после получения отправления от уполномоченного подразделения 
         выполняет его проверку на наличие файла, содержащего информацию первичного 
         межсетевого экспорта в зашифрованном виде (файл с расширением .enc) и файла, 
         содержащего сертификат уполномоченного подразделения (файл с расширением .cer). 
         Заявитель выполняет расшифрование файла, содержащего информацию первичного 
         межсетевого экспорта, с использованием СКЗИ и ключа электронной подписи 
         сертификата получателя.Заявитель выполняет проверку содержимого расшифрованного 
         файла на наличие информации первичного межсетевого экспорта (файлы с расширением .lzh, 
         .key и .txt). При получении письма-уведомления о передаче информации первичного 
         межсетевого экспорта заявитель проверяет соответствие информации, указанной в письме. 
         В случае если в результате проверки отправлений не было выявлено нарушений, заявитель 
         выполняет установку информации первичного межсетевого экспорта и формирование 
         информации ответного межсетевого экспорта. Заявитель зашифровывает файл 
         информации ответного межсетевого экспорта, используя ключ проверки 
         электронной подписи уполномоченного подразделения, содержащийся в сертификате 
         уполномоченного подразделения, и направляет зашифрованный файл в уполномоченное 
         подразделение. Одновременно с этим заявитель направляет на электронную почту 13833vipnet@citis.ru письмо-уведомление о получении информации первичного межсетевого экспорта и о передаче информации ответного межсетевого экспорта в формате .pdf, подписанное квалифицированной электронной подписью руководителя организации или лица, действующего на основании доверенности (с обязательным приложением файла в формате .sig).
        </div> <a className="button-download-let" href="/10_Письмо_уведомление_о_получении_межсетевой_информации_для_13833.docx" download>Скачать</a>
    </div>
                    <div className="container_post_connection">
       <div className="container_steps_header">
                  <div class="news-date">
            <span class="news-date__title">07</span>
            <span class="news-date__txt">ШАГ</span>
            </div>
           <h2>Получение и установка информации ответного межсетевого экспорта</h2>
       </div>
       <div className="container_steps_main">
         Уполномоченное подразделение выполняет расшифрование файла, содержащего информацию ответного межсетевого экспорта, с использованием средства электронной подписи и ключа электронной подписи сертификата уполномоченного подразделения. Уполномоченное подразделение выполняет проверку содержимого расшифрованного файла на наличие информации ответного межсетевого экспорта (файл с расширением .lzh). При получении письма-уведомления о получении информации первичного межсетевого экспорта и о передаче информации ответного межсетевого экспорта уполномоченное подразделение проверяет соответствие информации, указанной в письме. При положительном результате проверки уполномоченное подразделение выполняет установку информации ответного межсетевого экспорта, проверку соответствия информации, указанной в разделе 3 заявления, и направляет заявителю письмо-уведомление о получении информации ответного межсетевого экспорта.
         <br></br>
<br></br>
         Процесс обмена информацией межсетевого взаимодействия считается завершенным после получения заявителем письма-уведомления о получении информации ответного межсетевого экспорта.
        </div>
    </div>
    </div>
    </motion.div>
    </>
);