import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import {Link, NavLink} from 'react-router-dom';

export const Connection_3608_gos = ({ placeholder, handleChange }) => (
    <>
                    <motion.div className="esia"
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                <a className="button-download-let-3608-esia" href="/esia/">Внесение сведений в ФИС ФРДО через ЕСИА (Госуслуги)</a>
    </motion.div>
    </>
);