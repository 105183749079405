import React from 'react';
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';
import dataOtherQ from "../elements/data-13833allq";
import Accordion from "../elements/Accordintation-13833";

const title = 'ЦИТиС ЗСПД | Руководства ЗСПД 13833'

const container = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 10
      }
    }
  };


  const item = {
     hidden: { opacity: 0 },
     visible: {opacity: 1}
  };

const GuidesFaq = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
                  </Helmet> 
                  <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                                
            <div id="3189" className="container-guides-section-faq">
                
                <div className="container-guides-heading-faq">
                    <h1>Ответы на часто задаваемые вопросы</h1>
                </div>
                <div className="container-guides-sqd">
                    <div className="container-guides-faq">
                            <h1 id="otherq">Общие вопросы и ответы</h1>
                        <Accordion items={dataOtherQ.items}/>
                        </div> 
                </div>
            </div>
            </motion.div>
        </>
    )
}

export default GuidesFaq;