import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import {Link, NavLink} from 'react-router-dom';

export const Presentation_13833 = ({ placeholder, handleChange }) => (
    <>
                    <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                <a className="button-download-let-13833-v" href="/Порядок_подключения_к_13833.pdf">Презентация порядка подключения к защищенной сети передачи данных 13833</a>
                <a className="button-download-let-13833-v" href="/guides/man/faq/">Вопросы-ответы, инструкции по защищенной сети передачи данных 13833</a>
    </motion.div>
    </>
);