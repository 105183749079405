import React from 'react';
import {Link, NavLink, Router, BrowserRouter} from 'react-router-dom';
import scheme from '../img/Asset-scheme.svg';
import { Change_master_keys } from '../containers/Change_master_keys.jsx';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Cмена мастер-ключей'


const Page_3189_change_master_keys = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
        </Helmet>
            <div className='container-network Flex-cell Flex--1of1'>
                <div className="info-container">
                    <Change_master_keys></Change_master_keys>
                </div>
            </div>
        </>
    
    )
}

export default Page_3189_change_master_keys;