export default {
    items: [
      {
        id: "id1",
        title: "Где можно скачать VipNet Client? ",
        text1:
          "Вы можете скачать актуальную версию ПО ViPNet Client с официального сайта разработчика",
        button1: "https://infotecs.ru/downloads/demo-versii/vipnet-client-4-for-windows-4.5.3.65102.html?show_form=Y"

      },
      {
        id: "id2",
        title: "Как узнать номер сети и абонентский пункт?",
        text1: "Вы можете узанть абонетский пункт и номер сети, воспользовавшись данной инструкцией",
        button1: "/АП.pdf"
    }
    ]
  };
  