import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";

export const News_3608 = ({ placeholder, handleChange }) => (
    <>
                <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                <div className="container_stroke_steps">
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>22.03.2024</p>
       </div>
       <div className="container_steps_news">
           <h2>Регламент</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных № 3608!
            <br></br>
            Обращаем Ваше внимание, что в соответствии с Регламентом подключения к защищенной сети передачи данных 3608, утвержденного приказом ФГАНУ ЦИТиС от 22 марта 2024 г. № 88, с 1 апреля текущего года исключается возможность подключения к указанной сети организаций, не имеющих лицензии на осуществление образовательной деятельности.
Убедительная просьба при подключении к защищенной сети передачи данных 3608 действовать в соответствии с установленными Регламентом порядком и правилами.

              <a className="button-download-let" href="/rg3608.pdf" target="_blank">Регламент</a>
        </div>
        
    </div> 
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>12 января 2024</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       <p>Уважаемые пользователи защищенной сети передачи данных № 3608.</p>
<p>Уведомляем о том, что в период с 05 февраля по 16 февраля текущего года будет осуществляться смена мастер-ключей для ЗСПД 3608.<br></br>
Руководство о порядке действий пользователей при смене мастер-ключей размещено в разделе Руководство.
</p>
<a class="button-download-let" href="/guides/man/change-master-keys">Руководство</a>
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>07 февраля 2023</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       <p>Уважаемые пользователи защищенной сети передачи данных № 3608.</p>
<p>Напоминаем о том, что в период с 6 февраля по 17 февраля текущего года проводится смена мастер-ключей для ЗСПД 3608.<br></br>
Для успешного завершения данной процедуры от пользователя требуется лишь, чтобы на компьютере, подключенном к ЗСПД 3608, была запущена программа ViPNet Client (альтернативное название «Монитор»). 
<br></br>Включите программу и введите Ваш пароль. После прохождения авторизации можете свернуть окно программы. Оно будет активно в контекстном меню панели задач.<br></br>
ВАЖНО: НЕ ЗАКРЫВАЙТЕ программу и НЕ ВЫКЛЮЧАЙТЕ компьютер. Программа ViPNet Client (Монитор) должна быть активна в течении всего периода смены мастер-ключей в рабочие дни.<br></br>
Результаты обновления мастер-ключей пользователь сможет узнать только после завершения периода смены мастер-ключей. 
Если смена мастер-ключей прошла успешно, то 18 февраля вход в ФИС ФРДО будет доступен. В противном случае необходимо на email: 3608vipnet@citis.ru направить скриншот вашего абонентского пункта и открытую часть квалифицированного сертификата ключа проверки электронной подписи (инструкция по ссылке: https://zspd.citis.ru/guides/faq), указав в теме письма: «Нет доступа к ФИС ФРДО после смены мастер-ключей».

</p>
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>10 января 2023</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       <p>Уважаемые пользователи защищенной сети передачи данных № 3608.</p>
<p>Напоминаем о том, что в период с 06 февраля по 17 февраля текущего года будет осуществляться смена мастер-ключей для ЗСПД 3608.<br></br>
Информация о согласовании Рособрнадзором указанной процедуры и сроков ее проведения прилагается.<br></br>
С руководством о порядке действий пользователей при смене мастер-ключей можно ознакомиться по данной ссылке: <a class="change_master_key_link" href="https://zspd.citis.ru/guides/man/change-master-keys">https://zspd.citis.ru/guides/man/change-master-keys</a>
</p>
<a class="button-download-let" href="/06-58-1017.06-14.pdf" download="">Скачать</a>
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>05.10.2022</p>
       </div>
       <div className="container_steps_news">
           <h2>Технические работы ЗСПД 3608</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных № 3608.<p>Сообщаем Вам о том, что 7 октября 2022 года будут проводиться технические
         работы на сети связи. В этот период возможно кратковременное прерывание доступа к ФИС ФБДА, АКНД ПП.</p>
    </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>02.03.2022</p>
       </div>
       <div className="container_steps_news">
           <h2>Технические работы ЗСПД 3608</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных № 3608.
    В связи с проводимыми плановыми техническими работами на сети возможны пропадания связи в период с 2 марта по 3 марта 2022 года.
    </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>15.02.2022</p>
       </div>
       <div className="container_steps_news">
           <h2>Технические работы ЗСПД 3608</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных № 3608.
        <p>В связи с проводимыми техническими работами на сети возможны пропадания связи в период </p>с 15 февраля по 16 февраля 2022 года.
    </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>29.11.2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных ViPNet 3608! Федеральное государственное автономное научное учреждение 
       «Центр информационных технологий и систем органов исполнительной власти» сообщает о завершении процедуры смены мастер-ключей в з
       ащищенной сети передачи данных ViPNet 3608. В связи с этим, просим проверить доступ к Федеральному реестру сведений о документах 
       бразовании и (или) о квалификации, документах об обучении (ФИС ФРДО). В случае возникновения проблем при подключении 
       к информационной системе просим обращаться в службу технической поддержки сети VipNet 3608.
       Контактные данные службы технической поддержки сети VipNet 3608:
       <p>E-mail: 3608vipnet@citis.ru</p>
       Телефон: +7(800)200-65-64, +7(495)197-65-91.
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>22.10.2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных 3608 (ЗСПД 3608) сообщаем Вам о том, что с 15 по 26 ноября 2021 года будет осуществляться смена мастер-ключей в ЗСПД 3608.
       <a className="button-download-let" href="/guides/doc/3608">Подробнее</a>
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>07.10.2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Регламент ЗСПД 3608</h2>
       </div>
       <div className="container_post_main">
         Уважаемые пользователи защищенной сети передачи данных Федеральной службы по надзору в сфере 
         образования и науки № 3608 сообщаем Вам о том, что с 01.11.2021 года вступает в силу новый Регламент
         подключения к защищенной сети передачи данных №3608
         <a className="button-download-let" href="/guides/doc/3608" target="_blank">Подробнее</a>
    </div>
    </div>
    <div className="container_post_connection">
       <div className="container_post_header">
           <p>18.03.2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Технические работы</h2>
       </div>
       <div className="container_post_main">
         Уважаемые пользователи защищенной сети передачи данных Федеральной службы по надзору в сфере образования и науки № 3608 сообщаем Вам о том, что 19 марта 2021 года с 00:00 до 06:00 по московскому времени будут проводиться технические работы на сети связи. В этот период возможно кратковременное прерывание доступа к ФИС ФРДО, ФИС ФБДА, АКНД ПП.
        </div>
    </div>
        <div className="container_post_connection">
       <div className="container_post_header">
           <p>08.10.2020</p>
       </div>
       <div className="container_steps_news">
           <h2>Технические работы</h2>
       </div>
       <div className="container_post_main">
         Уважаемые пользователи защищенной сети передачи данных Федеральной службы по надзору в сфере образования и науки № 3608 сообщаем Вам о том, что 09.10.2020 будут проводиться технические работы с координатором SM Obrnadzor- HW-2.
            В период проведения работ будет отсутствовать доступ к федеральной информационной системе «Федеральный реестр апостилей, проставленных на документах об образовании и (или) о квалификации» (ФИС ФБДА).
            Федеральная информационная система «Федеральный реестр сведений о документах об образовании и (или) о квалификации, документах об обучении» (ФИС ФРДО) будет работать в штатном режиме.
        </div>
    </div>
    
            <div className="container_post_connection">
       <div className="container_post_header">
           <p>27.02.2020</p>
       </div>
       <div className="container_post_main">      
        Уважаемые пользователи защищенной сети передачи данных Федеральной службы по надзору в сфере образования и науки № 3608 (далее – ЗСПД 3608) сообщаем Вам о том, что 28 февраля 2020 года с 16-00 по московскому времени будут проводиться технические работы. Ориентировочное время проведения работ — 5 часов. Во время проведения работ доступа к координаторам ЗСПД 3608 и федеральной информационной системе «Федеральный реестр сведений о документах об образовании и (или) о квалификации, документах об обучении» не будет. В связи со сменой ip адресов на координаторах ЗСПД 3608 рекомендуем заранее ознакомиться с инструкцией
                   <a className="button-download-let" href="/Инструкция_3608_1.pdf" target="_blank">Подробнее</a>
        </div>
    </div>
    
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>20.02.2020</p>
       </div>
       <div className="container_post_main">
            Уважаемые пользователи защищенной сети передачи данных Федеральной службы по надзору в сфере образования и науки № 3608 сообщаем Вам о том, что 21.02.2020 будет производиться смена ip адресов на координаторах SM Obrnadzor- HW-2 (ФИС ФБДА) и SM-Remote Access. Данные координаторы не задействованы в работе ФИС ФРДО.
            <br></br>
            В случае возникновения проблем рекомендуем ознакомиться с инструкцией
              <a className="button-download-let" href="/Инструкция_3608.pdf" target="_blank">Подробнее</a>
        </div>
        
    </div> 
                    <div className="container_post_connection">
       <div className="container_post_header">
           <p>09.01.2020</p>
       </div>
       <div className="container_post_main">
            Уважаемые пользователи защищенной сети передачи данных Федеральной службы по надзору в сфере образования и науки № 3608 (далее – ЗСПД 3608) сообщаем Вам о том, что с 09.01.2020 сопровождение и развитие ЗСПД 3608 осуществляет Федеральное государственное автономное научное учреждение «Центр информационных технологий и систем органов исполнительной власти
        </div>
        
    </div>
    </div>
    
        </motion.div>
    </>
);