import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import scheme from '../img/Asset-scheme.svg';
import { Contacts_3189 } from '../containers/Contacts_3189.jsx';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Контакты технической поддержки ЗСПД 3189'

const Page_3189_contacts = () => {
    return (
        <>
          <Helmet>
          <title>{ title }</title>
        </Helmet>
         <div className='container-network Flex-cell Flex--1of1'>
            <div className="info-container">
            <div className="header">
            <h1>Контакты технической поддержки ЗСПД 3189</h1>
            <p>Контакты и адрес технической поддержки ЗСПД 3189</p>
        </div>
                <Contacts_3189></Contacts_3189>
            </div>
        </div>
        </>
    )
}

export default Page_3189_contacts;