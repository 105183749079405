import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";

export const Contacts_3189 = ({ placeholder, handleChange }) => (
    <>
                <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                <div className="container_stroke_steps">
  <div className="container_post_connection">
    <div className="container_post">
       <div className="container_post_header">
               <svg width="31" height="31" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21C14.0993 20.763 10.4202 19.1065 7.65683 16.3432C4.8935 13.5798 3.23705 9.90074 3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4" stroke="#343B54" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
        <div className="container_post_contact">
               <div className="container_post_network_title"><a href="tel:+78002006564">+7 (800) 200-65-64</a>
               </div>
            <div className="container_post_network_title down"><a href="tel:+74951976591">+7 (495) 197-65-91</a></div>
               
           </div>
       </div>
              <div className="container_info_contact">
            C понедельника по  четверг с 9:00 до 18:00 по МСК<br></br>В пятницу с 9:00 до 17:00  по МСК
        </div>
    </div>
        <div className="container_post">
       <div className="container_post_header">
               <svg width="31" height="31" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z" stroke="#343B54" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 7L12 13L21 7" stroke="#343B54" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        <div className="container_post_contact">
               <div className="container_post_network_title"><a href ="mailto:3189vipnet@citis.ru">3189vipnet@citis.ru</a>
               </div>           
           </div>
       </div>
    </div>
            <div className="container_post">
       <div className="container_post_header">
               <svg width="31" height="31" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 6V6.01" stroke="#343B54" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 13L14.5 7.99997C14.1631 7.391 13.9909 6.70466 14.0004 6.00876C14.0099 5.31287 14.2008 4.63149 14.5543 4.03197C14.9077 3.43245 15.4115 2.93552 16.0158 2.59029C16.6201 2.24506 17.304 2.06348 18 2.06348C18.696 2.06348 19.3799 2.24506 19.9842 2.59029C20.5885 2.93552 21.0923 3.43245 21.4457 4.03197C21.7992 4.63149 21.9901 5.31287 21.9996 6.00876C22.0091 6.70466 21.8369 7.391 21.5 7.99997L18 13Z" stroke="#343B54" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5 4.75L9 4L3 7V20L9 17L15 20L21 17V15" stroke="#343B54" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 4V17" stroke="#343B54" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 15V20" stroke="#343B54" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


        <div className="container_post_contact">
               <div className="container_post_network_title">123557, Москва, Пресненский Вал, 19, стр. 1
               </div>           
           </div>
       </div>
    </div>
    </div>
    </div>
    </motion.div>
    </>
);