import React, { useState, useEffect, Component } from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";

export const Host = props => (
    <motion.div 
    className="overlay-form-answer"
    initial={{opacity: 0 }}
    transition={{ duration: 1 }}
    animate={{opacity: 1, y: "-20vw" }}
    exit={{opacity: 0}}>
                    <div className="overlay-upper gj"></div>
                    <div className="overlay-form-inner">
                        <h1 className="overlay-form-title">
                            Обновление мастер-ключей прошло успешно
                        </h1>
                        <p className="overlay-form-description">
                        Данное сообщение означает, что после смены мастер-ключа, у введенного Вами идентификатора 
                        имеется доступ к защищенной сети передачи данных 3189! Если это не так или у вас остались 
                        вопросы, напишите нам на электронную почту: 3189vipnet@citis.ru
                        </p>
                        <div className="overlay-form-answer-description">
                            <div className="overlay-form-status">Индентификатор узла: <div className="status-title">{props.host.idHost}</div></div>
                            <div className="overlay-form-status">Cтатус: <div className="status-title sgj">{props.host.statusHost}</div></div>
                        </div>
        </div>
    </motion.div>
);