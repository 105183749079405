import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";


const title = "ЦИТиС ЗСПД | Подключение к модулю ЕР ЦДО с помощью TLS ГОСТ";


const TlsErcdo = () => {
  const [currentContent, setCurrentContent] = useState("browser");

  const createContent = (dataContent) => {
    const contentDiv = document.getElementById("contentSpace");
    const contentStart = document.getElementById("contentStart");

    if (contentStart) {
      contentStart.remove();
    }

    const newContent = document.createElement("div");
    newContent.classList.add("manuals_base-container-tls");
    // newContent.classList.add("animated-fade");
    newContent.id = dataContent;

    if (dataContent === "browser") {
      newContent.innerHTML = `
      <div class="manuals_base-container-tls-left">
      <h1>Вы используете браузер, поддерживающий шифрование ГОСТ?</h1>
      <p>TLS — криптографический протокол, обеспечивающий защищённую передачу данных в интернете. Если сайт запрашивает шифрование TLS по ГОСТу, браузер проверяет, установлена ли программа КриптоПро CSP. Если программа установлена, ей передаётся управление.</p>
      <div class="manuals_base-container-tls-row">
      <button class="manual_button beginning" data-content="crypto_pro" type="button">Да</button>
      <button class="manual_button denial" data-content="browser-trouble-select-os" type="button">Нет, нужна помощь</button>
      </div>
      </div>
    <div class="manuals_base-container-tls-right">
    <svg width="351" height="288" viewBox="0 0 351 288" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34 19.75C34 15.0424 35.8701 10.5276 39.1989 7.19886C42.5276 3.87009 47.0424 2 51.75 2H300.25C304.958 2 309.472 3.87009 312.801 7.19886C316.13 10.5276 318 15.0424 318 19.75V268.25C318 272.958 316.13 277.472 312.801 280.801C309.472 284.13 304.958 286 300.25 286H51.75C47.0424 286 42.5276 284.13 39.1989 280.801C35.8701 277.472 34 272.958 34 268.25V19.75Z" fill="white" stroke="black" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M34 73H318" stroke="black" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M105 2V73" stroke="black" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M35 20C35 10.6112 42.6112 3 52 3H104V72H35V20Z" fill="#D51818"/>
    <path d="M25.5455 223L0.0454547 129.909H8.68182L29.7727 209.955H30.5L51.5909 129.909H61.4091L82.5 209.955H83.2273L104.318 129.909H113L87.5 223H78.4091L56.8182 144.045H56.2273L34.6364 223H25.5455ZM144.545 223L119.045 129.909H127.682L148.773 209.955H149.5L170.591 129.909H180.409L201.5 209.955H202.227L223.318 129.909H232L206.5 223H197.409L175.818 144.045H175.227L153.636 223H144.545ZM263.545 223L238.045 129.909H246.682L267.773 209.955H268.5L289.591 129.909H299.409L320.5 209.955H321.227L342.318 129.909H351L325.5 223H316.409L294.818 144.045H294.227L272.636 223H263.545Z" fill="black"/>
    <path d="M106 3H300C309.389 3 317 10.6112 317 20V72H106V3Z" fill="#7572FF"/>
    </svg>
    </div>
      `;
    } else if (dataContent === "yandex_check") {
      newContent.innerHTML = `
      <div class="manuals_base-container-tls-left">
      <h1>Если вы используете Яндекс браузер, проверьте его настройки</h1>
      <p>В поиске по настройкам напишите «ГОСТ»,
      у Вас должны быть включены эти параметры, как указано на картинке справа.</p>
      <div class="manuals_base-container-tls-row">
      <button class="manual_button beginning" data-content="unable-connection" type="button">Далее</button>
      </div>
      </div>
    <div class="manuals_base-container-tls-right">
    <img class="img_def" src="/gost_ya.png" />
    </div>`;
      } else if (dataContent === "crypto_pro") {
        newContent.innerHTML = `
          <div class="manuals_base-container-tls-left">
            <h1>У Вас установлен КриптоПро CSP?</h1>
            <p>Для организации TLS ГОСТ на АРМ пользователя должен быть установлен Криптопровайдер с поддержкой криптоалгоритмов ГОСТ или браузер с поддержкой криптоалгоритмов ГОСТ</p>
            <div class="manuals_base-container-tls-row">
            <button class="manual_button beginning" data-content="yandex_check"  type="button">Да</button>
            <button class="manual_button denial" data-content="crypto-trouble-select-os" type="button">Нет, нужна помощь</button>
            </div>
            </div>
          <div class="manuals_base-container-tls-right"></div>`;

      } else if (dataContent === "begin-page") {
        newContent.innerHTML = `
          <div id="contentStart" class="manuals_base-container-tls">
          
          <div class="manuals_base-container-tls-left">
            <h1>Руководство пользователя по подключению к системе ФИС ФРДО
            <span> (модуль ЕР ЦДО) </span> с использованием протокола TLS ГОСТ</h1>
            <p>Предложенный алгоритм действий предназначен для проверки наличия необходимых
            компонентов на рабочем месте и возможные решения в случае
            отсутствия или некорректной работы перечисленных компонентов.</p>
            <button id="buttonStart" data-content="browser" class="manual_button beginning" type="button">Начать</button>
          </div>
          <div class="manuals_base-container-tls-right">         
                <svg width="518" height="452" viewBox="0 0 518 492" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M215.356 78.7316C238.71 70.0374 254.088 43.0151 278.841 40.6906C303.066 38.4155 326.522 52.074 345.358 66.5232C363.341 80.3187 367.252 105.844 383.814 121.216C400.467 136.673 428.822 138.044 442.116 156.331C455.293 174.455 461.296 199.178 456.529 221.239C451.651 243.814 419.085 255.075 415.852 277.89C411.681 307.314 449.316 336.058 436.412 363.183C425.224 386.7 385.802 378.167 362.294 390.468C339.683 402.301 324.894 426.684 300.516 434.295C275.802 442.011 248.95 438.867 223.855 433.692C198.816 428.529 174.189 419.634 154.377 404.161C134.988 389.016 125.682 365.417 111.237 345.732C96.7355 325.969 67.5789 311.37 68.2789 286.826C69.0589 259.482 111.09 245.349 114.875 218.172C118.653 191.051 82.4064 168.567 88.4801 141.79C93.943 117.705 121.005 102.283 143.723 90.9919C165.615 80.1113 192.432 87.2655 215.356 78.7316Z" fill="url(#paint0_linear_536_1205)"/>
                  <path d="M3 416H347.5" stroke="black" stroke-width="3"/>
                  <rect x="107.5" y="400.5" width="135" height="15" fill="#0C9CF4" stroke="black" stroke-width="3"/>
                  <path d="M131.5 348.5V400.5H218V348.5H131.5Z" fill="#0C9CF4" stroke="black" stroke-width="3"/>
                  <rect x="1.5" y="76.5" width="348" height="272" rx="22.5" stroke="black" stroke-width="3"/>
                  <rect x="10" y="85" width="332" height="254" rx="16" fill="#E7E7E7"/>
                  <mask id="path-7-inside-1_536_1205" fill="white">
                  <rect x="19" y="101" width="313" height="206" rx="2"/>
                  </mask>
                  <rect x="19" y="101" width="313" height="206" rx="2" fill="white" stroke="black" stroke-width="6" mask="url(#path-7-inside-1_536_1205)"/>
                  <rect x="22" y="104" width="307" height="200" fill="#D8EAFF"/>
                  <path d="M152.5 383V348.5H197V383H152.5Z" fill="#3DBBFF" stroke="black" stroke-width="3"/>
                  <rect x="287" y="382" width="23" height="15" fill="#FF9955"/>
                  <rect x="311" y="382" width="23" height="15" fill="#0C9CF4"/>
                  <path d="M286 397.5V415.5H335V397.5M286 397.5H310.5M286 397.5V381M335 397.5H310.5M335 397.5V381H321.5H298.5M310.5 397.5V381M310.5 381H298.5M310.5 381H286M298.5 381H286" stroke="black" stroke-width="3"/>
                  <rect class="lighting-lines"  x="116.564" y="157" width="119.405" height="12.8473" fill="#FFC661"/>
                  <rect class="lighting-lines" x="75" y="185.717" width="201.779" height="9.0687" fill="#FFC661"/>
                  <rect class="lighting-lines" x="75" y="201.588" width="201.779" height="9.0687" fill="#FFC661"/>
                  <rect class="lighting-lines" x="75" y="217.459" width="201.779" height="9.0687" fill="#FFC661"/>
                  <rect class="lighting-lines" x="75" y="242" width="88.4198" height="9.0687" fill="#FFC661"/>
                  <defs>
                  <linearGradient id="paint0_linear_536_1205" x1="201.165" y1="67.0032" x2="327.846" y2="424.654" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#2090FF"/>
                  <stop offset="1" stop-color="#45C7FF"/>
                  </linearGradient>
                  </defs>
                </svg>
          </div>
          </div>`;
        } else if (dataContent === "browser-trouble") {
          newContent.innerHTML = `
          <div class="manuals_base-container-tls-left">
            <h1>Установка браузера с поддержкой шифрования ГОСТ</h1>
            <p>Данный мануал предназначен для проверки наличия необходимых компонентов на рабочем месте и предложение решений в случае отсутствия или некорректной работы перечисленных компонентов</p>
            <div class="manuals_base-container-tls-row">
            <button class="manual_button beginning" data-content="crypto_pro" type="button">Да</button>
            <button class="manual_button denial" data-content="browser-trouble-select-os" type="button">Нет, нужна помощь</button>
            </div>
            </div>
          <div class="manuals_base-container-tls-right">
          </div>`;
        } else if (dataContent == "astra-browser") {
          newContent.innerHTML = `
        <div class="manuals_base-container-tls-column">
          <div class="manuals_base-container-tls-title">
            <div class="manuals_base-container-tls-title-row">
              <h1>Установка Яндекс браузера | </h1>
              <svg width="106" height="33" viewBox="0 0 106 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_542_1227)">
                <path d="M9.38207 13.8125H11.1805L14.5919 26.0755H13.1086L12.126 22.5665H8.30674L7.41681 26.0755H5.93359L9.38207 13.8125ZM10.272 14.8018H10.2349L8.62192 21.3346H11.8294L10.272 14.8018Z" fill="#0079C0"/>
                <path d="M17.0019 22.1936C17.0019 24.0041 17.5025 25.1427 19.3009 25.1427C20.6728 25.1427 21.6184 24.5454 21.6184 23.0895C21.6184 21.503 20.8768 21.111 19.6346 20.6444L17.8362 19.9725C16.7238 19.5432 15.8709 18.7032 15.8709 16.7434C15.8709 14.7835 17.2058 13.5703 19.1896 13.5703C21.3032 13.5703 22.5639 14.7835 22.6566 17.0234H21.3217C21.3217 15.6795 20.6543 14.7835 19.3009 14.7835C17.7991 14.7835 17.28 15.6981 17.28 16.7247C17.28 17.8446 17.5766 18.3486 18.3553 18.6286L20.302 19.3565C22.2302 20.0845 23.0274 21.2417 23.0274 22.8656C23.0274 25.18 21.5998 26.3373 19.2638 26.3373C16.9277 26.3373 15.6484 24.8067 15.6484 22.175L17.0019 22.1936Z" fill="#0079C0"/>
                <path d="M28.2738 26.0755H26.9204V15.0444H23.75V13.8125H31.4071V15.0444H28.2553L28.2738 26.0755Z" fill="#0079C0"/>
                <path d="M34.2812 26.0755H32.9277V13.8125H36.6358C38.7679 13.8125 40.1769 14.8577 40.1769 17.1536C40.1769 18.5535 39.3797 19.7854 37.9707 19.972V20.0093C39.2499 20.1587 40.0472 21.2786 40.0472 22.7904C40.0472 25.3476 40.2696 25.4409 40.455 25.8142V26.0942H39.0831C38.805 25.2916 38.6937 24.5823 38.6937 23.2571C38.6937 21.6332 37.9892 20.6999 36.4689 20.6999H34.2812V26.0755ZM34.2812 19.4494H36.6358C37.5813 19.4494 38.7679 19.0014 38.7679 17.2096C38.7679 15.4364 37.7667 15.0444 36.5801 15.0444H34.2812V19.4494Z" fill="#0079C0"/>
                <path d="M45.3489 13.8125H47.1473L50.5587 26.0755H49.0754L48.1113 22.5665H44.2735L43.3836 26.0942H41.9004L45.3489 13.8125ZM46.2203 14.8018H46.1832L44.5702 21.3346H47.7776L46.2203 14.8018Z" fill="#0079C0"/>
                <path d="M58.2714 24.8623H63.2402V26.0942H56.918V13.8125H58.2714V24.8623Z" fill="#0079C0"/>
                <path d="M66.2441 26.0755H64.8906V13.8125H66.2441V26.0755Z" fill="#0079C0"/>
                <path d="M70.9153 13.8125L74.3637 23.8544C74.5121 24.2837 74.6418 24.6383 74.7902 25.1982H74.8458C74.8272 24.7503 74.7902 24.2837 74.7902 23.8357V13.8125H76.1436V26.0755H73.9188L70.5259 16.2576C70.322 15.6604 70.1551 15.0444 70.0624 14.4285H70.0253C70.0439 15.0444 70.0624 15.679 70.0624 16.295V26.0755H68.709V13.8125H70.9153Z" fill="#0079C0"/>
                <path d="M79.9823 13.8125V22.6411C79.9823 23.593 80.1121 25.1422 82.2813 25.1422C84.4505 25.1422 84.5803 23.593 84.5803 22.6411V13.8125H85.9337V23.1264C85.9337 25.3102 84.4876 26.3555 82.2813 26.3555C80.075 26.3555 78.6289 25.3102 78.6289 23.1264V13.8125H79.9823Z" fill="#0079C0"/>
                <path d="M89.2517 13.8125L91.8102 18.5908L94.3502 13.8125H95.852L92.6075 19.7854L95.9447 26.0942H94.4059L91.8102 20.9613L89.1775 26.0942H87.6758L91.0316 19.7854L87.7499 13.8125H89.2517Z" fill="#0079C0"/>
                <path d="M99.4495 6.43859H57.5487C57.1779 6.43859 56.8627 6.73723 56.8627 7.1292C56.8627 7.5025 57.1594 7.81981 57.5487 7.81981H99.4495C102.008 7.81981 104.103 9.91031 104.103 12.5048V26.9516C104.103 29.5274 102.027 31.6366 99.4495 31.6366H71.2684H6.00702C3.44847 31.6366 1.35343 29.5461 1.37197 26.9516V12.4861C1.37197 9.91031 3.44847 7.81981 6.00702 7.80114H47.9449C48.3157 7.80114 48.6309 7.5025 48.6309 7.11053C48.6309 6.73723 48.3342 6.41992 47.9449 6.41992H6.00702C2.68833 6.43859 0 9.14503 0 12.4861V26.9329C0 30.274 2.68833 32.9804 6.00702 32.9804H71.2684H99.4495C102.768 32.9804 105.457 30.274 105.457 26.9329V12.4861C105.457 9.14503 102.768 6.43859 99.4495 6.43859Z" fill="#0079C0"/>
                <path d="M58.0113 4.23699L54.433 3.62104L53.0796 0.727941L52.7273 0L51.0216 3.60238L47.4434 4.21833L50.0019 7.0181L49.4272 10.9751L52.7273 9.14593L56.0275 10.9751L55.4342 7.0181L57.5107 4.74095L58.0113 4.23699ZM54.9892 6.85011L55.4713 10.1538L52.7088 8.64197L49.9648 10.1538L50.4469 6.85011L48.3147 4.51697L51.2997 3.99434L52.7088 1.00792L54.1178 3.99434L57.1028 4.51697L54.9892 6.85011Z" fill="#0079C0"/>
                <path d="M50.7067 7.44765L51.7078 8.8102L52.1343 8.56756L50.4842 5.13317L52.6534 8.28758L52.7275 8.25025L53.4877 8.66088L51.8191 4.96518L54.6557 9.31416L55.0451 9.51948L54.9894 9.10884L53.154 4.79719L54.7299 7.31699L54.6372 6.73837L55.1192 6.21574L54.4889 4.61054L55.3046 6.01043L56.4727 4.72253L53.9141 4.29323L52.7275 1.77344L51.541 4.29323L48.9824 4.72253L50.8179 6.73837L50.7067 7.44765Z" fill="#0079C0"/>
                <path d="M50.6141 8.17578L50.4102 9.51967L51.0961 9.14637L50.6141 8.17578Z" fill="#0079C0"/>
                <path d="M99.8764 15.3611C99.8764 16.2383 99.1719 16.9663 98.282 16.9663C97.4106 16.9663 96.6875 16.257 96.6875 15.3611C96.6875 14.4838 97.392 13.7559 98.282 13.7559C98.282 13.7559 98.282 13.7559 98.3005 13.7559C99.1533 13.7745 99.8764 14.4838 99.8764 15.3611ZM96.9285 15.3611C96.9285 16.1077 97.5403 16.7236 98.282 16.7236C99.0236 16.7236 99.6354 16.1077 99.6354 15.3611C99.6354 14.6145 99.0236 13.9985 98.282 13.9985C97.5403 13.9985 96.9285 14.6145 96.9285 15.3611ZM97.6887 14.4651H98.4674C98.764 14.4651 99.0977 14.5585 99.0977 14.9504C99.0977 15.3424 98.8196 15.4357 98.5415 15.4357L99.0792 16.257H98.8196L98.319 15.4357H97.9297V16.257H97.7072L97.6887 14.4651ZM97.9111 15.2677H98.4303C98.5971 15.2677 98.8567 15.2304 98.8567 14.9318C98.8567 14.6705 98.6157 14.6331 98.3932 14.6331H97.8926V15.2677H97.9111Z" fill="#0079C0"/>
                </g>
                <defs>
                <clipPath id="clip0_542_1227">
                <rect width="105.457" height="33" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            </div> 
          </div>
          <div class="manuals_base-container-tls-row-step">
            <div class="selection_box cursor-def">
              <div class="selection_box-container steps">
                <div class="selection_box-container-title">
                  <article class="title-step"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-archive" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                    <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                    <path d="M10 12l4 0" />
                  </svg><h1> шаг 1</h1></article>
                  <article>
                  <h2>Скачайте Яндекс браузер</h2>
                  <p>Нажмите на кнопку ниже, чтобы перейти на страницу скачивания дистрибутива Яндекс браузера и воспользуйтесь инструкцией по его установке с официального сайта Astra Linux.</p>
                  </article>
                </div>
                <a href="https://wiki.astralinux.ru/pages/viewpage.action?pageId=229280803" class="manual_button beginning">Скачать</a>
              </div>
            </div>
            <svg class="arrow-down" width="381" height="136" viewBox="0 0 381 136" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 8H366" stroke="#1E8DFF" stroke-width="2"/>
              <path d="M373 15V128" stroke="#1E8DFF" stroke-width="2"/>
              <path d="M373 16V10C373 8.89543 372.105 8 371 8H365" stroke="#1E8DFF" stroke-width="2"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8Z" fill="#1E8DFF"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M373 132L367 124H379L373 132Z" fill="#1E8DFF"/>
              </svg>
              

        <div class="selection_box long-hv">
        <div class="selection_box hv-500 long-left">
        <div class="selection_box-container steps">
          <div class="selection_box-container-title">
            <article class="title-step"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-terminal-2" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M8 9l3 3l-3 3" />
              <path d="M13 15l3 0" />
              <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
            </svg><h1> шаг 2</h1></article>
            <article>
            <h2>Проверьте настройки Яндекс браузера</h2>
            <p>В поиске по настройкам напишите «ГОСТ»,
            у Вас должны быть включены эти параметры, как указано на картинке справа.</p>
            </article>
          </div>
        </div>
      </div>
      <div class="selection_box long-right">
        <div class="selection_box-container steps">
        <img class="img_astra_ya" src="/gost_ya.png" />
        </div>
      </div>
        </div>
        <div class="manuals_base-container-tls-row-select buttons">
            <button class="manual_button denial" data-content="browser-trouble-select-os" type="button">Назад</button>
            <button class="manual_button beginning" data-content="crypto_pro" type="button">Далее</button>
        </div>
        </div>`;
      } else if (dataContent == "astra-crypto") {
        newContent.innerHTML = `
      <div class="manuals_base-container-tls-column">
          <div class="manuals_base-container-tls-title">
            <div class="manuals_base-container-tls-title-row">
              <h1>Установка КриптоПро CSP | </h1>
              <svg width="106" height="33" viewBox="0 0 106 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_542_1227)">
                <path d="M9.38207 13.8125H11.1805L14.5919 26.0755H13.1086L12.126 22.5665H8.30674L7.41681 26.0755H5.93359L9.38207 13.8125ZM10.272 14.8018H10.2349L8.62192 21.3346H11.8294L10.272 14.8018Z" fill="#0079C0"/>
                <path d="M17.0019 22.1936C17.0019 24.0041 17.5025 25.1427 19.3009 25.1427C20.6728 25.1427 21.6184 24.5454 21.6184 23.0895C21.6184 21.503 20.8768 21.111 19.6346 20.6444L17.8362 19.9725C16.7238 19.5432 15.8709 18.7032 15.8709 16.7434C15.8709 14.7835 17.2058 13.5703 19.1896 13.5703C21.3032 13.5703 22.5639 14.7835 22.6566 17.0234H21.3217C21.3217 15.6795 20.6543 14.7835 19.3009 14.7835C17.7991 14.7835 17.28 15.6981 17.28 16.7247C17.28 17.8446 17.5766 18.3486 18.3553 18.6286L20.302 19.3565C22.2302 20.0845 23.0274 21.2417 23.0274 22.8656C23.0274 25.18 21.5998 26.3373 19.2638 26.3373C16.9277 26.3373 15.6484 24.8067 15.6484 22.175L17.0019 22.1936Z" fill="#0079C0"/>
                <path d="M28.2738 26.0755H26.9204V15.0444H23.75V13.8125H31.4071V15.0444H28.2553L28.2738 26.0755Z" fill="#0079C0"/>
                <path d="M34.2812 26.0755H32.9277V13.8125H36.6358C38.7679 13.8125 40.1769 14.8577 40.1769 17.1536C40.1769 18.5535 39.3797 19.7854 37.9707 19.972V20.0093C39.2499 20.1587 40.0472 21.2786 40.0472 22.7904C40.0472 25.3476 40.2696 25.4409 40.455 25.8142V26.0942H39.0831C38.805 25.2916 38.6937 24.5823 38.6937 23.2571C38.6937 21.6332 37.9892 20.6999 36.4689 20.6999H34.2812V26.0755ZM34.2812 19.4494H36.6358C37.5813 19.4494 38.7679 19.0014 38.7679 17.2096C38.7679 15.4364 37.7667 15.0444 36.5801 15.0444H34.2812V19.4494Z" fill="#0079C0"/>
                <path d="M45.3489 13.8125H47.1473L50.5587 26.0755H49.0754L48.1113 22.5665H44.2735L43.3836 26.0942H41.9004L45.3489 13.8125ZM46.2203 14.8018H46.1832L44.5702 21.3346H47.7776L46.2203 14.8018Z" fill="#0079C0"/>
                <path d="M58.2714 24.8623H63.2402V26.0942H56.918V13.8125H58.2714V24.8623Z" fill="#0079C0"/>
                <path d="M66.2441 26.0755H64.8906V13.8125H66.2441V26.0755Z" fill="#0079C0"/>
                <path d="M70.9153 13.8125L74.3637 23.8544C74.5121 24.2837 74.6418 24.6383 74.7902 25.1982H74.8458C74.8272 24.7503 74.7902 24.2837 74.7902 23.8357V13.8125H76.1436V26.0755H73.9188L70.5259 16.2576C70.322 15.6604 70.1551 15.0444 70.0624 14.4285H70.0253C70.0439 15.0444 70.0624 15.679 70.0624 16.295V26.0755H68.709V13.8125H70.9153Z" fill="#0079C0"/>
                <path d="M79.9823 13.8125V22.6411C79.9823 23.593 80.1121 25.1422 82.2813 25.1422C84.4505 25.1422 84.5803 23.593 84.5803 22.6411V13.8125H85.9337V23.1264C85.9337 25.3102 84.4876 26.3555 82.2813 26.3555C80.075 26.3555 78.6289 25.3102 78.6289 23.1264V13.8125H79.9823Z" fill="#0079C0"/>
                <path d="M89.2517 13.8125L91.8102 18.5908L94.3502 13.8125H95.852L92.6075 19.7854L95.9447 26.0942H94.4059L91.8102 20.9613L89.1775 26.0942H87.6758L91.0316 19.7854L87.7499 13.8125H89.2517Z" fill="#0079C0"/>
                <path d="M99.4495 6.43859H57.5487C57.1779 6.43859 56.8627 6.73723 56.8627 7.1292C56.8627 7.5025 57.1594 7.81981 57.5487 7.81981H99.4495C102.008 7.81981 104.103 9.91031 104.103 12.5048V26.9516C104.103 29.5274 102.027 31.6366 99.4495 31.6366H71.2684H6.00702C3.44847 31.6366 1.35343 29.5461 1.37197 26.9516V12.4861C1.37197 9.91031 3.44847 7.81981 6.00702 7.80114H47.9449C48.3157 7.80114 48.6309 7.5025 48.6309 7.11053C48.6309 6.73723 48.3342 6.41992 47.9449 6.41992H6.00702C2.68833 6.43859 0 9.14503 0 12.4861V26.9329C0 30.274 2.68833 32.9804 6.00702 32.9804H71.2684H99.4495C102.768 32.9804 105.457 30.274 105.457 26.9329V12.4861C105.457 9.14503 102.768 6.43859 99.4495 6.43859Z" fill="#0079C0"/>
                <path d="M58.0113 4.23699L54.433 3.62104L53.0796 0.727941L52.7273 0L51.0216 3.60238L47.4434 4.21833L50.0019 7.0181L49.4272 10.9751L52.7273 9.14593L56.0275 10.9751L55.4342 7.0181L57.5107 4.74095L58.0113 4.23699ZM54.9892 6.85011L55.4713 10.1538L52.7088 8.64197L49.9648 10.1538L50.4469 6.85011L48.3147 4.51697L51.2997 3.99434L52.7088 1.00792L54.1178 3.99434L57.1028 4.51697L54.9892 6.85011Z" fill="#0079C0"/>
                <path d="M50.7067 7.44765L51.7078 8.8102L52.1343 8.56756L50.4842 5.13317L52.6534 8.28758L52.7275 8.25025L53.4877 8.66088L51.8191 4.96518L54.6557 9.31416L55.0451 9.51948L54.9894 9.10884L53.154 4.79719L54.7299 7.31699L54.6372 6.73837L55.1192 6.21574L54.4889 4.61054L55.3046 6.01043L56.4727 4.72253L53.9141 4.29323L52.7275 1.77344L51.541 4.29323L48.9824 4.72253L50.8179 6.73837L50.7067 7.44765Z" fill="#0079C0"/>
                <path d="M50.6141 8.17578L50.4102 9.51967L51.0961 9.14637L50.6141 8.17578Z" fill="#0079C0"/>
                <path d="M99.8764 15.3611C99.8764 16.2383 99.1719 16.9663 98.282 16.9663C97.4106 16.9663 96.6875 16.257 96.6875 15.3611C96.6875 14.4838 97.392 13.7559 98.282 13.7559C98.282 13.7559 98.282 13.7559 98.3005 13.7559C99.1533 13.7745 99.8764 14.4838 99.8764 15.3611ZM96.9285 15.3611C96.9285 16.1077 97.5403 16.7236 98.282 16.7236C99.0236 16.7236 99.6354 16.1077 99.6354 15.3611C99.6354 14.6145 99.0236 13.9985 98.282 13.9985C97.5403 13.9985 96.9285 14.6145 96.9285 15.3611ZM97.6887 14.4651H98.4674C98.764 14.4651 99.0977 14.5585 99.0977 14.9504C99.0977 15.3424 98.8196 15.4357 98.5415 15.4357L99.0792 16.257H98.8196L98.319 15.4357H97.9297V16.257H97.7072L97.6887 14.4651ZM97.9111 15.2677H98.4303C98.5971 15.2677 98.8567 15.2304 98.8567 14.9318C98.8567 14.6705 98.6157 14.6331 98.3932 14.6331H97.8926V15.2677H97.9111Z" fill="#0079C0"/>
                </g>
                <defs>
                <clipPath id="clip0_542_1227">
                <rect width="105.457" height="33" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            </div> 
          </div>
          <div class="manuals_base-container-tls-row-step">
            <div class="selection_box long">
              <div class="selection_box long-left">
              <div class="selection_box-container steps">
                <div class="selection_box-container-title">
                  <article class="title-step"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-terminal-2" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M8 9l3 3l-3 3" />
                    <path d="M13 15l3 0" />
                    <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                  </svg><h1> шаг 1</h1></article>
                  <article>
                  <h2>Скачайте КриптоПро CSP</h2>
                  <p>Нажмите на кнопку скачать, далее пролистайте вниз и подтвердите пользовательское соглашение. В зависимости от Вашей ОС выберите нужный установщик, нажав на название ОС, из списка Сертифицированных версий. После нажатия на «Unix» Вам будут предложены варианты пакетов, выберите тот, который продемонстрирован справа.</p>
                  </article>
                </div>
              </div>
            </div>
            <div class="selection_box long-right">
              <div class="selection_box-container steps center-img">
                <img class="img_crypto" src=/crypto_linux.png />
              </div>
            </div>
        </div>
            <div class="selection_box long">
              <div class="selection_box long-left">
              <div class="selection_box-container steps">
                <div class="selection_box-container-title">
                  <article class="title-step"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-terminal-2" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M8 9l3 3l-3 3" />
                    <path d="M13 15l3 0" />
                    <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                  </svg><h1> шаг 2</h1></article>
                  <article>
                  <h2>Установка</h2>
                  <p>Откройте терминал командной строки с помощью горячих клавиш Alt + T и введите команды по очереди, указанные справа.</p>
                  </article>
                </div>
              </div>
            </div>
            <div class="selection_box long-right">
              <div class="selection_box-container steps">
                <div class="selection_box-container-title">
                  <ul>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-terminal-2" width="17" height="17" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M8 9l3 3l-3 3" />
                        <path d="M13 15l3 0" />
                        <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                      </svg> cd ~/Загрузки
                    </li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-terminal-2" width="17" height="17" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M8 9l3 3l-3 3" />
                      <path d="M13 15l3 0" />
                      <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                    </svg>
                    tar –zxf linux-amd64_deb
                    </li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-terminal-2" width="17" height="17" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M8 9l3 3l-3 3" />
                      <path d="M13 15l3 0" />
                      <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                    </svg>
                    cd linux-amd64_deb
                    </li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-terminal-2" width="17" height="17" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M8 9l3 3l-3 3" />
                      <path d="M13 15l3 0" />
                      <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                    </svg>
                    sudo ./install_gui_sh
                    </li>
                  </ul>
                  </article>
                </div>
              </div>
            </div>
        </div>
    
        <div class="selection_box long-hv">
          <div class="selection_box hv-500 long-left">
          <div class="selection_box-container steps">
            <div class="selection_box-container-title">
              <article class="title-step"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-terminal-2" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M8 9l3 3l-3 3" />
                <path d="M13 15l3 0" />
                <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
              </svg><h1> шаг 3</h1></article>
              <article>
              <h2>Выбор компонентов</h2>
              <p>Выберите компоненты, указанные на рисунке справа (Выбирайте компоненты, используя «Пробел»)<br>
                После установки введите команду и перезагрузите компьютер.</p>
              </article>
            </div>
          </div>
        </div>
        <div class="selection_box-container steps center-img">
          <img class="img_crypto-hv" src="/astra_components.png" />
        </div>
    </div>
        </div>
        <div class="manuals_base-container-tls-row-select buttons">
            <button class="manual_button denial" data-content="crypto-trouble-select-os" type="button">Назад</button>
            <button class="manual_button beginning" data-content="crypto_pro" type="button">Далее</button>
          </div>
        </div>`;
      } else if (dataContent == "windows-browser") {
        newContent.classList.add("overflow");
        newContent.innerHTML = `
      <div class="manuals_base-container-tls-column">
        <div class="manuals_base-container-tls-title">
          <div class="manuals_base-container-tls-title-row">
          </div> 
        </div>
        <div class="manuals_base-container-tls-row center">
        <div class="manuals_base-container-tls-left">
          <h2>шаг 1</h2>
          <h1>Скачайте с официального сайта установщик Яндекс браузера</h1>
          <p class="line_left">Двойным щелчком левой кнопки мыши нажмите на скачанный файл и проследуйте шагам установщика.</p>
          <a href="https://browser.yandex.ru/old/?banerid=6400000000&lang=ru" id="buttonStart" data-content="browser" class="manual_button yan" type="button">Скачать</a>
        </div>
        <div class="manuals_base-container-tls-right">         
          <svg width="250" height="250" viewBox="0 0 340 340" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M340 170C340 263.888 263.888 340 170 340C76.1117 340 0 263.888 0 170C0 76.1117 76.1117 0 170 0C263.888 0 340 76.1117 340 170Z" fill="url(#paint0_linear_553_1299)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M331.765 170.002C331.765 259.342 259.34 331.768 170 331.768C80.6591 331.768 8.23438 259.342 8.23438 170.002C8.23438 80.6612 80.6591 8.23633 170 8.23633C259.34 8.23633 331.765 80.6612 331.765 170.002Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M279.436 99.8759L188.46 188.86V299.493C188.46 301.485 179.827 303.079 169.999 303.212C160.569 303.079 151.538 301.485 151.538 299.493V188.86L60.5615 99.8759C59.1005 98.415 63.7489 91.5087 70.9208 84.3369C78.0927 77.165 85.1318 72.5165 86.4599 73.9775L169.866 155.392L253.272 73.9775C254.733 72.5165 261.64 77.165 268.811 84.3369C275.983 91.5087 280.765 98.415 279.436 99.8759Z" fill="#EC1C24"/>
            <defs>
            <linearGradient id="paint0_linear_553_1299" x1="170" y1="1.60026e-08" x2="170" y2="340" gradientUnits="userSpaceOnUse">
            <stop offset="1e-07" stop-color="#F5F5F6"/>
            <stop offset="1" stop-color="#EDEDEE"/>
            </linearGradient>
            </defs>
            </svg>
        </div>
      </div>
      <svg class="ya-brow" width="16" height="287" viewBox="0 0 16 287" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 15V279" stroke="#EC1C24" stroke-width="2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 12.4183 3.5817 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 1.93129e-07 8 0C3.5817 -1.93129e-07 0 3.58172 0 8ZM2 8C2 11.3137 4.6863 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.6863 2 2 4.68629 2 8ZM8 12C5.7909 12 4 10.2091 4 8C4 5.79086 5.7909 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12Z" fill="#EC1C24"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 287L2 279H14L8 287Z" fill="#EC1C24"/>
        </svg>
      </div>
      
      <div class="manuals_base-container-tls-column mg-55">
        <div class="manuals_base-container-tls-row center">
        <div class="manuals_base-container-tls-left">
          <h2>шаг 2</h2>
          <h1>Настройте Яндекс браузер</h1>
          <p class="line_left">После установки нажмите на иконку <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="25" rx="3" fill="#2C3B43"/>
            <line x1="3" y1="10.5" x2="9" y2="10.5" stroke="white"/>
            <line x1="3" y1="14.5" x2="10" y2="14.5" stroke="white"/>
            <line x1="3" y1="18.5" x2="15" y2="18.5" stroke="white"/>
            <circle cx="16.5" cy="11.5" r="5.5" fill="#0078D7"/>
            </svg>
              , находящуюся в верхнем правом углу и перейдите в настройки. В поиске по настройкам напишите «ГОСТ»,
              у Вас должны быть включены эти параметры, как указано на картинке справа.</p>
            
            </div>
            
        <div class="manuals_base-container-tls-right">         
          <img class="img_def" src="/gost_ya.png" />
        </div>
      </div>
      </div>
      <div class="manuals_base-container-tls-row-select buttons">
          <button class="manual_button denial" data-content="browser-trouble-select-os" type="button">Назад</button>
          <button class="manual_button beginning" data-content="crypto_pro" type="button">Далее</button>
      </div>`;
    } else if (dataContent == "crypto-trouble-select-os") {
      newContent.classList.add("overflow");
      newContent.innerHTML = `
    <div class="manuals_base-container-tls-column selector">
    <div class="manuals_base-container-tls-title">
      <h1>Какая у Вас операционная система?</h1>
      <p>Пожалуйста, выберите операционную систему, откуда осуществляется вход на ресурс.</p>
    </div>
    <div class="manuals_base-container-tls-row-select">
      <button data-content="windows-crypto" class="selection_box">
        <div class="selection_box-container"><svg width="120" height="120" viewBox="0 0 255 253" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="121" height="120" fill="#0078D4"/>
          <rect y="133" width="121" height="120" fill="#0078D4"/>
          <rect x="133" y="132" width="121" height="120" fill="#0078D4"/>
          <rect x="134" width="121" height="120" fill="#0078D4"/>
          </svg>
          <h1>Windows</h1></div>
      </button>
      <button data-content="astra-crypto" class="selection_box>
        <div class="selection_box-container"><svg width="245" height="77" viewBox="0 0 245 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_542_1227)">
        <path d="M21.7948 31.875H25.9729L33.8984 60.174H30.4525L28.1696 52.0763H19.2966L17.2291 60.174H13.7832L21.7948 31.875ZM23.8623 34.1579H23.7762L20.0288 49.2335H27.4805L23.8623 34.1579Z" fill="#0079C0"/>
        <path d="M39.4979 51.2142C39.4979 55.3923 40.6608 58.0198 44.8389 58.0198C48.0263 58.0198 50.2231 56.6415 50.2231 53.2818C50.2231 49.6205 48.5001 48.716 45.6142 47.6392L41.4361 46.0885C38.8518 45.0979 36.8704 43.1596 36.8704 38.6369C36.8704 34.1142 39.9717 31.3145 44.5805 31.3145C49.4908 31.3145 52.4198 34.1142 52.6352 39.283H49.5339C49.5339 36.1817 47.9833 34.1142 44.8389 34.1142C41.35 34.1142 40.1439 36.2248 40.1439 38.5938C40.1439 41.1782 40.8331 42.3412 42.6422 42.9873L47.1649 44.6671C51.6445 46.347 53.4966 49.0175 53.4966 52.7649C53.4966 58.1059 50.18 60.7765 44.7528 60.7765C39.3256 60.7765 36.3535 57.2445 36.3535 51.1712L39.4979 51.2142Z" fill="#0079C0"/>
        <path d="M65.6856 60.174H62.5413V34.7178H55.1758V31.875H72.965V34.7178H65.6425L65.6856 60.174Z" fill="#0079C0"/>
        <path d="M79.6424 60.174H76.498V31.875H85.1127C90.0661 31.875 93.3396 34.2871 93.3396 39.5851C93.3396 42.8156 91.4875 45.6584 88.2139 46.0891V46.1753C91.186 46.5199 93.0381 49.1043 93.0381 52.5932C93.0381 58.4942 93.555 58.7096 93.9857 59.571V60.2171H90.7983C90.1522 58.365 89.8938 56.7282 89.8938 53.67C89.8938 49.9226 88.257 47.769 84.725 47.769H79.6424V60.174ZM79.6424 44.8831H85.1127C87.3094 44.8831 90.0661 43.8493 90.0661 39.7143C90.0661 35.6224 87.7401 34.7178 84.9835 34.7178H79.6424V44.8831Z" fill="#0079C0"/>
        <path d="M105.357 31.875H109.535L117.461 60.174H114.015L111.775 52.0763H102.859L100.792 60.2171H97.3457L105.357 31.875ZM107.382 34.1579H107.296L103.548 49.2335H111L107.382 34.1579Z" fill="#0079C0"/>
        <path d="M135.379 57.3743H146.922V60.2171H132.234V31.875H135.379V57.3743Z" fill="#0079C0"/>
        <path d="M153.9 60.174H150.756V31.875H153.9V60.174Z" fill="#0079C0"/>
        <path d="M164.755 31.875L172.766 55.0483C173.111 56.039 173.412 56.8574 173.757 58.1496H173.886C173.843 57.1159 173.757 56.039 173.757 55.0053V31.875H176.901V60.174H171.732L163.85 37.5176C163.376 36.1392 162.989 34.7178 162.773 33.2964H162.687C162.73 34.7178 162.773 36.1823 162.773 37.6037V60.174H159.629V31.875H164.755Z" fill="#0079C0"/>
        <path d="M185.818 31.875V52.2486C185.818 54.4453 186.12 58.0204 191.159 58.0204C196.199 58.0204 196.5 54.4453 196.5 52.2486V31.875H199.645V53.3685C199.645 58.4081 196.285 60.8201 191.159 60.8201C186.034 60.8201 182.674 58.4081 182.674 53.3685V31.875H185.818Z" fill="#0079C0"/>
        <path d="M207.355 31.875L213.299 42.9017L219.2 31.875H222.689L215.151 45.6584L222.904 60.2171H219.329L213.299 48.372L207.182 60.2171H203.693L211.49 45.6584L203.866 31.875H207.355Z" fill="#0079C0"/>
        <path d="M231.044 14.8595H133.699C132.838 14.8595 132.105 15.5486 132.105 16.4532C132.105 17.3146 132.794 18.0469 133.699 18.0469H231.044C236.988 18.0469 241.856 22.8711 241.856 28.8582V62.1969C241.856 68.1409 237.031 73.0082 231.044 73.0082H165.573H13.9557C8.0116 73.0082 3.14434 68.184 3.18741 62.1969V28.8152C3.18741 22.8711 8.0116 18.0469 13.9557 18.0038H111.387C112.249 18.0038 112.981 17.3146 112.981 16.4101C112.981 15.5486 112.292 14.8164 111.387 14.8164H13.9557C6.24561 14.8595 0 21.1051 0 28.8152V62.1538C0 69.8639 6.24561 76.1095 13.9557 76.1095H165.573H231.044C238.754 76.1095 245 69.8639 245 62.1538V28.8152C245 21.1051 238.754 14.8595 231.044 14.8595Z" fill="#0079C0"/>
        <path d="M134.776 9.7776L126.463 8.35619L123.319 1.67985L122.5 0L118.538 8.31311L110.225 9.73453L116.169 16.1955L114.833 25.327L122.5 21.1058L130.167 25.327L128.789 16.1955L133.613 10.9406L134.776 9.7776ZM127.755 15.8078L128.875 23.4318L122.457 19.9429L116.083 23.4318L117.202 15.8078L112.249 10.4237L119.184 9.21765L122.457 2.32595L125.731 9.21765L132.666 10.4237L127.755 15.8078Z" fill="#0079C0"/>
        <path d="M117.805 17.186L120.131 20.3304L121.121 19.7704L117.288 11.845L122.327 19.1243L122.5 19.0382L124.266 19.9858L120.389 11.4573L126.979 21.4933L127.884 21.9671L127.755 21.0195L123.49 11.0696L127.152 16.8845L126.936 15.5493L128.056 14.3432L126.592 10.6389L128.487 13.8694L131.2 10.8974L125.256 9.90667L122.5 4.0918L119.743 9.90667L113.799 10.8974L118.063 15.5493L117.805 17.186Z" fill="#0079C0"/>
        <path d="M117.589 18.8652L117.115 21.9665L118.709 21.105L117.589 18.8652Z" fill="#0079C0"/>
        <path d="M232.036 35.4484C232.036 37.4729 230.399 39.1527 228.331 39.1527C226.307 39.1527 224.627 37.5159 224.627 35.4484C224.627 33.424 226.264 31.7441 228.331 31.7441C228.331 31.7441 228.331 31.7441 228.374 31.7441C230.356 31.7872 232.036 33.424 232.036 35.4484ZM225.187 35.4484C225.187 37.1714 226.608 38.5928 228.331 38.5928C230.054 38.5928 231.476 37.1714 231.476 35.4484C231.476 33.7255 230.054 32.3041 228.331 32.3041C226.608 32.3041 225.187 33.7255 225.187 35.4484ZM226.953 33.3809H228.762C229.451 33.3809 230.226 33.5963 230.226 34.5008C230.226 35.4054 229.58 35.6207 228.934 35.6207L230.183 37.5159H229.58L228.417 35.6207H227.513V37.5159H226.996L226.953 33.3809ZM227.47 35.2331H228.676C229.063 35.2331 229.667 35.1469 229.667 34.4577C229.667 33.8547 229.107 33.7686 228.59 33.7686H227.427V35.2331H227.47Z" fill="#0079C0"/>
        </g>
        <defs>
        <clipPath id="clip0_542_1227">
        <rect width="245" height="76.1533" fill="white"/>
        </clipPath>
        </defs>
        </svg>
          <h1>Astra Linux</h1></div>
      </div>
  </button>
  <div class="manuals_base-container-tls-row-select buttons">
  <button class="manual_button beginning" data-content="congratulation" type="button">Пропустить</button>
  </div>
  </div>`;
} else if (dataContent == "congratulation") {
  newContent.classList.add("overflow");
  newContent.innerHTML = `
<div class="manuals_base-container-tls-column">
<div class="manuals_base-container-tls-row center-column">
<div class="manuals_base-container-tls-right">         
<svg width="341" height="404" viewBox="0 0 141 104" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="71.5" cy="54.5" r="32.5" fill="#DEF0FE"/>
  <circle cx="71.5" cy="54.5" r="32.5" fill="#DEF0FE"/>
  <circle cx="71.5" cy="54.5" r="22.5" fill="#B5E1FC"/>
  <circle cx="71.5" cy="54.5" r="14.5" fill="#0F7AE2"/>
  <g clip-path="url(#clip0_0_1)">
  <path d="M64.791 54.5007L69.5827 59.2923L79.166 49.709" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <path d="M106.502 18.1457L104.957 19L105.252 17.1906L104 15.9094L105.727 15.6461L106.5 14L107.273 15.6461L109 15.9094L107.748 17.1906L108.043 19L106.502 18.1457Z" fill="#1695F1"/>
  <path d="M118.502 36.1457L116.957 37L117.252 35.1906L116 33.9094L117.727 33.6461L118.5 32L119.273 33.6461L121 33.9094L119.748 35.1906L120.043 37L118.502 36.1457Z" fill="#1695F1"/>
  <path d="M111.502 51.1457L109.957 52L110.252 50.1906L109 48.9094L110.727 48.6461L111.5 47L112.273 48.6461L114 48.9094L112.748 50.1906L113.043 52L111.502 51.1457Z" fill="#1695F1"/>
  <path d="M54.5018 13.1457L52.9566 14L53.2518 12.1906L52 10.9094L53.7274 10.6461L54.5 9L55.2726 10.6461L57 10.9094L55.7482 12.1906L56.0434 14L54.5018 13.1457Z" fill="#1695F1"/>
  <path d="M91.5018 4.14574L89.9566 5L90.2518 3.19065L89 1.90939L90.7274 1.64613L91.5 0L92.2726 1.64613L94 1.90939L92.7482 3.19065L93.0434 5L91.5018 4.14574Z" fill="#1695F1"/>
  <path d="M77.5018 15.1457L75.9566 16L76.2518 14.1906L75 12.9094L76.7274 12.6461L77.5 11L78.2726 12.6461L80 12.9094L78.7482 14.1906L79.0434 16L77.5018 15.1457Z" fill="#8380EB"/>
  <path d="M34.5018 24.1457L32.9566 25L33.2518 23.1906L32 21.9094L33.7274 21.6461L34.5 20L35.2726 21.6461L37 21.9094L35.7482 23.1906L36.0434 25L34.5018 24.1457Z" fill="#FCC93C"/>
  <path d="M22.5018 56.1457L20.9566 57L21.2518 55.1906L20 53.9094L21.7274 53.6461L22.5 52L23.2726 53.6461L25 53.9094L23.7482 55.1906L24.0434 57L22.5018 56.1457Z" fill="#8380EB"/>
  <path d="M27.5018 76.1457L25.9566 77L26.2518 75.1906L25 73.9094L26.7274 73.6461L27.5 72L28.2726 73.6461L30 73.9094L28.7482 75.1906L29.0434 77L27.5018 76.1457Z" fill="#1695F1"/>
  <path d="M46.5018 96.1457L44.9566 97L45.2518 95.1906L44 93.9094L45.7274 93.6461L46.5 92L47.2726 93.6461L49 93.9094L47.7482 95.1906L48.0434 97L46.5018 96.1457Z" fill="#FCC93C"/>
  <path d="M79.5018 103.146L77.9566 104L78.2518 102.191L77 100.909L78.7274 100.646L79.5 99L80.2726 100.646L82 100.909L80.7482 102.191L81.0434 104L79.5018 103.146Z" fill="#1695F1"/>
  <path d="M101.502 91.1457L99.9566 92L100.252 90.1906L99 88.9094L100.727 88.6461L101.5 87L102.273 88.6461L104 88.9094L102.748 90.1906L103.043 92L101.502 91.1457Z" fill="#8380EB"/>
  <path d="M127.502 81.1457L125.957 82L126.252 80.1906L125 78.9094L126.727 78.6461L127.5 77L128.273 78.6461L130 78.9094L128.748 80.1906L129.043 82L127.502 81.1457Z" fill="#FCC93C"/>
  <path d="M2.50175 68.1457L0.956589 69L1.25175 67.1906L0 65.9094L1.72742 65.6461L2.5 64L3.27258 65.6461L5 65.9094L3.74825 67.1906L4.04341 69L2.50175 68.1457Z" fill="#1695F1"/>
  <path d="M5.50175 44.1457L3.95659 45L4.25175 43.1906L3 41.9094L4.72742 41.6461L5.5 40L6.27258 41.6461L8 41.9094L6.74825 43.1906L7.04341 45L5.50175 44.1457Z" fill="#FCC93C"/>
  <path d="M22.5018 36.1457L20.9566 37L21.2518 35.1906L20 33.9094L21.7274 33.6461L22.5 32L23.2726 33.6461L25 33.9094L23.7482 35.1906L24.0434 37L22.5018 36.1457Z" fill="#1695F1"/>
  <path d="M31.5018 7.14574L29.9566 8L30.2518 6.19065L29 4.90939L30.7274 4.64613L31.5 3L32.2726 4.64613L34 4.90939L32.7482 6.19065L33.0434 8L31.5018 7.14574Z" fill="#1695F1"/>
  <path d="M138.502 56.1457L136.957 57L137.252 55.1906L136 53.9094L137.727 53.6461L138.5 52L139.273 53.6461L141 53.9094L139.748 55.1906L140.043 57L138.502 56.1457Z" fill="#FCC93C"/>
  <path d="M91.5018 4.14574L89.9566 5L90.2518 3.19065L89 1.90939L90.7274 1.64613L91.5 0L92.2726 1.64613L94 1.90939L92.7482 3.19065L93.0434 5L91.5018 4.14574Z" fill="#FCC93C"/>
  <defs>
  <clipPath id="clip0_0_1">
  <rect width="23" height="23" fill="white" transform="translate(60 43)"/>
  </clipPath>
  </defs>
  </svg>
</div>
<div class="manuals_base-container-tls-title">
<div class="manuals_base-container-tls-title-row-column">
  <h1>Настройка звершена</h1>
  <p>Вы выполнили все необходимые мероприятия для входа на сайт ЕР ЦДО с использованием протокола TLS ГОСТ.</p>
</div> 
</div>
</div>
</div>

<div class="manuals_base-container-tls-row-select buttons">
<button class="manual_button beginning" data-content="begin-page" type="button">В начало</button>
<a href="https://ercdo.obrnadzor.gov.ru/" class="manual_button beginning ercdo" type="button">ЕР ЦДО</a>
</div>`;
} else if (dataContent == "windows-crypto") {
  newContent.classList.add("overflow");
  newContent.innerHTML = `
  <div class="manuals_base-container-tls-column">
  <div class="manuals_base-container-tls-title">
    <div class="manuals_base-container-tls-title-row">
    </div> 
  </div>
  <div class="manuals_base-container-tls-row center">
  <div class="manuals_base-container-tls-left">
    <h2>шаг 1</h2>
    <h1>Скачайте с официального сайта установщик КриптоПро</h1>
    <p class="line_left-crypto">Для того, чтобы скачать файлы, Вам необходимо зарегистрироваться. После установки у Вас будет 3 месяца до окончания пробного периода лицензии, позже вам потребуется ее приобрести.</p>
    <a href="https://www.cryptopro.ru/products/csp?csp=download" id="buttonStart" class="manual_button crypto" type="button">Скачать</a>
  </div>
  <div class="manuals_base-container-tls-right">         
    <img class="img_crypto" src="/download_crypto.png" />
  </div>
</div>
<svg class="crypto-win" width="16" height="287" viewBox="0 0 16 287" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 15V279" stroke="#0055A2" stroke-width="2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 12.4183 3.5817 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 1.93129e-07 8 0C3.5817 -1.93129e-07 0 3.58172 0 8ZM2 8C2 11.3137 4.6863 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.6863 2 2 4.68629 2 8ZM8 12C5.7909 12 4 10.2091 4 8C4 5.79086 5.7909 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12Z" fill="#0055A2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 287L2 279H14L8 287Z" fill="#0055A2"/>
  </svg>
</div>

<div class="manuals_base-container-tls-column mg-25">
  <div class="manuals_base-container-tls-row center">
  <div class="manuals_base-container-tls-left">
    <h2>шаг 2</h2>
    <h1>Установка КриптоПро CSP</h1>
    <p class="line_left-crypto">После загрузки файла 
      нажмите на него двойным щелчком левой кнопки мыши и проследуйте 
      шагам установщика, как показано слева. После установки потребуется перезагрузить компьютер.</p>
      </div>
      
  <div class="manuals_base-container-tls-right">         
    <img class=img_crypto" src="/crypto_install.png" />
  </div>
</div>
</div>
<div class="manuals_base-container-tls-row-select buttons">
    <button class="manual_button denial" data-content="crypto-trouble-select-os" type="button">Назад</button>
    <button class="manual_button beginning" data-content="unable-connection" type="button">Далее</button>
</div>`;
} else if (dataContent == "unable-connection") {
  newContent.innerHTML = `     
    <div class="manuals_base-container-tls-left">
      <h2 class="warning_information"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-triangle" width="19" height="19" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffbc11" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z" />
      <path d="M12 9v4" />
      <path d="M12 17h.01" />
    </svg>Возможные трудности</h2>
      <h1>Невозможно установить безопасное соединение</h1>
      <p>В данном случае это значит, что у Вас не установлен сертификат от 
        Минцифры. 
        Чтобы избавиться от этой ошибки, Вам необходимо скачать и установить корневой сертификат.
          Инструкцию по их установке Вы можете найти на сайте <a href="https://www.gosuslugi.ru/crt" class="link_am">госуслуг</a>.</p>
      <div class="manuals_base-container-tls-row">
      <button id="buttonStart" data-content="congratulation" class="manual_button beginning" type="button">Пропустить</button>
      <a href="http://reestr-pki.ru/cdp/guc2022.crt"  class="manual_button download-cert" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-certificate" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.0" stroke="#1884ff" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
          <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
          <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
          <path d="M6 9l12 0" />
          <path d="M6 12l3 0" />
          <path d="M6 15l2 0" />
        </svg>
        Скачать корневой сертификат</a>
    </div>
    </div>
    <div class="manuals_base-container-tls-right">         
    <div class="selection_box long-right">
    <div class="selection_box-container steps">
    <img class="img_astra_ya" src="/gost_browser_error.png" />
    </div>
  </div>
    </div>`;
  } else if (dataContent == "browser-trouble-select-os") {
    newContent.innerHTML = `
  <div class="manuals_base-container-tls-column selector">
  <div class="manuals_base-container-tls-title">
    <h1>Какая у Вас операционная система?</h1>
    <p>Пожалуйста, выберите операционную систему, откуда осуществляется вход на ресурс.</p>
  </div>
  <div class="manuals_base-container-tls-row-select">
    <button data-content="windows-browser" class="selection_box">
      <div class="selection_box-container"><svg width="120" height="120" viewBox="0 0 255 253" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="121" height="120" fill="#0078D4"/>
        <rect y="133" width="121" height="120" fill="#0078D4"/>
        <rect x="133" y="132" width="121" height="120" fill="#0078D4"/>
        <rect x="134" width="121" height="120" fill="#0078D4"/>
        </svg>
        <h1>Windows</h1></div>
    </button>
    <button data-content="astra-browser" class="selection_box">
      <div class="selection_box-container"><svg width="245" height="77" viewBox="0 0 245 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_542_1227)">
      <path d="M21.7948 31.875H25.9729L33.8984 60.174H30.4525L28.1696 52.0763H19.2966L17.2291 60.174H13.7832L21.7948 31.875ZM23.8623 34.1579H23.7762L20.0288 49.2335H27.4805L23.8623 34.1579Z" fill="#0079C0"/>
      <path d="M39.4979 51.2142C39.4979 55.3923 40.6608 58.0198 44.8389 58.0198C48.0263 58.0198 50.2231 56.6415 50.2231 53.2818C50.2231 49.6205 48.5001 48.716 45.6142 47.6392L41.4361 46.0885C38.8518 45.0979 36.8704 43.1596 36.8704 38.6369C36.8704 34.1142 39.9717 31.3145 44.5805 31.3145C49.4908 31.3145 52.4198 34.1142 52.6352 39.283H49.5339C49.5339 36.1817 47.9833 34.1142 44.8389 34.1142C41.35 34.1142 40.1439 36.2248 40.1439 38.5938C40.1439 41.1782 40.8331 42.3412 42.6422 42.9873L47.1649 44.6671C51.6445 46.347 53.4966 49.0175 53.4966 52.7649C53.4966 58.1059 50.18 60.7765 44.7528 60.7765C39.3256 60.7765 36.3535 57.2445 36.3535 51.1712L39.4979 51.2142Z" fill="#0079C0"/>
      <path d="M65.6856 60.174H62.5413V34.7178H55.1758V31.875H72.965V34.7178H65.6425L65.6856 60.174Z" fill="#0079C0"/>
      <path d="M79.6424 60.174H76.498V31.875H85.1127C90.0661 31.875 93.3396 34.2871 93.3396 39.5851C93.3396 42.8156 91.4875 45.6584 88.2139 46.0891V46.1753C91.186 46.5199 93.0381 49.1043 93.0381 52.5932C93.0381 58.4942 93.555 58.7096 93.9857 59.571V60.2171H90.7983C90.1522 58.365 89.8938 56.7282 89.8938 53.67C89.8938 49.9226 88.257 47.769 84.725 47.769H79.6424V60.174ZM79.6424 44.8831H85.1127C87.3094 44.8831 90.0661 43.8493 90.0661 39.7143C90.0661 35.6224 87.7401 34.7178 84.9835 34.7178H79.6424V44.8831Z" fill="#0079C0"/>
      <path d="M105.357 31.875H109.535L117.461 60.174H114.015L111.775 52.0763H102.859L100.792 60.2171H97.3457L105.357 31.875ZM107.382 34.1579H107.296L103.548 49.2335H111L107.382 34.1579Z" fill="#0079C0"/>
      <path d="M135.379 57.3743H146.922V60.2171H132.234V31.875H135.379V57.3743Z" fill="#0079C0"/>
      <path d="M153.9 60.174H150.756V31.875H153.9V60.174Z" fill="#0079C0"/>
      <path d="M164.755 31.875L172.766 55.0483C173.111 56.039 173.412 56.8574 173.757 58.1496H173.886C173.843 57.1159 173.757 56.039 173.757 55.0053V31.875H176.901V60.174H171.732L163.85 37.5176C163.376 36.1392 162.989 34.7178 162.773 33.2964H162.687C162.73 34.7178 162.773 36.1823 162.773 37.6037V60.174H159.629V31.875H164.755Z" fill="#0079C0"/>
      <path d="M185.818 31.875V52.2486C185.818 54.4453 186.12 58.0204 191.159 58.0204C196.199 58.0204 196.5 54.4453 196.5 52.2486V31.875H199.645V53.3685C199.645 58.4081 196.285 60.8201 191.159 60.8201C186.034 60.8201 182.674 58.4081 182.674 53.3685V31.875H185.818Z" fill="#0079C0"/>
      <path d="M207.355 31.875L213.299 42.9017L219.2 31.875H222.689L215.151 45.6584L222.904 60.2171H219.329L213.299 48.372L207.182 60.2171H203.693L211.49 45.6584L203.866 31.875H207.355Z" fill="#0079C0"/>
      <path d="M231.044 14.8595H133.699C132.838 14.8595 132.105 15.5486 132.105 16.4532C132.105 17.3146 132.794 18.0469 133.699 18.0469H231.044C236.988 18.0469 241.856 22.8711 241.856 28.8582V62.1969C241.856 68.1409 237.031 73.0082 231.044 73.0082H165.573H13.9557C8.0116 73.0082 3.14434 68.184 3.18741 62.1969V28.8152C3.18741 22.8711 8.0116 18.0469 13.9557 18.0038H111.387C112.249 18.0038 112.981 17.3146 112.981 16.4101C112.981 15.5486 112.292 14.8164 111.387 14.8164H13.9557C6.24561 14.8595 0 21.1051 0 28.8152V62.1538C0 69.8639 6.24561 76.1095 13.9557 76.1095H165.573H231.044C238.754 76.1095 245 69.8639 245 62.1538V28.8152C245 21.1051 238.754 14.8595 231.044 14.8595Z" fill="#0079C0"/>
      <path d="M134.776 9.7776L126.463 8.35619L123.319 1.67985L122.5 0L118.538 8.31311L110.225 9.73453L116.169 16.1955L114.833 25.327L122.5 21.1058L130.167 25.327L128.789 16.1955L133.613 10.9406L134.776 9.7776ZM127.755 15.8078L128.875 23.4318L122.457 19.9429L116.083 23.4318L117.202 15.8078L112.249 10.4237L119.184 9.21765L122.457 2.32595L125.731 9.21765L132.666 10.4237L127.755 15.8078Z" fill="#0079C0"/>
      <path d="M117.805 17.186L120.131 20.3304L121.121 19.7704L117.288 11.845L122.327 19.1243L122.5 19.0382L124.266 19.9858L120.389 11.4573L126.979 21.4933L127.884 21.9671L127.755 21.0195L123.49 11.0696L127.152 16.8845L126.936 15.5493L128.056 14.3432L126.592 10.6389L128.487 13.8694L131.2 10.8974L125.256 9.90667L122.5 4.0918L119.743 9.90667L113.799 10.8974L118.063 15.5493L117.805 17.186Z" fill="#0079C0"/>
      <path d="M117.589 18.8652L117.115 21.9665L118.709 21.105L117.589 18.8652Z" fill="#0079C0"/>
      <path d="M232.036 35.4484C232.036 37.4729 230.399 39.1527 228.331 39.1527C226.307 39.1527 224.627 37.5159 224.627 35.4484C224.627 33.424 226.264 31.7441 228.331 31.7441C228.331 31.7441 228.331 31.7441 228.374 31.7441C230.356 31.7872 232.036 33.424 232.036 35.4484ZM225.187 35.4484C225.187 37.1714 226.608 38.5928 228.331 38.5928C230.054 38.5928 231.476 37.1714 231.476 35.4484C231.476 33.7255 230.054 32.3041 228.331 32.3041C226.608 32.3041 225.187 33.7255 225.187 35.4484ZM226.953 33.3809H228.762C229.451 33.3809 230.226 33.5963 230.226 34.5008C230.226 35.4054 229.58 35.6207 228.934 35.6207L230.183 37.5159H229.58L228.417 35.6207H227.513V37.5159H226.996L226.953 33.3809ZM227.47 35.2331H228.676C229.063 35.2331 229.667 35.1469 229.667 34.4577C229.667 33.8547 229.107 33.7686 228.59 33.7686H227.427V35.2331H227.47Z" fill="#0079C0"/>
      </g>
      <defs>
      <clipPath id="clip0_542_1227">
      <rect width="245" height="76.1533" fill="white"/>
      </clipPath>
      </defs>
      </svg>
        <h1>Astra Linux</h1></div>
    </div>
</button>
<div class="manuals_base-container-tls-row-select buttons">
<button class="manual_button beginning" data-content="crypto_pro" type="button">Пропустить</button>
</div>
</div>`;
  }
  contentDiv.appendChild(newContent);

  newContent.querySelectorAll("button[data-content]").forEach((button) => {
    button.addEventListener("click", () => {
      const dataContent = button.getAttribute("data-content");
      newContent.remove();
      createContent(dataContent);
    });
  });
}

  const startBtnClickHandler = () => {
    const dataContent = "browser"; 
    createContent(dataContent);
  };

  useEffect(() => {
    const startBtn = document.getElementById("buttonStart");
    if (startBtn) {
      startBtn.addEventListener("click", startBtnClickHandler);
    }
  }, []); 

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <motion.div className="manuals_base">
        <div className="manuals_base-container">
          <div id="contentStart" className="manuals_base-container-tls">
            <div className="manuals_base-container-tls-left">
              <h1>
                Руководство пользователя по подключению к системе ФИС ФРДО{" "}
                <span>(модуль ЕР ЦДО) </span>
                с использованием протокола TLS ГОСТ
              </h1>
              <p>
                Предложенный алгоритм действий предназначен для проверки наличия необходимых
                компонентов на рабочем месте и возможные решения в случае
                отсутствия или некорректной работы перечисленных компонентов.
              </p>
              <button
                id="buttonStart"
                data-content="browser"
                className="manual_button beginning"
                type="button"

              >
                Начать
              </button>
            </div>
            <div className="manuals_base-container-tls-right">
            <svg
width="518"
height="452"
viewBox="0 0 518 492"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  fill-rule="evenodd"
  clip-rule="evenodd"
  d="M215.356 78.7316C238.71 70.0374 254.088 43.0151 278.841 40.6906C303.066 38.4155 326.522 52.074 345.358 66.5232C363.341 80.3187 367.252 105.844 383.814 121.216C400.467 136.673 428.822 138.044 442.116 156.331C455.293 174.455 461.296 199.178 456.529 221.239C451.651 243.814 419.085 255.075 415.852 277.89C411.681 307.314 449.316 336.058 436.412 363.183C425.224 386.7 385.802 378.167 362.294 390.468C339.683 402.301 324.894 426.684 300.516 434.295C275.802 442.011 248.95 438.867 223.855 433.692C198.816 428.529 174.189 419.634 154.377 404.161C134.988 389.016 125.682 365.417 111.237 345.732C96.7355 325.969 67.5789 311.37 68.2789 286.826C69.0589 259.482 111.09 245.349 114.875 218.172C118.653 191.051 82.4064 168.567 88.4801 141.79C93.943 117.705 121.005 102.283 143.723 90.9919C165.615 80.1113 192.432 87.2655 215.356 78.7316Z"
  fill="url(#paint0_linear_536_1205)"
/>
<path d="M3 416H347.5" stroke="black" stroke-width="3" />
<rect
  x="107.5"
  y="400.5"
  width="135"
  height="15"
  fill="#0C9CF4"
  stroke="black"
  stroke-width="3"
/>
<path
  d="M131.5 348.5V400.5H218V348.5H131.5Z"
  fill="#0C9CF4"
  stroke="black"
  stroke-width="3"
/>
<rect
  x="1.5"
  y="76.5"
  width="348"
  height="272"
  rx="22.5"
  stroke="black"
  stroke-width="3"
/>
<rect
  x="10"
  y="85"
  width="332"
  height="254"
  rx="16"
  fill="#E7E7E7"
/>
<mask id="path-7-inside-1_536_1205" fill="white">
  <rect x="19" y="101" width="313" height="206" rx="2" />
</mask>
<rect
  x="19"
  y="101"
  width="313"
  height="206"
  rx="2"
  fill="white"
  stroke="black"
  stroke-width="6"
  mask="url(#path-7-inside-1_536_1205)"
/>
<rect x="22" y="104" width="307" height="200" fill="#D8EAFF" />
<path
  d="M152.5 383V348.5H197V383H152.5Z"
  fill="#3DBBFF"
  stroke="black"
  stroke-width="3"
/>
<rect x="287" y="382" width="23" height="15" fill="#FF9955" />
<rect x="311" y="382" width="23" height="15" fill="#0C9CF4" />
<path
  d="M286 397.5V415.5H335V397.5M286 397.5H310.5M286 397.5V381M335 397.5H310.5M335 397.5V381H321.5H298.5M310.5 397.5V381M310.5 381H298.5M310.5 381H286M298.5 381H286"
  stroke="black"
  stroke-width="3"
/>
<rect
  x="116.564"
  y="157"
  width="119.405"
  height="12.8473"
  fill="#FFC661"
/>
<rect
  x="75"
  y="185.717"
  width="201.779"
  height="9.0687"
  fill="#FFC661"
/>
<rect
  x="75"
  y="201.588"
  width="201.779"
  height="9.0687"
  fill="#FFC661"
/>
<rect
  x="75"
  y="217.459"
  width="201.779"
  height="9.0687"
  fill="#FFC661"
/>
<rect
  x="75"
  y="242"
  width="88.4198"
  height="9.0687"
  fill="#FFC661"
/>
<defs>
  <linearGradient
    id="paint0_linear_536_1205"
    x1="201.165"
    y1="67.0032"
    x2="327.846"
    y2="424.654"
    gradientUnits="userSpaceOnUse"
  >
    <stop stop-color="#2090FF" />
    <stop offset="1" stop-color="#45C7FF" />
  </linearGradient>
</defs>
</svg>
            </div>
          </div>
          <motion.div id="contentSpace">
           
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

export default TlsErcdo;

