import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Внесение сведений в ФИС ФРДО через ЕСИА'

const container = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 10
      }
    }
  };


  const item = {
     hidden: { opacity: 0 },
     visible: {opacity: 1}
  };

const Page_22 = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
                  </Helmet> 
                  <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
            <div className="container-guides-section-faq-isea">
                <div className="container-guides-heading cghn">
                <h1-1>Внесение данных в ФИС ФРДО через ЕСИА</h1-1>
                </div>
                <div className="container-ipnet-man">
                <motion.div
            className="overlay-form-isea">
                <div className="overlay-upper-esia"></div>
                <div className="overlay-form-inner-esia">
                    <p>Для образовательных организаций высшего образования, профессиональных образовательных организаций, организаций среднего профессионального образования, а также 
                        образовательных организаций дополнительного профессионального образования имеется возможность внесения сведений 
                        в ФИС ФРДО через портал: <a href ="https://open-dpo.obrnadzor.gov.ru">https://open-dpo.obrnadzor.gov.ru</a>.</p>
                        <p>Для осуществления доступа к ФИС ФРДО данным методом образовательной организации потребуется наличие:</p>
                            <p-t>• учетной записи на портале государственных услуг Российской Федерации;</p-t>
                            <p-t>• регистрации в Единой системе идентификации и аутентификации (ЕСИА);</p-t>
                            <p-t>• квалифицированного сертификата ключа проверки электронной подписи;</p-t>
                            <p-t>• средства криптографической защиты информации, реализующего функции создания и проверки электронной подписи и шифрования файлов.</p-t>
                      <p>На указанном портале пользователю из выпадающего списка необходимо выбрать подсистему ФИС ФРДО в зависимости от типа образовательной организации.</p>
                      
                          <div className="container_steps_main_p2_esia bc">
                          Внимание! Указанный способ внесения сведений в ФИС ФРДО для организаций, осуществляющих общеобразовательную
                          деятельность (школы), недоступен.<br></br>
                          Внесение общеобразовательными организациями (школами) сведений в ФИС ФРДО возможен только посредством подключения к <a href='/3608/'>защищенной сети передачи<br></br> данных 3608</a>. 
                          </div>
                        <p2>По вопросам внесения сведений в ФИС ФРДО через ресурс: <a href ="https://open-dpo.obrnadzor.gov.ru">https://open-dpo.obrnadzor.gov.ru</a> необходимо обращаться по телефону +7(800)100-03-71 или электронной почте frdo@inevm.ru.</p2>
                </div>
            </motion.div>
            </div>
            </div>
            </motion.div>
        </>
        
        
    ) 
}
export default Page_22;