import React from 'react';
import { AnimatePresence } from "framer-motion";
import {Redirect, BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Navbar from './components/layout/Navbar.jsx';
import Home from './components/pages/Home.jsx';
import Guides from './components/pages/Guides.jsx';
import GuidesFaq from './components/pages/GuidesFaq.jsx';
import GuidesMan from './components/pages/GuidesMan.jsx';
import GuidesDoc from './components/pages/GuidesDoc.jsx';
import GuidesIpres from './components/pages/GuidesIpres.jsx';
import Documents_3189 from './components/containers/Documents_3189.jsx';
import Documents_3608 from './components/containers/Documents_3608.jsx';
import Documents_13833 from './components/containers/Documents_13833.jsx';
import Page_13 from './components/pages/13833.jsx';
import Page_13833_faq from './components/pages/13833_faq.jsx';
import Page_13833_connect from './components/pages/13833_connect.jsx';
import ercdo_contacts from './components/pages/ercdo_contacts.jsx';
import Page_13833_connect_networks from './components/pages/13833_connect_networks.jsx';
import Page_13833_connect_client from './components/pages/13833_connect_client.jsx';
import Page_13833_contacts from './components/pages/13833_contacts.jsx';
import Page_36 from './components/pages/3608.jsx';
import Page_3608_connect from './components/pages/3608_connect.jsx';
import Page_3608_connect_networks from './components/pages/3608_connect_networks.jsx';
import Page_3608_contacts from './components/pages/3608_contacts.jsx';
import Page_3608_faq from './components/pages/3608_faq.jsx';
import Page_31 from './components/pages/3189.jsx';
import Page_3189_connect from './components/pages/3189_connect.jsx';
import Page_3189_contacts from './components/pages/3189_contacts.jsx';
import Page_3189_change_master_keys from './components/pages/3189_change_master_keys.jsx';
import Page_3189_faq from './components/pages/3189_faq.jsx';
import Page_3189_status from './components/pages/3189_status_network';
import NotFoundPage from './components/pages/NotFoundPage.jsx';
import Page_22 from "./components/pages/3608_connection_gos.jsx";
import Partners from './components/pages/partners_infotecs.jsx';
import Licensees from './components/pages/licensees.jsx';
import FAQ_13833 from './components/pages/13833Faq.jsx'
import GuidesOther from './components/pages/GuidesOther.jsx'
import TlsErcdo from './components/pages/GuidesTLSErcdo.jsx';


import './sass/main.scss';
import './App.css'



function App() {
  return (
      <Router>
          <meta name="viewport" content="width=1600"></meta>
          <div className="App">
             <Navbar />
                  <Switch>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/guides/" component={Guides} />
                      <Route exact path="/guides/faq" component={GuidesFaq} />
                      <Route exact path="/guides/man" component={GuidesMan} />
                      <Route exact path="/guides/man/ercdo" component={TlsErcdo} />
                      <Route exact path="/guides/man/change-master-keys" component={Page_3189_change_master_keys} />
                      <Route exact path='/guides/other' component={GuidesOther} />
                      <Route exact path="/guides/doc" component={GuidesDoc} />
                      <Route exact path="/guides/doc/3189" component={Documents_3189} />
                      <Route exact path="/guides/doc/3608" component={Documents_3608} />
                      <Route exact path="/guides/doc/13833" component={Documents_13833} />
                      <Route exact path="/guides/ipres" component={GuidesIpres} />
                      <Route exact path="/ercdo/contacts" component={ercdo_contacts} />
                      <Route exact path="/13833" component={Page_13833_connect_client} />
                      <Route exact path="/13833/news" component={Page_13} />
                      <Route exact path="/13833/connection" component={Page_13833_connect} />
                      <Route exact path="/13833/networks" component={Page_13833_connect_networks} />
                      <Route exact path="/13833/connection/client" component={Page_13833_connect_client} />
                      <Route exact path="/13833/contacts" component={Page_13833_contacts} />
                      <Route exact path="/13833/faq" component={Page_13833_faq} />
                      <Route exact path="/3608" component={Page_3608_connect} />
                      <Route exact path="/3608/news" component={Page_36} />
                      <Route exact path="/3608/connection" component={Page_3608_connect} />
                      <Route exact path="/esia/" component={Page_22} />
                      <Route exact path="/3608/networks" component={Page_3608_connect_networks} />
                      <Route exact path="/3608/faq" component={Page_3608_faq} />
                      <Route exact path="/3608/contacts" component={Page_3608_contacts} />
                      <Route exact path="/3189" component={Page_3189_connect} />
                      <Route exact path="/3189/news" component={Page_31} />
                      <Route exact path="/3189/connection" component={Page_3189_connect} />
                      <Route exact path="/3189/faq" component={Page_3189_faq} />
                      <Route exact path="/3189/contacts" component={Page_3189_contacts} />
                      <Route exact path="/partners/" component={Partners} />
                      <Route exact path="/licensees/" component={Licensees} />
                      <Route exact path="/guides/man/faq/" component={FAQ_13833} />
                      <Route exact path='/3189/ipres' component={Page_3189_status} />
                      <Route path="/404" component={NotFoundPage} />
                      <Redirect to="/404"/>
                  </Switch>
          </div>
      </Router>
  );
}

export default App;
