import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { render } from "@testing-library/react";

const Accordion = ({ items }) => {
    const [active, setActive] = useState(false);

    return (
        items &&
        items.map((item, i) => (
          <AccordionItem
            key={item.id}
            i={i}
            item={item}
            active={active}
            setActive={setActive}
          />
        ))
      );
}

const AccordionItem = ({ i, item, active, setActive }) => {
    // Determines if current item is active
    const isActive = active === i;
    return (
      item && (
        <AccordionContainer isActive={isActive}>
          <AccordionTitle isActive={isActive} setActive={setActive} i={i}>
            {item.title}
          </AccordionTitle>
          <AccordionText isActive={isActive}>{item.text1}</AccordionText>
          <AccordionButton isActive={isActive}>{item.button1}</AccordionButton>
        </AccordionContainer>
      )
    );
  };
  
  const AccordionTitle = ({ children, isActive, setActive, i }) => (
    <motion.div
    className="accordion-title-faq"
      initial={false}
      animate={{
        backgroundColor: isActive ? "#1462ff" : "#EBF5FF",
        boxShadow: isActive ? "0px" : "6px 6px 0px #1462ff",
        color: isActive ? "#fff" : "#333",
        borderTopLeftRadius: isActive ? "5px" : "0px",
        borderTopRightRadius: isActive ? "5px" : "0px"
      }}
      transition={{ duration: 0.1 }}
      onClick={() => setActive(isActive ? false : i)}
    >
      <>
        {children}
        <ChevronIcon isActive={isActive} />
      </>
    </motion.div>
  );
  
  const AccordionText = ({ children, isActive }) => (
    <AnimatePresence initial={false}>
      {isActive && (
        <motion.div
        className="accordion-text-faq"
          key="text"
          initial="closed"
          animate="active"
          exit="closed"
          style={{ overflow: "hidden" }}
          variants={{ active: { height: "auto" }, closed: { height: 0 } }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div
            style={{
              marginLeft: "30px",
              marginTop: "30px",
              marginBottom: "20px"
            }}
          >
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  const AccordionButton = ({ children, isActive }) => (
        <AnimatePresence initial={false}>  
        {isActive && (

            <motion.div
            className="accordion-text-faq"
            key="button"
            initial="closed"
            animate="active"
            exit="closed"
            style={{ overflow: "hidden" }}
            variants={{ active: { height: "auto" }, closed: { height: 0 } }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            >
            <div
                style={{marginLeft: "15px",}}
            >
                <a style={{display: children ? "visble" : "none" }} className="button-download-let xml" href={children}>Подробнее</a>
            </div>
            </motion.div>

        )}
        
        </AnimatePresence>
  );

  
  const AccordionContainer = ({ children, isActive }) => (
    <motion.div
      initial={false}
      style={{ borderRadius: "8px", marginBottom: "60px" }}
      animate={{
        boxShadow: isActive
          ? "0px 3px 5px 0px rgba(200, 200, 200, 1)"
          : "0px 0px 0px 0px rgba(200, 200, 200, 0)"
      }}
      whileHover={{ boxShadow: "0px 3px 5px 0px rgba(200, 200, 200, 1)" }}
      transition={{ duration: 0.2 }}
    >
      {children}
    </motion.div>
  );
  
  const ChevronIcon = ({ isActive }) => (
    <motion.div
      initial={false}
      style={{
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        backgroundColor: "rgba(0,0,0,.1)",
        position: "absolute",
        top: "25px",
        right: "10px"
      }}
      animate={{
        color: isActive ? "#fff" : "#333",
        transform: isActive ? "rotate(-180deg)" : "rotate(0deg)"
      }}
      transition={{ duration: 0.4 }}
    >
      <div style={{ transform: "scale(.4) translateY(-10px)" }}>
        <ChevronSvg />
      </div>
    </motion.div>
  );
  
  const ChevronSvg = () => (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="chevron-up"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
      />
    </svg>
  );
  
  
  export default Accordion;