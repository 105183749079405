import React from 'react';
import {Link, NavLink, Router, BrowserRouter} from 'react-router-dom';
import scheme from '../img/Asset-scheme.svg';
import { Connection_3189 } from '../containers/Connection_3189.jsx';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Подключение ЗСПД 3189'


const Page_3189_connect = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
        </Helmet>
            <div className='container-network Flex-cell Flex--1of1'>
                <div className="info-container">
                          <div className="header">
                <h1>Подключение к ЗСПД 3189</h1>
                <p>Ознакомьтесь с правилами и процедурой подключения к ЗСПД 3189</p>
            </div>
                    <Connection_3189></Connection_3189>
                </div>
            </div>
        </>
    
    )
}

export default Page_3189_connect;