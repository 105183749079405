import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import scheme from '../img/Asset-scheme.svg';
import { Connection_3608 } from '../containers/Connection_3608.jsx';
import { Connection_3608_gos } from '../containers/Connection_3608_gos';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Подключение к ЗСПД 3608'

const Page_3189_connect = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
        </Helmet>
         <div className='container-network Flex-cell Flex--1of1'>
            <div className="info-container">
                       <div className="header">
            <h1>Подключение к ЗСПД 3608</h1>
            <p>Ознакомьтесь с правилами и процедурой подключения к ЗСПД 3608</p>
        </div>
                <Connection_3608_gos></Connection_3608_gos>
                <Connection_3608></Connection_3608>
                
            </div>
        </div>
        </>
    )
}

export default Page_3189_connect;