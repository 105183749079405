import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {Link, NavLink} from 'react-router-dom';
import { motion } from "framer-motion";

export const Connection_3608 = ({ placeholder, handleChange }) => (
    <>
                    <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                             <div className="container_choosen_type">
                        <NavLink to='/3608/' className="nav-link-connect" activeClassName="nav-link-connect-active" >
                            Подключение с использованием СКЗИ ViPNet Client
                        </NavLink>
                        <NavLink to='/3608/networks' className="nav-link-connect" activeClassName="nav-link-connect-active">
                             Подключение с помощью межсетевого взаимодействия
                        </NavLink>
                    </div>
        <div className="info-container">
       <div className="header">
           <h1>Подключение с использованием СКЗИ ViPNet Client</h1>
       </div>
    </div>
    <div className="container_stroke_steps">
    <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">01</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Сбор документов</h2>
       </div>
       <div className="container_steps_main">
         Для подключения к защищенной сети передачи данных № 3608 (далее – сеть 3608) необходимо собрать следующие документы:
        </div>
        <div className="container_steps_main num">
        <div className="container_steps_ico">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-invoice" width="42" height="42" viewBox="0 0 24 24" stroke-width="1" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
  <line x1="9" y1="7" x2="10" y2="7" />
  <line x1="9" y1="13" x2="15" y2="13" />
  <line x1="13" y1="17" x2="15" y2="17" />
</svg> </div><p>Заявление на подключение к сети 3608 юридического лица;  
         <a className="button-download-let sml" href="/01 Форма заявления 3608.docx" download>Скачать</a></p>
        </div>
        <div className="container_steps_main num">
          &#8226; Заверенную копию лицензии на право пользования сети ViPNet № 3608 (ПО ViPNet Client) или Акт приема-передачи программного
обеспечения.
        </div>
        <div className="container_steps_main num">
          &#8226; Копию действующей лицензии на образовательную деятельность
или выписку из реестра лицензий на образовательную деятельность;
        </div>
        <div className="container_steps_main_p bc">
        Внимание! В соответствии с пунктом 2.4 Регламента подключения к ЗСПД 3608 переписка заявителя с органом КЗИ ЦБИ 
        должна осуществляться посредством электронной почты с адреса, указанного в разделе 2 заявления на подключение к ЗСПД 3608 
        с сохранением истории переписки. Информация, направленная с другой электронной почты, рассматриваться не будет.
           </div>
    </div>
    
        <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">02</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Отправка документов на предварительное согласование</h2>
       </div>
       <div className="container_steps_main">
       Скан-копии документов (в формате .pdf), указанных в шаге 1, совместно с действующим квалифицированным сертификатом
             ключа проверки электронной подписи (в формате .cer), выданным на лицо, получающее парольную и справочно-ключевую 
             информацию, необходимо направить на электронную почту 3608vipnet@citis.ru. В теме письма необходимо указать:
              «Согласование подключения», а также краткое наименование органа или организации (дальнейшую переписку нужно вести в 
              рамках первого письма с сохранением истории).
        </div>
    </div>
        <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">03</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Получение ответа</h2>
       </div>
       <div className="container_steps_main">
         После проверки (до 5 рабочих дней) на ваш адрес электронной почты придёт уведомление о положительном результате проверки или перечень обнаруженных замечаний.
        </div>
    </div>
            <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">04</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Отправка пакета документов подписанных электронной подписью</h2>
       </div>
       <div className="container_steps_main">
         После получения уведомления о положительном результате просим на электронную почту 3608vipnet@citis.ru направить пакет документов единым 
         файлом в формате .pdf, подписанного квалифицированной электронной подписью руководителя организации или лица, действующего на основании доверенности (с обязательным приложением файла в формате .sig).
        </div>
        <div className="container_steps_main_p bc">
Примечание - необходимо направить, как .pdf, так и .sig файл.
           </div>
    </div>
            <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">05</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Формирование и отправка парольной и справочно-ключевой информации</h2>
       </div>
       <div className="container_steps_main">
         В случае положительной проверки документов в течение 10 рабочих дней с момента 
         получения и регистрации документов осуществляется формирование и отправка парольной 
         и справочно-ключевой информации, зашифрованной на ключе проверки электронной подписи получателя (входит в состав сертификата получателя). 
         После чего заявителю направляется письмо-уведомление о направлении парольной и справочно-ключевой информации.
Если в результате проверки документов были обнаружены замечания, на адрес электронной почты (с которого вы направляли документы на предварительное согласование), будет направлен перечень обнаруженных замечаний.
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_steps_header">
       <div class="news-date">
            <span class="news-date__title">06</span>
            <span class="news-date__txt">ШАГ</span>
          </div>
           <h2>Получение и установка парольной и справочно-ключевой информации</h2>
       </div>
       <div className="container_steps_main">
         После получения парольной и справочно-ключевой информации в зашифрованном виде (файл с расширением .enc) заявитель:
        </div>
        <div className="container_steps_main num">
          &#8226; Осуществляет расшифрование парольной и справочно-ключевой информации с использованием средства электронной подписи и ключа электронной подписи сертификата получателя;
        </div>
        <div className="container_steps_main num">
          &#8226; Осуществляет проверку содержимого расшифрованного файла на наличие парольной (файл с расширением .xps) и справочно-ключевой информации (файл с расширением .dst);
        </div>
        <div className="container_steps_main num">
          &#8226; Организует настройку собственного узла сети 3608, включая установку справочно-ключевой информации.
        </div>
               <div className="container_steps_main">
         После получения письма-уведомления о передаче парольной и справочно-ключевой информации заявитель:
        </div>
                <div className="container_steps_main num">
         &#8226; Проверяет соответствие информации, указанной в письме;
        </div>
                <div className="container_steps_main num">
          &#8226; Направляет на электронную почту 3608vipnet@citis.ru письмо-уведомление о получении парольной и справочно-ключевой информации в формате .pdf, подписанное квалифицированной электронной подписью руководителя организации или лица, действующего на основании доверенности (с обязательным приложением файла в формате .sig).
                  </div>
                  <a className="button-download-let sml pp" href="/08_Письмо_уведомление_о_получении_дст_3608.docx" download>Скачать</a>
    </div>
    </div>
    </motion.div>
    </>
);