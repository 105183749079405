export default {
    items: [
      {
        id: "id1",
        title: "Вопросы по подключению к ЗСПД 13833 и ответы на них",
        text1: "Если у Вас возникили какие-либо вопросы по подключению к ЗСПД 13833 - воспользуйтесь следующей инструкцией",
        button1: "/Кейс Вопросы ответы по 13833.pdf",

        text2: "Абонентский пункт",
        button2: "/_Абонентский пункт.pdf",

        text3: "Извлечение сертификата ViPNet CSP",
        button3: "/_Извлечение сертификата ViPNet CSP.PDF",

        text4: "Извлечение сертификата КриптоПро CSP",
        button4: "/_Извлечение сертификата КриптоПро CSP.PDF",

        text5: "Первичный импорт",
        button5: "/_Первичный импорт.pdf",

        text6: "Ответный экспорт",
        button6: "/_Ответный экспорт.pdf",

        text7: "Подпись документов",
        button7: "/_Подпись документов.pdf",

        text8: "Установка ключей",
        button8: "/_Установка ключей.pdf",

        text9: "Установка сертификата",
        button9: "/_Установка сертификата.pdf",
      }
    ]
  };
  