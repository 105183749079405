import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import scheme from '../img/Asset-scheme.svg';
import { Connection_13833 } from '../containers/Connection_13833.jsx';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Подключение к ЗСПД 13833'

const Page_13833_connect = () => {
    return (
        <>
        
        <Helmet>
          <title>{ title }</title>
        </Helmet>
         <div className='container-network '>
<div className="sidebar">
                       <div className="side-menu">
                       <span className="nav-title">МЕНЮ</span>
                       <NavLink exact to='/' className="nav-link" activeClassName="nav-link-active">
                           <svg xmlns="http://www.w3.org/2000/svg" className="icon" activeClassName="icon-active" width="21" height="21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="5 12 3 12 12 3 21 12 19 12" />
  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
</svg>
                            Главная
                        </NavLink>
                        <NavLink exact to='/13833/news' className="nav-link" activeClassName="nav-link-active">
                           <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-news" width="21" height="21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
  <line x1="8" y1="8" x2="12" y2="8" />
  <line x1="8" y1="12" x2="12" y2="12" />
  <line x1="8" y1="16" x2="12" y2="16" />
</svg>
                            Новости
                        </NavLink>
                        <NavLink exact to='/13833/' className="nav-link" activeClassName="nav-link-active">
                           <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="21" height="21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M7 7h10v6a3 3 0 0 1 -3 3h-4a3 3 0 0 1 -3 -3v-6" />
  <line x1="9" y1="3" x2="9" y2="7" />
  <line x1="15" y1="3" x2="15" y2="7" />
  <path d="M12 16v2a2 2 0 0 0 2 2h3" />
</svg>
                            Подключение
                        </NavLink>
                        <NavLink exact to="/13833/connection/client" className="nav-link" activeClassName="nav-link-active">
                           <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-info-square" width="21" height="21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <line x1="12" y1="8" x2="12.01" y2="8" />
  <rect x="4" y="4" width="16" height="16" rx="2" />
  <polyline points="11 12 12 12 12 16 13 16" />
</svg>
                            Вопросы и ответы
                        </NavLink>
                        <NavLink exact to="/13833/contacts" className="nav-link" activeClassName="nav-link-active">
                           <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="21" height="21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54"  fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
</svg>
                            Контакты
                        </NavLink>
                    </div>
            </div>
                                
            <div className="info-container">
                       <div className="header">
            <h1>Подключение к ЗСПД 13833</h1>
            <p>Ознакомьтесь с правилами и процедурой подключения к ЗСПД 13833</p>
        </div>
                <Connection_13833></Connection_13833>
            </div>
        </div>
        </>
    )
}

export default Page_13833_connect;