export default {
    items: [
      {
        id: "id1",
        title: "Как загрузить первичный импорт и мастер-ключ?",
        text1:
          "Для загрузки первичного импорта и мастер-ключа воспользуйтесь данной инструкцией",
        button1: "/Инструкция-первичного-импорта.pdf"

      },
      {
        id: "id2",
        title: "Как создать ответный экспорт?",
        text1: "Для формирования ответного экспорта, воспользуйтесь данной инструкцией",
        button1: "/Инструкция ответного экспорта.pdf"
      }
    ]
  };
  