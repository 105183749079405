export default {
  
    items: [
      {
        id: "id1",
        title: "Как извлечь сертификат с помощью VipNet CSP?",
        text1: "Если Вы используете VipNet CSP - Воспользуйтесь следующей инструкцией",
        button1: "/Извлечение_сертификата_ViPNet_CSP.pdf"
      },
      {
        id: "id2",
        title: "Как извлечь сертификат с помощью КриптоПро CSP?",
        text1: "Если Вы используете КриптоПро CSP - Воспользуйтесь следующей инструкцией",
        button1: "/Извлечение_сертификата_КриптоПро_CSP.pdf"
      },
      {
        id: "id3",
        title: "Как установить сертификат?",
        text1: "Для установки сертификата воспользуйтесь данной инструкцией",
        button1: "/Установка_сертификата.pdf"
      }
    ]
  };
  