import React, { useState, useEffect, Component } from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import shield from '../img/shield.svg';
import { HostList } from '../host-list/host-list.component';
import  { Search_3189 } from '../containers/Search_3189.jsx';
import {InnerImg} from '../elements/Status_img.jsx';
import scheme from '../img/Asset-scheme.svg';

const variants = {
    open: {opacity: 1, y: 0},
    closed: { opacity: 0, y: "-2vw" },
}
export default class Status_3189 extends React.Component {
    constructor() {
        super();
        this.see_our_technologies = this.see_our_technologies.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.state = {
          hosts: [],
          isOpen: true,
          myStatus: false,
          setMyStatus: true,
          setIsOpen: true,
          hostError: false,
          hostWrong: false,
          setHostError: '123',
          searchField: '',
          setHost: '33',
        };
      }

    componentDidMount() {
        fetch('http://127.0.0.1:8000/api/')
          .then(response => response.json())
          .then(users => this.setState({ hosts: users }));
      }
      see_our_technologies() {
        this.setState({ show_technologies: true });
      }

    handleShow = () => {
        this.setState({
            isOpen: true
        })
    }

    handleHide() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
            show_technologies: true
        }));
    }


    render() {
        // const hostHandler = (e) => {
        //     this.setState.setHost(e.target.value)
        //     if(e.target.value.length < 5 || e.target.value > 8) {
        //         this.state.setHostError('GYF')
        //         if(!e.target.value) {
        //             this.state.setHostError('GYF')
        //         }
        //     } else {
        //         this.state.setHostError('')
        //     }
        // }
        const { hosts, searchField } = this.state;
        const filteredHosts = hosts.filter(host =>
            host.titleHost.toLowerCase().includes(searchField.toLowerCase()) ||
            host.idHost.toLowerCase().includes(searchField.toLowerCase())
        );
        const status = ["checkStatus"];
        // const [isOpen, setIsOpen] = useState(false)
        // const [myStatus, setMyStatus ] = useState(false);

        return (
            
            <>
        <motion.div
            className="overlay-form"
            animate={this.state.isOpen ? "open" : "closed"}
            variants={variants}>
                <div className="overlay-upper"></div>
                <div className="overlay-form-inner">
                    <h1 className="overlay-form-title">
                        Проверка доступа к ЗСПД 3189
                    </h1>
                    <p className="overlay-form-description">
                    Предложенная ниже форма для заполнения,
                    предоставит информацию о статусе доступности сетевого идентификатора узла ViPNet Client Вашей организации 
                    к защищенной сети передачи данных 3189 после смены мастер-ключей c 19 апреля по 2 мая 2021 года согласно письму 
                    Минобрнауки от 26.03.2021 № МН-3/2229
                    </p>
                    <div className="form-inner">
                        <div class="card-input">
                        <label for="cardNumber" className="card-input-label">Идентификатор узла</label>
                    {(this.state.hostError && this.state.hostWrong) && <div style={{color: 'red'}}>{this.state.hostError}</div>}
                    <input type="search" 
                        id="cardNumber"
                        className="card-input-input"
                        onChange={e => {
                            this.setState({ searchField: e.target.value }, () => console.log(this.state))
                        }}>
                    </input>
                            {status.map(status => ( 
                                <button 
                                type="button"
                                key={status}
                                className="card-button"
                                onClick={this.handleHide}>
                                    Проверить
                                </button>
                            ))}
                        </div>
                        </div>
                </div>
            </motion.div>

            {this.state.show_technologies ? (
                <HostList hosts={filteredHosts} /> 
            ) : (
                <div></div>
            )}
            </>
        )
    }
};