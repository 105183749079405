import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import doc_il from '../img/doc-il.svg';
import { motion } from "framer-motion";
import logoVipnet from '../img/dad_1.svg';
import {Link, NavLink} from 'react-router-dom';

export const Change_master_keys = ({ placeholder, handleChange }) => (
    <>
                <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                <div className="change-master-container">
                <div className="change-master-box-head">
                    <div className="change-master-heading">
                        <h1>Смена мастер-ключей</h1>
                        <p>В соответствии с разделом «Смена мастер-ключей защиты и обмена» 
                            Руководства администратора по настройке и использованию программы ViPNet Удостоверяющий и ключевой 
                            центр смена мастер-ключей проводится 
                            с периодичностью один раз в 15 месяцев</p>
                   </div>
                </div>
                <div className="change-master-content">
                    <div className="change-master-content-heading">
                        <h1>Порядок действий для смены мастер-ключей</h1>
                        <p>Мероприятия, которые необходимо выполнить до начала смены мастер-ключей</p>
                </div>
                <div className="change-master-content-main">
                    <div className="change-master-content-main-block textp fl">
                        <div className="сmcmb-step">Шаг №1</div>
                        На компьютере, подключенному к ЗСПД, запустить программу ViPNet Client

                        </div>
                        
                    <div className="change-master-content-main-flow">
                        
                    <svg width="331" height="329" viewBox="0 0 331 329" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M318.22 326.45C318.22 307.38 318.22 297.846 314.111 290.744C311.419 286.092 307.547 282.228 302.884 279.542C295.767 275.442 286.211 275.442 267.1 275.442L62.6216 275.442C43.5105 275.442 33.955 275.442 26.8378 271.342C22.1757 268.655 18.3034 264.791 15.6107 260.139C11.5019 253.038 11.5019 243.503 11.5019 224.434" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5019 244.837L11.5019 20.4031" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.556 11.4767C2.556 6.54683 6.56123 2.55036 11.5019 2.55036C16.4426 2.55036 20.4479 6.54683 20.4479 11.4767C20.4479 16.4066 16.4426 20.4031 11.5019 20.4031C6.56123 20.4031 2.556 16.4066 2.556 11.4767Z" fill="#E5E5E5" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M307.996 316.248L318.22 326.45L328.444 316.248" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="329" height="331" fill="white" transform="translate(331) rotate(90)"/>
</clipPath>
</defs>
</svg>


</div>

                    
                    <div className="change-master-content-main-block textp fl gart">
                    <div className="сmcmb-step">Шаг №2</div>В появившемся окне ввести пароль от Вашего ViPNet Client</div>
                    
                    <div className="change-master-content-main-flow ttw">
                    <svg width="372" height="390" viewBox="0 0 372 390" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M186 388L186 16" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M178 380L186 388L194 380" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 186 9)" fill="#E5E5E5" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



</div>

                    <div className="change-master-content-main-block textp fl garf"><div className="сmcmb-step">Шаг №3</div>Зайдите в контекстное меню «Справка», в раздел «О программе»</div>
                    <div className="change-master-content-main-flow tty">
                    <svg width="220" height="235" viewBox="0 0 220 235" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.99999 225L163 225C185.627 225 196.941 225 203.971 217.971C211 210.941 211 199.627 211 177L211 16" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(1.31134e-07 -1 -1 -1.31134e-07 211 9)" fill="#E5E5E5" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 233L2 225L10 217" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
<div className="change-master-content-main-block textp fl sj narf"><div className="сmcmb-step jj">Примечание</div>
В случае, если версия Вашего ViPNet Client ниже версии 4.X, то необходимо провести его обновление 
</div>
                    <div className="change-master-content-main-block textp fl gark"><div className="сmcmb-step">Шаг №4</div>
                    В появившемся окне убедиться, что версия ViPNet Client не ниже 4.X</div>
                    <div className="change-master-content-main-flow btty">
                    <svg width="454" height="472" viewBox="0 0 454 472" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M227 2L227 456" stroke="#1462ff" stroke-width="4" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="2 10"/>
<path d="M235 10L227 2L219 10" stroke="#1462ff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(4.37114e-08 1 1 -4.37114e-08 227 463)" fill="#1462ff" stroke="#1462ff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>





</div>
                </div>



                
                </div>
                
                </div>
                <div className="change-master-container">
                <div className="change-master-content">
                    <div className="change-master-content-heading tal">
                        <h1>Мероприятия, которые необходимо выполнить в период смены мастер-ключей</h1>
                </div>
                <div className="change-master-content-second-main">
                    <div className="change-master-content-second-block textp fl"><div className="сmcmb-step">Шаг №1</div>На компьютере, подключенному к ЗСПД, запустить программу ViPNet Client</div>
                    <div className="change-master-content-main-flow rrw">
                    <svg width="319" height="334" viewBox="0 0 319 334" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M317 324L57 324C34.3726 324 23.0589 324 16.0294 316.971C9 309.941 9 298.627 9 276L9 16" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="9" cy="9" r="7" transform="rotate(-90 9 9)" fill="#E5E5E5" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M309 332L317 324L309 316" stroke="#131212" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
                    <div className="change-master-content-second-block textp fl ytt"><div className="сmcmb-step">Шаг №2</div>В появившемся окне ввести пароль от Вашего ViPNet Client</div>
                    <div className="change-master-content-main-flow mklp">
                    <svg width="190" height="196" viewBox="0 0 190 196" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M172 186L180 194L188 186" stroke="#1462ff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 180 9)" fill="#1462ff" stroke="#1462ff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M180 194V194C180 179.046 180 171.569 176.785 166C174.678 162.352 171.648 159.322 168 157.215C162.431 154 154.954 154 140 154L42 154C27.0461 154 19.5692 154 14 150.785C10.3515 148.678 7.32183 145.648 5.21539 142C2 136.431 2 128.954 2 114V114" stroke="#1462ff" stroke-width="4" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="2 10"/>
<path d="M2 96L2 114" stroke="#1462ff" stroke-width="4" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="2 10"/>
<path d="M180 16V16C180 30.9539 180 38.4308 176.785 44C174.678 47.6485 171.648 50.6782 168 52.7846C162.431 56 154.954 56 140 56L42 56C27.0461 56 19.5692 56 14 59.2154C10.3515 61.3218 7.32183 64.3515 5.21539 68C2 73.5692 2 81.0461 2 96V96" stroke="#1462ff" stroke-width="4" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="2 10"/>
</svg>
</div>
                    <div className="change-master-content-second-block textp fl oop"><div className="сmcmb-step jj">Примечание</div>
                   После прохождения авторизации в ПО ViPNet Client вы можете свернуть окно программы. Оно будет активно в контекстном меню панели задач.
                   <br></br>
                   <br></br>
                   Обратите внимание, программа ViPNet Client должна быть активна в период смены мастер-ключей включительно в рабочие дни с 
                   10:00 до 16:00 по МСК</div>
                </div>
                
                </div>
                </div>
                <div className="change-master-container">
                <div className="change-master-content">
                    <div className="change-master-content-heading lal">
                        <h1>Мероприятие, которое необходимо выполнить <span className="higlight-text">после смены мастер-ключей</span></h1>
                </div>
                <div className="change-master-content-three-main">
                <div className="change-master-content-three-block textp fl utt">
                    Зайти в раздел «Защищенная сеть»</div>
                    <div className="change-master-content-main-flow njok">
                <svg width="102" height="84" viewBox="0 0 102 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M100 42H16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M92 50L100 42L92 34" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(-1 0 0 1 9 42)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</div>
                    <div className="change-master-content-three-block textp fl stt">Нажать на вкладку «Защищенная сеть»</div>
                    <div className="change-master-content-main-flow kjok">
                    <svg width="132" height="150" viewBox="0 0 132 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M66 148L66 16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M58 140L66 148L74 140" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 66 9)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


</div>
                    <div className="change-master-content-three-block textp fl btt">Нажать кнопку «Проверить» или клавишу F5
                    на клавиатуре. Убедиться, что координатор(ы) доступны</div>
                    <div className="change-master-content-main-flow pjok">
                    <svg width="115" height="119" viewBox="0 0 115 119" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M58 117L58 16" stroke="#1A1A1A" stroke-width="4" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="2 10"/>
<path d="M50 109L58 117L66 109" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 58 9)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
<div className="change-master-content-main-flow vjok">
<svg width="242" height="227" viewBox="0 0 242 227" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 225V57C10 34.3726 10 23.0589 17.0294 16.0294C24.0589 9 35.3726 9 58 9H226" stroke="#1A1A1A" stroke-width="4" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="2 10"/>
<circle cx="233" cy="9" r="7" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 217L10 225L18 217" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
<div className="change-master-content-main-flow dolkd">
<svg width="319" height="325" viewBox="0 0 319 325" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M301 315L309 323L317 315" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 309 9)" fill="#E5E5E5" stroke="#1A1A1A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M309 323V323C309 308.046 309 300.569 305.785 295C303.678 291.352 300.648 288.322 297 286.215C291.431 283 283.954 283 269 283L42 283C27.0461 283 19.5692 283 14 279.785C10.3515 277.678 7.32182 274.648 5.21538 271C1.99999 265.431 1.99999 257.954 1.99999 243V243" stroke="#1A1A1A" stroke-width="4" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="2 10"/>
<path d="M2 96L1.99999 243" stroke="#1A1A1A" stroke-width="4" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="2 10"/>
<path d="M309 16V16C309 30.9539 309 38.4308 305.785 44C303.678 47.6485 300.648 50.6782 297 52.7846C291.431 56 283.954 56 269 56L42 56C27.0461 56 19.5692 56 14 59.2154C10.3515 61.3218 7.32182 64.3515 5.21538 68C1.99999 73.5692 1.99999 81.0461 1.99999 96V96" stroke="#1A1A1A" stroke-width="4" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="2 10"/>
</svg>
</div>
                    <div className="change-master-content-second-block textp fl lkl"><div className="сmcmb-step zok">Примечание</div>
                   Если координатор(ы) недоступны, обратитесь в техническую поддержку Вашей ЗСПД</div>
                   <div className="change-master-content-second-block textp fl mop"><div className="сmcmb-step bgm">Примечание</div>
                   Если координатор(ы) доступны, то Вы можете продолжать работу на портале</div>
                    </div>
                </div>
                </div>

                <div className="change-master-container">
                <div className="change-master-content">
                    <div className="change-master-content-heading">
                        <h1>Контакты технической поддержки ЗСПД</h1>
                </div>
                <div className="change-master-content-block-contact">
                    <div className="contact-flow">
                    +7 (800) 200-65-64
                    </div>
                    <div className="change-master-content-main-flow dolkdvb">
                    <svg width="378" height="306" viewBox="0 0 378 306" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M376 153H16" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M368 161L376 153L368 145" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle r="7" transform="matrix(-1 0 0 1 9 153)" fill="#000" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
                    <div className="contact-flow nnn">
                    Эл. почта Вашей ЗСПД
                    </div>
                    <div className="change-master-content-main-flow sdolkdvb">
                    <svg width="554" height="64" viewBox="0 0 554 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M544 2V7C544 29.6274 544 40.9411 536.971 47.9706C529.941 55 518.627 55 496 55L16 55" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="9" cy="55" r="7" transform="rotate(180 9 55)" fill="black" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M552 10L544 2L536 10" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>






</div>
                    <div className="contact-flow cvc">
                    +7 (495) 197-65-91
                    </div>
                </div>
                </div>
                </div>
    </motion.div>
    </>
);