import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import { motion } from "framer-motion";
import scheme from '../img/Asset-scheme.svg';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Руководства'

const container = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 10
      }
    }
  };


  const item = {
     hidden: { opacity: 0 },
     visible: {opacity: 1}
  };

const GuidesMan = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
                  </Helmet> 
                  <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
            <div className="container-guides-section-faq1">
                <div className="container-guides-heading cghn">
                    <h1>Руководства</h1>
                </div>
                <div className="container-ipnet-man md-45">
                  <motion.div className="overlay-form">
                    <div className="overlay-upper"></div>
                    <div className="overlay-form-inner">
                        <h1>Смена мастер-ключей</h1>
                        <p>Данное руководство поможет Вам подготовится и успешно осуществить процедуру смены мастер-ключей
                            </p>
                            <NavLink className="guides-more wtb" exact to='/guides/man/change-master-keys'>Подробнее</NavLink>
                    </div>
                  </motion.div>
                </div>
                <div className="container-ipnet-man md-45">
                  <motion.div className="overlay-form">
                    <div className="overlay-upper"></div>
                    <div className="overlay-form-inner">
                        <h1>Подключение к ЕР ЦДО с помощью TLS ГОСТ</h1>
                        <p>Данное руководство поможет Вам подготовить рабочее место для подключения к ЕР ЦДО
                            </p>
                            <NavLink className="guides-more wtb" exact to='/guides/man/ercdo'>Подробнее</NavLink>
                    </div>
                  </motion.div>
                </div>
                <div className="container-ipnet-man md-45">
                  <motion.div className="overlay-form">
                    <div className="overlay-upper"></div>
                    <div className="overlay-form-inner">
                        <h1>Вопросы-ответы, инструкции по защищенной сети передачи данных 13833</h1>
                        <p>Вопросы по подключению к ЗСПД 13833 и ответы на них</p>
                            <NavLink className="guides-more wtb" exact to='/guides/man/faq/'>Подробнее</NavLink>
                    </div>
                  </motion.div>
                </div>
            </div>
            </motion.div>
        </>
        
        
    )
}

export default GuidesMan;