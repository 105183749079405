import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import scheme from '../img/Asset-scheme.svg';
import { Contacts_ercdo } from '../containers/Contacts_ercdo.jsx';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Контакты технической поддержки ЕР ЦДО'

const ercdo_contacts = () => {
    return (
        <>
          <Helmet>
          <title>{ title }</title>
        </Helmet>
         <div className='container-network Flex-cell Flex--1of1'>
            <div className="info-container">
            <div className="header">
            <h1>Контакты технической поддержки по вопросам доступа к<br></br> системе ФИС ФРДО (модуль ЕР ЦДО)</h1>
        </div>
                <Contacts_ercdo></Contacts_ercdo>
            </div>
        </div>
        </>
    )
}

export default ercdo_contacts;