import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import { motion } from "framer-motion";
import scheme from '../img/Asset-scheme.svg';
import { Helmet } from 'react-helmet';
import HeaderDoc from "../elements/HeaderDoc.jsx";

const title = 'ЦИТиС ЗСПД | Документация'

const container = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 10
      }
    }
  };


  const item = {
     hidden: { opacity: 0 },
     visible: {opacity: 1}
  };

const GuidesDoc = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
                  </Helmet> 
                  <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
            <div className="container-guides-docu">
                <div className="container-guides-heading vbbb">

                        <h1>Документы и служебные письма</h1>

                </div>
                
                <div className="guides-doc-heading">
                    <h1>Выберите сеть</h1>
                </div>
                <HeaderDoc />
            </div>
            </motion.div>
        </>
        
        
    )
}

export default GuidesDoc;