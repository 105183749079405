import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import logo from '../img/logo.png';
import { motion, AnimatePresence } from "framer-motion";

const Navbar = () => {
    const [isMouse, toggleMouse] = React.useState(false);
    const toggleMouseMenu = () => {
        toggleMouse(!isMouse)
    };

    const [hoverMouse, inputMouse] = React.useState(false);
    const inputMouseMenu = () => {
        inputMouse(!hoverMouse)
    };

    
    const [orinMouse, slabelMouse] = React.useState(false);
    const lableMouseMenu = () => {
        slabelMouse(!orinMouse)
    };

    const [dopMouse, slMouse] = React.useState(false);
    const lMouseMenu = () => {
        slMouse(!dopMouse)
    };
    
    const subMenuAnimate = {
        enter: {
            opacity: 1,
            rotateX: 0,
            transition: {
              duration: 0.3
            },
            display: "block",
          },
          exit: {
            opacity: 0,
            rotateX: -17,
            transition: {
              duration: 0.2,
              delay: 0.3
            },
            transitionEnd: {
              display: "none"
            }
          }
    };
    return (
        <div className="Navbar">
            <a className="logo" href="https://citis.ru/" target="_blank"> <img className="logo-img" src={logo} /></a>
                <div className="nav-menu">
                    <NavLink className="navbar-link" activeClassName="navbar-link-active" exact to='/'>
                        Главная
                    </NavLink>
                    <NavLink className="navbar-link" activeClassName="navbar-link-active" exact to='/guides/'>
                        Инструкции
                    </NavLink>
                    <div className="navbar-link" activeClassName="navbar-link-active" exact to='/13833/'>
                    <motion.div 
                            onMouseEnter={lableMouseMenu}
                            onMouseLeave={lableMouseMenu}>ЗСПД 13833
                            <motion.div 
                                className="navbar-dropdown"
                                initial="exit"
                                animate={orinMouse ? "enter" : "exit"}
                                variants={subMenuAnimate}>
                            <div className="navbar-items">

                            <NavLink className="nd" exact to='/13833/news'><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="navbar-block">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-news" width="115" height="62" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
  <line x1="8" y1="8" x2="12" y2="8" />
  <line x1="8" y1="12" x2="12" y2="12" />
  <line x1="8" y1="16" x2="12" y2="16" />
</svg><div className="md pd"><div className="navbar-card">Новости</div><p className="navbar-card-text">Последние и актуальные новости касаемые 
                                защищенной сети передачи данных 13833</p></div></motion.div></NavLink>
                                
                                <NavLink className="nd" exact to='/13833/'><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="navbar-block">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-news" width="90" height="62" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M7 7h10v6a3 3 0 0 1 -3 3h-4a3 3 0 0 1 -3 -3v-6" />
  <line x1="9" y1="3" x2="9" y2="7" />
  <line x1="15" y1="3" x2="15" y2="7" />
  <path d="M12 16v2a2 2 0 0 0 2 2h3" />
</svg> 
<div className="md pd"><div className="navbar-card">Подключение</div>
<p className="navbar-card-text">Ознакомьтесь с правилами и процедурой подключения к ЗСПД 13833</p></div></motion.div></NavLink>


<NavLink className="nd" exact to='/13833/contacts'><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="navbar-block">
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="75" height="62" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54"  fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
</svg> <div className=""><div className="pd"><div className="navbar-card">Контакты</div>
<p className="navbar-card-text">Контакты и адрес технической поддержки ЗСПД 13833</p></div></div></motion.div></NavLink>
                            </div>
                    </motion.div>
                    </motion.div>
                    </div>
                    <div className="navbar-link" activeClassName="navbar-link-active" exact to="/3608/">
                    <motion.div 
                            onMouseEnter={inputMouseMenu}
                            onMouseLeave={inputMouseMenu}>ЗСПД 3608
                            <motion.div 
                                className="navbar-dropdown"
                                initial="exit"
                                animate={hoverMouse ? "enter" : "exit"}
                                variants={subMenuAnimate}>
                            <div className="navbar-items">

                            <NavLink className="nd" exact to='/3608/news'><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="navbar-block">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-news" width="115" height="62" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
  <line x1="8" y1="8" x2="12" y2="8" />
  <line x1="8" y1="12" x2="12" y2="12" />
  <line x1="8" y1="16" x2="12" y2="16" />
</svg><div className="md pd"><div className="navbar-card">Новости</div><p className="navbar-card-text">Последние и актуальные новости касаемые 
                                защищенной сети передачи данных 3608</p></div></motion.div></NavLink>
                                
                                <NavLink className="nd" exact to='/3608/'><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="navbar-block">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-news" width="90" height="62" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M7 7h10v6a3 3 0 0 1 -3 3h-4a3 3 0 0 1 -3 -3v-6" />
  <line x1="9" y1="3" x2="9" y2="7" />
  <line x1="15" y1="3" x2="15" y2="7" />
  <path d="M12 16v2a2 2 0 0 0 2 2h3" />
</svg> 
<div className="md pd"><div className="navbar-card">Подключение</div>
<p className="navbar-card-text">Ознакомьтесь с правилами и процедурой подключения к ЗСПД 3608</p></div></motion.div></NavLink>


<NavLink className="nd" exact to='/3608/contacts'><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="navbar-block">
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="75" height="62" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54"  fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
</svg> <div className=""><div className="pd"><div className="navbar-card">Контакты</div>
<p className="navbar-card-text">Контакты и адрес технической поддержки ЗСПД 3608</p></div></div></motion.div></NavLink>
                            </div>
                    </motion.div>
                    </motion.div>
                    </div>
                    <div className="navbar-link" activeClassName="navbar-link-active" exact to="/3189/">
                        <motion.div 
                            onMouseEnter={toggleMouseMenu}
                            onMouseLeave={toggleMouseMenu}>ЗСПД 3189
                            <motion.div 
                                className="navbar-dropdown"
                                initial="exit"
                                animate={isMouse ? "enter" : "exit"}
                                variants={subMenuAnimate}>
                            <div className="navbar-items">

                            <NavLink className="nd" exact to='/3189/news'><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="navbar-block">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-news" width="115" height="62" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
  <line x1="8" y1="8" x2="12" y2="8" />
  <line x1="8" y1="12" x2="12" y2="12" />
  <line x1="8" y1="16" x2="12" y2="16" />
</svg><div className="md pd"><div className="navbar-card">Новости</div><p className="navbar-card-text">Последние и актуальные новости касаемые 
                                защищенной сети передачи данных 3189</p></div></motion.div></NavLink>
                                
                                <NavLink className="nd" exact to='/3189/'><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="navbar-block">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-news" width="90" height="62" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M7 7h10v6a3 3 0 0 1 -3 3h-4a3 3 0 0 1 -3 -3v-6" />
  <line x1="9" y1="3" x2="9" y2="7" />
  <line x1="15" y1="3" x2="15" y2="7" />
  <path d="M12 16v2a2 2 0 0 0 2 2h3" />
</svg> 
<div className="md pd"><div className="navbar-card">Подключение</div>
<p className="navbar-card-text">Ознакомьтесь с правилами и процедурой подключения к ЗСПД 3189</p></div></motion.div></NavLink>


<NavLink className="nd" exact to='/3189/contacts'><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="navbar-block">
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="75" height="62" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54"  fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
</svg> <div className=""><div className="pd"><div className="navbar-card">Контакты</div>
<p className="navbar-card-text">Контакты и адрес технической поддержки ЗСПД 3189</p></div></div></motion.div></NavLink>
                            </div>
                    </motion.div>
                    </motion.div>
                </div>
                    <NavLink className="navbar-link" activeClassName="navbar-link-active" exact to='/guides/man/ercdo'>
                        
                        <motion.div 
                            onMouseEnter={lMouseMenu}
                            onMouseLeave={lMouseMenu}>TLS ГОСТ
                            <motion.div 
                                className="navbar-dropdown"
                                initial="exit"
                                animate={dopMouse ? "enter" : "exit"}
                                variants={subMenuAnimate}>
                            <div className="navbar-items">

                            <NavLink className="nd" exact to='/guides/man/ercdo'><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="navbar-block">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-news" width="115" height="62" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
  <line x1="8" y1="8" x2="12" y2="8" />
  <line x1="8" y1="12" x2="12" y2="12" />
  <line x1="8" y1="16" x2="12" y2="16" />
</svg><div className="md pd"><div className="navbar-card">Руководство</div><p className="navbar-card-text">Руководство по подключению к ЕР ЦДО с помощью TLS ГОСТ</p></div></motion.div></NavLink>
                                


<NavLink className="nd" exact to='/ercdo/contacts'><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="navbar-block">
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="75" height="62" viewBox="0 0 24 24" stroke-width="1.5" stroke="#343B54"  fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
</svg> <div className=""><div className="pd"><div className="navbar-card">Контакты</div>
<p className="navbar-card-text">Контакты и адрес технической поддержки ЕР ЦДО</p></div></div></motion.div></NavLink>
                            </div>
                    </motion.div>
                    </motion.div>
                    </NavLink>
                </div>
        </div>
    );
};

export default Navbar;