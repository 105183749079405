import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import scheme from '../img/Asset-scheme.svg';
import Quest_3189 from '../containers/Quest_3189.jsx';
import Quest_3189_2 from '../containers/Quest_3189-2.jsx';
import Quest_13833 from '../containers/Quest-13833.jsx';
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Вопросы и ответы ЗСПД 13833'


const Page_13833_faq = () => {
    return (
        <>
                                <Helmet>
          <title>{ title }</title>
        </Helmet>
         <div className='container-network Flex-cell Flex--1of1'>
            <div className="info-container">
                       <div className="header">
            <h1>Вопросы и ответы ЗСПД 13833</h1>
            <p>Ответы на часто задаваемые вопросы</p>
        </div>

        <Quest_13833 title="Добавление узлов(клиентов) своей сети в межсетевое взаимодействие с сетью 13833"
                    content="Для добавления клиентов своей сети в межсетевое взаимодействие с сетью 13833 воспользуйтесь следующей инструкцией">
                    </Quest_13833>
        <Quest_3189 title="Расшифрование и установка дистрибутивов"
                    content="Для расшифрования и установки дистрибутива воспользуйтесь следующей инструкцией">
                    </Quest_3189>
                    <Quest_3189_2 title="Установка ViPNet Client"
                    content="Для установки VipNet Client воспользуйтесь следующей инструкцией">
                    </Quest_3189_2>
                {/* 
                   <Quest_13833 title="Нет доступа на ФРДО или не доступен координатор"
                    content="Для решения Вашей проблемы, просим написать на нашу электронную почту 3608vipnet@citis.ru с указанием абонентского пункта: инструкцию как узнать абонентский пункт"></Quest_13833>
                    <Quest_13833 title="Что такое «Заверенная копия документа, подтверждающего выполнение в информационной системе, подключаемой к сети 3608, требований о защите информации в соответствии с действующим законодательством Российской Федерации»?"
                    content="Наименование данного документа «Аттестат соответствия», запросить копию документа, Вы можете у организации, которая проводила аттестацию рабочего места и устанавливала VipNet Client. Если у Вас первичное подключение, Вы можете обратиться в любую удобную Вам организацию, имеющую лицензию на деятельность по технической защите конфиденциальной информации (Список этих организаций, Вы можете найти на данном ресурсе:"></Quest_13833>
                    <Quest_13833 title="Как расшифровать и установить дистрибутивы (он же dst-файл, ПКИ)?"
                    content="&#10065;Если Вы используете VipNet PKI (Загрузить данное ПО можно из 10 пункта) - Воспользуйтесь следующей инструкцией ()<br></br>
&#10065;Если Вы используете КриптоАРМ (Загрузить данное ПО можно из 10 пункта) - Воспользуйтесь следующей инструкцией ()
"></Quest_13833>
                    <Quest_13833 title="Как заполнять сопроводительное письмо?"
                    content="Сопроводительное письмо пишется в свободной форме со списком прикладываемых документов на подключение или восстановление доступа к ЗСПД 3608"></Quest_13833>
                    <Quest_13833 title="Сроки ответа заявок по электронной почте"
                    content="Ответ на заявки по электронной почте осуществляется в течении 5 дней после получения сообщения"></Quest_13833>
                    <Quest_13833 title="Сроки выпуска дистрибутивов"
                    content="Дистрибутивы выпускаются в течении 15 дней с момента получения и согласования бумажных копий документов"></Quest_13833>
                    <Quest_13833 title="Как заполнять сопроводительное письмо?"
                    content="Сопроводительное письмо пишется в свободной форме со списком прикладываемых документов на подключение или восстановление доступа к ЗСПД 3608"></Quest_13833>
                    <Quest_13833 title="Где взять лицензию на сеть 3608? "
                    content="Запросить лицензию Вы можете у той организации, которая устанавливала Вам VipNet Client, если у Вас первичное подключение, для приобретения лицензии Вам следует обратиться в компанию ИнфоТеКС"></Quest_13833>
                    */}
            </div>
        </div>
        </>
    )
}

export default Page_13833_faq;