import React, { useState, useEffect } from "react";
import {Link, NavLink} from 'react-router-dom';
import ReactDOM from "react-dom";
import { motion } from "framer-motion";

export const News_3189 = ({ placeholder, handleChange }) => (
    <>
                <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
                <div className="container_stroke_steps">
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>11 марта 2024</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       <p>Уважаемые пользователи защищенной сети передачи данных № 3189.</p>
       <p>Уведомляем Вас о том, что в период с 22 апреля по 29 апреля текущего года будет проводится смена мастер-ключей для ЗСПД 3189.<br></br>
Информация о согласовании Минобрнауки России указанной процедуры и сроков ее проведения прилагается.<br></br>
Для успешного завершения данной процедуры от пользователя требуется лишь, чтобы на компьютере, подключенном к ЗСПД 3189, была запущена программа ViPNet Client (альтернативное название «Монитор»). 
<br></br>Включите программу и введите Ваш пароль. После прохождения авторизации можете свернуть окно программы. Оно будет активно в контекстном меню панели задач.<br></br>
ВАЖНО: НЕ ЗАКРЫВАЙТЕ программу и НЕ ВЫКЛЮЧАЙТЕ компьютер. Программа ViPNet Client (Монитор) должна быть активна в течении всего периода смены мастер-ключей в рабочие дни.<br></br>
Результаты обновления мастер-ключей пользователь сможет узнать только после завершения периода смены мастер-ключей. 
Если смена мастер-ключей прошла успешно, то 30 апреля вход в ФИС ГНА будет доступен. В противном случае необходимо на email: 3189vipnet@citis.ru направить скриншот вашего абонентского пункта и открытую часть квалифицированного сертификата ключа проверки электронной подписи (инструкция по ссылке: <a className="change_master_key_link" href="https://zspd.citis.ru/guides/faq">https://zspd.citis.ru/guides/faq</a>), указав в теме письма: «Нет доступа к ФИС ГНА после смены мастер-ключей».
</p>
<a className="button-download-let" href="/Письмо_МОН_в_организации_о_смене_ключей.pdf" download>Скачать</a>
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>23 марта 2023</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       <p>Уважаемые пользователи защищенной сети передачи данных № 3189.</p>
<p>Уведомляем Вас о том, что в период с 10 апреля по 23 апреля текущего года будет проводится смена мастер-ключей для ЗСПД 3189.<br></br>
Информация о согласовании Минобрнауки России указанной процедуры и сроков ее проведения прилагается.<br></br>
Для успешного завершения данной процедуры от пользователя требуется лишь, чтобы на компьютере, подключенном к ЗСПД 3189, была запущена программа ViPNet Client (альтернативное название «Монитор»). 
<br></br>Включите программу и введите Ваш пароль. После прохождения авторизации можете свернуть окно программы. Оно будет активно в контекстном меню панели задач.<br></br>
ВАЖНО: НЕ ЗАКРЫВАЙТЕ программу и НЕ ВЫКЛЮЧАЙТЕ компьютер. Программа ViPNet Client (Монитор) должна быть активна в течении всего периода смены мастер-ключей в рабочие дни.<br></br>
Результаты обновления мастер-ключей пользователь сможет узнать только после завершения периода смены мастер-ключей. 
Если смена мастер-ключей прошла успешно, то 24 апреля вход в ФИС ГНА будет доступен. В противном случае необходимо на email: 3189vipnet@citis.ru направить скриншот вашего абонентского пункта и открытую часть квалифицированного сертификата ключа проверки электронной подписи (инструкция по ссылке: <a className="change_master_key_link" href="https://zspd.citis.ru/guides/faq">https://zspd.citis.ru/guides/faq</a>), указав в теме письма: «Нет доступа к ФИС ГНА после смены мастер-ключей».
</p>
<a className="button-download-let" href="/МОН о смене ключей.pdf" download>Скачать</a>
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>01 февраля 2022</p>
       </div>
       <div className="container_steps_news">
           <h2>Технические работы</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных № 3189.
        <p>В связи с проводимыми техническими работами на сети возможны пропадания связи в период </p>с 1 февраля по 2 февраля 2022 года.
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>24 ноября 2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных ViPNet 3189! Федеральное государственное автономное научное учреждение 
       «Центр информационных технологий и систем органов исполнительной власти» сообщает о завершении процедуры смены мастер-ключей в 
       защищенной сети передачи данных ViPNet 3189. В связи с этим, просим проверить доступ к федеральной информационной системе 
       государственной научной аттестации (ФИС ГНА). В случае возникновения проблем при подключении к информационной системе просим 
       обращаться в службу технической поддержки сети VipNet 3189.
       Контактные данные службы технической поддержки сети VipNet 3189:
       <p>E-mail: 3189vipnet@citis.ru;</p>
       Телефон: +7(800)200-65-64, +7(495)197-65-91.
       <a className="button-download-let" href="/guides/doc/3608">Подробнее</a>
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>06 сентября 2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных 3189 (ЗСПД 3189) сообщаем Вам о том, что с 18 по 29 октября 2021 года будет осуществляться смена мастер-ключей в ЗСПД 3189.
       <a className="button-download-let" href="/guides/doc/3608">Подробнее</a>
        </div>
    </div>
    
    <div className="container_post_connection">
       <div className="container_post_header">
           <p>11 мая 2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Резервный набор ключей</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных 3189 (ЗСПД 3189) сообщаем Вам о том, если Ваш ViPNet Client запрашивает резервный набор персональных ключей (РНПК) или нет подключения к сайту http://vak3.ed.gov.ru/ и 
       отсутствует соединение с координаторами SA и VAK-3, то обратитесь на нашу электронную почту 3189vipnet@citis.ru вместе с прикрепленным снимком экрана Вашего абонентского пункта. Также, к письму необходимо прикрепить ключ проверки электронной подписи,
       для дальнейшей отправки дистрибутива в зашифрованном виде.
        </div>
    </div>
                <div className="container_post_connection">
       <div className="container_post_header">
           <p>05 мая 2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Технические работы</h2>
       </div>
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных 3189 (ЗСПД 3189) сообщаем Вам о том, что с 5 мая по 7 мая 2021 года будут осуществляться технические работы. Возможны пропадания связи. 
        </div>
    </div>
    <div className="container_post_connection">
       <div className="container_post_header">
           <p>27 марта.2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Смена мастер-ключей</h2>
       </div>
       
       <div className="container_post_main">
       Уважаемые пользователи защищенной сети передачи данных 3189 (ЗСПД 3189) сообщаем Вам о том, что с 19 апреля по 2 мая 2021 года будет осуществляться смена мастер-ключей в ЗСПД 3189. 
       <br></br>
       В связи с этим просим Вас осуществить необходимые мероприятия, указанные в письме Минобрнакуки от 26.03.2021 № МН-3/2229.
       <a className="button-download-let" href="/ВАК Информационное письмо смена мастер-ключей.PDF" target="_blank">Скачать</a>
        </div>
    </div>
        <div className="container_post_connection">
       <div className="container_post_header">
           <p>18 марта 2021</p>
       </div>
       <div className="container_steps_news">
           <h2>Технические работы</h2>
       </div>
       <div className="container_post_main">
         Уважаемые пользователи защищенной сети передачи данных № 3189 сообщаем Вам о том, что 19 марта 2021 года с 00:00 до 06:00 по московскому времени будут проводиться технические работы на сети связи. В этот период возможно кратковременное прерывание доступа к ФИС ГНА.
        </div>
    </div>
    <div className="container_post_connection">
       <div className="container_post_header">
           <p>10 августа 2020</p>
       </div>
       <div className="container_steps_news">
           <h2>Регламент</h2>
       </div>
       <div className="container_post_main">
         Уважаемые пользователи защищенной сети передачи данных 3189 сообщаем Вам о том, что с 16.11.2020 г. утвержден новый порядок подключения к защищенной сети передачи данных 3189
           <a className="button-download-let" href="/rg3189.pdf" download>Скачать</a>
        </div>
    </div>
    </div>
    </motion.div>
    </>
);