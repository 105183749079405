import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import scheme from '../img/Asset-scheme.svg';
import { motion } from "framer-motion";
import { Connection_13833_networks} from '../containers/Connection_13833_networks.jsx';
import { Presentation_13833} from '../containers/Pressentation_13833.jsx';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Межсетевое подключение к ЗСПД 13833'

const Page_13833_connect_networks = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
        </Helmet>
         <div className='container-network Flex-cell Flex--1of1'>
            <div className="info-container">
         <div className="header">
            <h1>Подключение к ЗСПД 13833 c помощью межсетевого взаимодействия</h1>
            <p>Ознакомьтесь с правилами и процедурой подключения к ЗСПД 13833</p>
            <div className='presentation'><Presentation_13833></Presentation_13833></div>
        </div>
                <Connection_13833_networks></Connection_13833_networks>
                </div>
        </div>
        </>
    )
}

export default Page_13833_connect_networks;