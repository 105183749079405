export default {
    items: [
      {
        id: "id1",
        title: "Как подписать документ?",
        text1:
          "Если Вы используете VipNet PKI - Воспользуйтесь следующей инструкцией",
          button1: "/Подпись документов.pdf"

      }
    ]
  };
  