import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {Link, NavLink} from 'react-router-dom';

export const Connection_13833 = ({ placeholder, handleChange }) => (
    <>
                            <div className="navbar-13833">
                        <NavLink exact to='/13833/connection/client' className="nav-link-13833" activeClassName="nav-link-active" >
                            Подключение к ЗСПД 13833 с использованием СКЗИ ViPNet Client
                        </NavLink>
                        <NavLink exact to='/13833/connection/networks' className="nav-link-13833" activeClassName="nav-link-active">
                             Подключение к ЗСПД 13833 с помощью межсетевого взаимодействия
                        </NavLink>
                    </div>
    </>
);