export default {
    items: [
      {
        id: "id1",
        title: "Нет доступа на портал, не доступен координатор",
        text1:
          "Для решения Вашей проблемы, обратитесь в техническую поддержку по телефону +7 (800) 200-65-64 и +7 (495) 197-65-91 или по электронной почте, в зависимости от номера Вашей сети"

      },
      {
        id: "id2",
        title: "Где взять лицензию на ViPNet сеть?",
        text1: "Запросить лицензию Вы можете у той организации, которая устанавливала Вам VipNet Client, если у Вас первичное подключение, для приобретения лицензии Вам следует обратиться в компанию ИнфоТеКС",
        button1: "https://infotecs.ru/partners/list/"
      },
      {
        id: "id3",
        title: "Как узнать реквизиты письма-уведомления?",
        text1: "Для уточнения реквизитов письма-уведомления обратитесь в техническую поддержку по телефону +7 (800) 200-65-64 и +7 (495) 197-65-91 или по электронной почте, в зависимости от номера Вашей сети, с наименованием Вашей организации, указанием города, ИНН"
      },
      {
        id: "id4",
        title: "Установили дистрибутив, но нет доступа на сайт?",
        text1: "Вероятнее всего проблема заключается в закрытых портах (Порты - дополнение в сетевом адресе), проверьте открыты ли данные порты на Вашем сетевом оборудовании (Не только на рабочем месте, но и на межсетевых экранах Вашей организации), в Ваших средствах защиты (Secret Net, Dallas Lock и т.д), также данные порты должны быть открыты у Вашего Интернет-провайдера. Список портов: 5000-5002 TCP,2046-2047 UDP и 55777 UDP"
      }
    ]
  };
  