import React, { useState } from "react";
import { Link } from "react-router-dom";


export default function HeaderDoc() {
    const [active, setAcitve] = useState(null);
    function handlenavclick(e) {
      if (e.target.className === "aa 1") {
        setAcitve(1);
      } else if (e.target.className === "aa 2") {
        setAcitve(2);
      } else if (e.target.className === "aa 3") {
        setAcitve(3);
      } else if (e.target.className === "aa 4") {
        setAcitve(4);
      }
    }
    return (
        <div className="container-guides-categ vbbb ssss">
                    
        <div className="container-guides-categ-block ppb">
            
            <h1>ЗСПД 3608</h1>
            <p>ФИС ФРДО, ФИС ФБДА, АКНД ПП</p>
            <Link className="guides-more mtb" to="/guides/doc/3608">Подробнее</Link>
        </div>
        <div className="container-guides-categ-block ppb">
        
            <h1>ЗСПД 3189</h1>
            <p>ФИС ГНА</p>
            <Link className="guides-more mtb" to="/guides/doc/3189">Подробнее</Link>
        </div>
        <div className="container-guides-categ-block ppb">
        
            <h1>ЗСПД 13833</h1>
            <p>Сервис приема</p>
            <Link className="guides-more mtb" to="/guides/doc/13833">Подробнее</Link>
        </div>
    </div>
    );
  }
  