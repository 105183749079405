import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import { motion } from "framer-motion";
import scheme from '../img/Asset-scheme.svg';
import { Helmet } from 'react-helmet';

const title = 'ЦИТиС ЗСПД | Разное'

const container = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 10
      }
    }
  };


  const item = {
     hidden: { opacity: 0 },
     visible: {opacity: 1}
  };

const GuidesOther = () => {
    return (
        <>
        <Helmet>
          <title>{ title }</title>
                  </Helmet> 
                  <motion.div
            visible={{ opacity: 1, x: 0, transition: {type:'spring', delay: 3.5} }}
            initial={{ opacity: 0, x: '2vw', y: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 0, transition: {ease:'easeInOut'}, y: 0 }}
            >
            <div className="container-guides-section-faq1">
                <div className="container-guides-heading cghn">
                    <h1>Разное</h1>
                </div>
                <div className="container-ipnet-man">
                <motion.div className="overlay-form md-45">
                  <div className="overlay-upper"></div>
                  <div className="overlay-form-inner">
                      <h1>Перечень организаций-партнеров ОАО «ИнфоТеКС»</h1>
                      <p>
                          Для подключения к защищенным сетям передачи данных образовательной организации необходимо приобрести у 
                          любой организации-партнера ОАО «ИнфоТеКС» средства криптографической защиты информации ViPNet Client для 
                          требуемой сети, произвести установку и настройку указанного средства.
                      </p>
                    <NavLink className="guides-more wtb" exact to='/partners/'>Подробнее</NavLink>
                  </div>
              </motion.div>
              <motion.div className="overlay-form md-45">
                  <div className="overlay-upper"></div>
                  <div className="overlay-form-inner">
                      <h1>Перечень лицензиатов по технической защите информации</h1>
                      <p>Перечень организаций-лицензиатов по технической защите информации
                        приведен в Реестре лицензий на деятельность по технической защите конфиденциальной информации
                          </p>
                    <NavLink className="guides-more wtb" exact to='/licensees/'>Подробнее</NavLink>
                  </div>
              </motion.div>
            </div>
            </div>
            </motion.div>
        </>
        
        
    )
}

export default GuidesOther;